/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Module } from 'vuex';
import {
  findIndex, orderBy, sum
} from 'lodash';
import {
  Forums, Forum, Threads, Thread, Message, MessagePost, emptyForum, MessagesSort, ThreadsSort, FORUM_ID_BLOG,
} from '@/views/forum/models/Forum';
import ForumService from '@/services/ForumService';
import { logError } from '@/utils/logger';
import usePageState from '@/state/pageState';
import { useRouter } from 'vue2-helpers/vue-router';
import getAxiosResponseErrorCode from '@/utils/getAxiosResponseErrorCode';

interface State {
  forumList: Forums;
  forum: Forum;
  threadList: Threads;
  thread: Thread;
  messagesSort: MessagesSort;
  threadsSort: ThreadsSort;
  isFetchingMessages: boolean;
  isFetchingThreads: boolean;
  allThreadsLoaded: boolean;
  allMessagesLoaded: boolean;
  editMessageId: string;
  replyMessageId: string;
  isUpdatingMessage: boolean;
  lastMessageText: string;
}

const module: Module<State, any> = ({
  namespaced: true,
  state: {
    threadsSort: {
      sortParam: 'TIMESTAMP',
      sortOrder: 'DESC',
    },
    messagesSort: {
      sortParam: 'TIMESTAMP',
      sortOrder: 'DESC',
    },
    editMessageId: '',
    replyMessageId: '',
    forumList: [
    ],
    forum: { ...emptyForum },
    threadList: Object.assign([], emptyForum.groups[0]),
    thread: { ...emptyForum.groups[0].threads[0] },
    isFetchingMessages: false,
    isFetchingThreads: false,
    allThreadsLoaded: false,
    allMessagesLoaded: false,
    isUpdatingMessage: false,
    lastMessageText: '',
  },
  getters: {
    getForumTitle: ({ forum }): string | undefined => forum.groups[0].title,
    getForumId: (state): string => state.forum.forumId || state.thread.forumId,
    getForumList: ({ forumList }): Forums => forumList,
    getForumListWithTitle({ forumList }): Forums {
      const filteredList = forumList.filter((forum) => forum.title !== undefined && forum.title !== null);
      const blogForumIndex = findIndex(filteredList, (forum) => forum.forumId === FORUM_ID_BLOG);
      filteredList.push(filteredList[blogForumIndex]);
      filteredList.splice(blogForumIndex, 1);
      return filteredList;
    },
    getThreadId: ({ thread }): string => thread.threadId,
    getGroupId: ({ thread }): string | undefined => thread.groupId,
    getThreadList: ({ threadList }): Threads => threadList,
    getThreadListSorted({ threadList, threadsSort }) {
      let sort: 'title' | 'forumThreadMetaData.numberOfViews' | 'creator.lastname' | 'forumThreadMetaData.voteResult' | 'created' | 'forumThreadMetaData.latestReplyMessageTimestamp';
      let order: 'asc' | 'desc' = 'asc';
      switch (threadsSort.sortOrder) {
        case 'DESC':
          order = 'desc';
          break;
        case 'ASC':
        default:
          order = 'asc';
          break;
      }

      switch (threadsSort.sortParam) {
        case 'TITLE':
          sort = 'title';
          break;
        case 'VIEWS':
          sort = 'forumThreadMetaData.numberOfViews';
          break;
        case 'NAME':
          sort = 'creator.lastname';
          break;
        case 'VOTES':
          sort = 'forumThreadMetaData.voteResult';
          break;
        case 'TIMESTAMP':
        default:
          sort = 'forumThreadMetaData.latestReplyMessageTimestamp';
          break;
      }

      function getNestedValue(obj: any, path: string) {
        return path.split('.').reduce((o, key) => o && o[key], obj);
      }

      return orderBy(threadList,
        [(thread) => {
          if (sort === 'title') { return thread.title.toLowerCase(); }
          if (sort === 'creator.lastname') {
            return thread.creator.lastname?.toLowerCase();
          }
          if (sort === 'forumThreadMetaData.voteResult') {
            return thread.forumThreadMetaData.voteResult;
          }
          if (sort === 'forumThreadMetaData.numberOfViews') {
            return thread.forumThreadMetaData.numberOfViews;
          }
          return getNestedValue(thread, sort);
        }],
        order);
    },
    getThreadTitle: ({ thread }): string => thread.title,
    getThreadTopic: ({ thread }): Message => thread.initialMessage,
    getThreadSortParam: ({ threadsSort }): any => threadsSort,
    getThreadRepliesFlat({ thread }) {
      const replies: Array<Message> = [];
      for (let i = 1; i < thread.messages.length; i++) {
        replies.push(thread.messages[i]);
      }
      return replies;
    },
    getThreadMessagesMap(state) {
      const replyMap: any = {};
      const localReplies: Array<Message> = JSON.parse(JSON.stringify(state.thread.messages));

      localReplies.forEach((message) => {
        replyMap[message.messageId] = message;
        if (message.subMessages?.length) {
          message.subMessages.forEach((subMessage) => {
            replyMap[subMessage.messageId] = subMessage;
          });
        }
      });
      return replyMap;
    },
    getOpenEditMessageId: ({ editMessageId }): any => editMessageId,

    getOpenReplyToMessageId: ({ replyMessageId }) => replyMessageId,

    getThreadRepliesSorted: ({ thread, messagesSort }) => {
      const replyMapBase: any = {};
      const localReplies: Array<Message> = thread.messages || [];

      localReplies.forEach((message, index) => {
        replyMapBase[message.messageId] = message;
        message.messageCount = `# ${index + 1}`;
        if (message.subMessages?.length) {
          message.subMessages.forEach((replyMessage, replyIndex) => {
            replyMapBase[replyMessage.messageId] = replyMessage;
            replyMessage.messageCount = `# ${index + 1}.${replyIndex + 1}`;
            if (replyMessage.replyToMessageId !== undefined && replyMessage.replyToMessageId !== message.messageId) {
              replyMessage.subReplyLink = `<a href="#message-${replyMessage.replyToMessageId}">
              @ ${replyMapBase[replyMessage.replyToMessageId].creator.firstname} - ${replyMapBase[replyMessage.replyToMessageId].messageCount}</a>`;
            }
          });
        }
      });

      let sort: 'creator.lastname' | 'voteResult' | 'created';
      let order: 'asc' | 'desc' = 'asc';
      switch (messagesSort.sortOrder) {
        case 'DESC':
          order = 'desc';
          break;
        case 'ASC':
        default:
          order = 'asc';
          break;
      }

      switch (messagesSort.sortParam) {
        case 'NAME':
          sort = 'creator.lastname';
          break;
        case 'VOTES':
          sort = 'voteResult';
          break;
        case 'TIMESTAMP':
        default:
          sort = 'created';
          break;
      }
      return orderBy(localReplies,
        [(reply) => {
          if (sort === 'creator.lastname') {
            return reply.creator.lastname?.toLowerCase();
          }
          return reply[sort];
        }],
        order);
    },
    getThreadRepliesTotal: ({ thread }): number => {
      if (thread.forumThreadMetaData.numberOfReplies) {
        return thread.forumThreadMetaData.numberOfReplies;
      }
      if (thread.messages) {
        return sum(thread.messages.map((message: Message) => 1 + message.subMessages!.length));
      }
      return 0;
    },
  },
  mutations: {
    setForumList(state, forumList) {
      state.forumList = forumList;
    },
    setForum(state, forum: Forum) {
      state.forum = forum;
    },
    updateForum(state, forum) {
      state.forum = Object.assign(state.forum, forum);
    },
    setThreadList(state, threadList) {
      state.threadList = threadList;
    },
    setThread(state, thread: Thread) {
      state.thread = { ...state.thread, ...thread };
      state.isFetchingMessages = false;
    },
    updateThread(state, thread: any) {
      state.thread = Object.assign(state.thread, thread);
    },
    setGroupId({ thread }, groupId: string) {
      thread.groupId = groupId;
    },
    setOpenEditMessageId(state, messageId: string) {
      state.editMessageId = messageId;
    },
    setOpenReplyToMessageId(state, messageId: string) {
      state.replyMessageId = messageId;
    },
    increaseNumberOfReplies({ thread }) {
      thread.forumThreadMetaData.numberOfReplies += 1;
    },
    addThreads(state, threads: Array<Thread>) {
      state.threadList.push(...threads);
      state.isFetchingThreads = false;
    },
    setThreadListSort(state, { sortParam, sortOrder }) {
      const sortThreadList: {sortParam: string; sortOrder: 'ASC' | 'DESC'} = {
        sortParam,
        sortOrder,
      };
      window.localStorage.setItem('sortThreadList', JSON.stringify(sortThreadList));
      state.threadsSort = sortThreadList;
    },
    setMessagesSort(state, { sortParam, sortOrder }) {
      state.messagesSort = {
        sortParam,
        sortOrder,
      };
    },
    addReply(state, message: Message) {
      state.thread.messages.push(message);
    },
    addReplies(state, messages: Array<Message>) {
      state.thread.messages.push(...messages);
      state.isFetchingMessages = false;
    },
    updateReply({ thread }, { message, messageLocation }) {
      const regexMessageCount = new RegExp(/^[\d]{1,}(?:\.[\d]{1,})?$/);
      if (messageLocation === 'topic') {
        thread.initialMessage = { ...thread.initialMessage, ...message };
      } else if (regexMessageCount.test(messageLocation)) {
        const messageLocationParts: Array<number> = messageLocation.split('.').map((item: string) => parseInt(item));
        if (messageLocationParts[1] && thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1] !== undefined) {
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].text = message.text;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].voteResult = message.voteResult;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].downvoteCount = message.downvoteCount;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].upvoteCount = message.upvoteCount;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].created = message.created;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].edited = message.edited;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].error = message.error;
        } else {
          thread.messages[messageLocationParts[0] - 1].text = message.text;
          thread.messages[messageLocationParts[0] - 1].voteResult = message.voteResult;
          thread.messages[messageLocationParts[0] - 1].downvoteCount = message.downvoteCount;
          thread.messages[messageLocationParts[0] - 1].upvoteCount = message.upvoteCount;
          thread.messages[messageLocationParts[0] - 1].created = message.created;
          thread.messages[messageLocationParts[0] - 1].edited = message.edited;
          thread.messages[messageLocationParts[0] - 1].error = message.error;
        }
      }
    },
    endOfThreadListReached({ forum }) {
      forum.groups[0].allThreadsLoaded = true;
    },
    endOfThreadReached(state) {
      state.allMessagesLoaded = true;
      state.isFetchingMessages = false;
    },
    resetThreadListFetchingStates(state) {
      state.allThreadsLoaded = false;
      state.isFetchingThreads = false;
    },
    resetThreadFetchingStates(state) {
      state.allMessagesLoaded = false;
      state.isFetchingMessages = false;
    },
    clearThreadList(state) {
      state.threadList = [];
    },
    clearThread(state) {
      state.thread = { ...emptyForum.groups[0].threads[0] };
    },
    setUpdatingMessage(state, updating: boolean) {
      state.isUpdatingMessage = updating;
    },
  },
  actions: {
    fetchForumList({ commit }) {
      ForumService.getForumList().then((forumList) => {
        commit('setForumList', forumList);
      });
    },
    fetchThreadList({ state, commit }, {
      forumId, groupId, previousRouteName = undefined,
    }) {
      const sortThreadListString: string | null = window.localStorage.getItem('sortThreadList');
      if (sortThreadListString !== null && (previousRouteName === 'thread')) {
        const sortThreadList: { sortParam: string; sortOrder: 'ASC' | 'DESC' } = JSON.parse(sortThreadListString);
        state.threadsSort = sortThreadList;
      }
      if (!state.allThreadsLoaded && !state.isFetchingThreads && forumId && groupId) {
        state.isFetchingThreads = true;
        ForumService.getThreads(forumId, groupId).then((forum) => {
          commit('updateForum', forum);
          const threadList = forum.groups[0].threads;
          commit('setThreadList', threadList);
          commit('endOfThreadListReached');
        }).finally(() => {
          state.isFetchingThreads = false;
        });
      } else {
        logError(forumId, groupId);
      }
    },
    fetchThread({ state, commit }, {
      forumId, groupId, threadId,
    }):Promise<void | Forum> | null {
      const { pageNotFound } = usePageState();
      const router = useRouter();

      if (!state.isFetchingMessages && forumId && groupId && threadId) {
        state.isFetchingMessages = true;
        return ForumService.getThread(forumId, groupId, threadId).then((forum) => {
          commit('setForum', forum);
          const [thread] = forum.groups[0].threads;
          state.editMessageId = '';
          state.replyMessageId = '';
          commit('setThread', thread);
          commit('endOfThreadReached');
        }).finally(() => {
          state.isFetchingMessages = false;
        }).catch(() => {
          pageNotFound.value = true;
          router.push('/talks');
        });
      }
      return null;
    },
    clearThread({ commit }) {
      commit('clearThread');
    },
    clearThreadList({ commit }) {
      commit('clearThreadList');
    },
    postThread({ },
      {
        forumId,
        groupId,
        title,
        description,
        tagAssignmentList,
      }) {
      const { globalErrorMessage } = usePageState();
      return ForumService.createThread(forumId, groupId, title, description, tagAssignmentList)
        .catch((error) => {
          globalErrorMessage.value = {
            i18Key: 'forum.errors.could-not-create', code: getAxiosResponseErrorCode(error), component: 'forum/store/index.ts', withoutReload: true
          };
          throw error;
        });
    },
    updateThreadTitle({ commit, state }, {
      forumId, groupId, threadId, title
    }) {
      ForumService.updateThread(forumId, groupId, threadId, title).then((forum) => {
        commit('updateThread', { title });
      });
    },
    async postThreadMessage({
      state, dispatch, commit,
    }, postMessage: MessagePost) {
      const { forumId, threadId, groupId } = state.thread;
      const { globalErrorMessage } = usePageState();
      state.lastMessageText = postMessage.messageContent;
      try {
        const forum = await ForumService.postThreadMessage(forumId, groupId, threadId, postMessage).catch((error) => {
          globalErrorMessage.value = {
            i18Key: 'forum.errors.not-sent', code: getAxiosResponseErrorCode(error), component: 'forum/store/index.ts', withoutReload: true
          };
          throw error;
        });
        state.lastMessageText = '';
        const [thread] = forum.groups[0].threads;
        const [message] = thread.messages;
        if (state.thread.initialMessage.messageId === message.replyToMessageId) {
          dispatch('fetchThread', {
            forumId, groupId, threadId, sortParam: 'TIMESTAMP', sortOrder: 'DESC', presetOffset: 0,
          });
        } else {
          commit('increaseNumberOfReplies');
          state.thread.messages?.forEach((stateMessage: Message, index: number) => {
            if (stateMessage.messageId === message.replyToMessageId) {
              state.thread.messages[index].subMessages?.push(message);
            } else {
              stateMessage.subMessages?.forEach((subMessage: Message) => {
                if (subMessage.messageId === message.replyToMessageId) {
                  state.thread.messages[index].subMessages?.push(message);
                }
              });
            }
          });
        }
        setTimeout(() => {
          document.querySelector(`#message-${message.messageId}`)!.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 200);
      } catch (error) {
        state.replyMessageId = postMessage.replyToMessageId ?? '';
      }
    },
    refreshThread({ commit, state }) {
      const { thread } = state;
      ForumService.getThread(thread.forumId,
        thread.groupId,
        thread.threadId).then((forum) => {
        const [responseThread] = forum.groups[0].threads;
        commit('setThread', responseThread);
      });
    },
    updateThreadMessage({ commit, state }, { postMessage, messageCount }) {
      commit('updateReply', { message: { text: postMessage.messageContent }, messageLocation: messageCount.replace(/#|\s/g, '') });
      commit('setUpdatingMessage', true);
      const { globalErrorMessage } = usePageState();

      ForumService.updateThreadMessage(postMessage.forumId,
        state.thread.groupId,
        postMessage.threadId,
        postMessage.forumThreadMessageId || '',
        postMessage.messageContent).then((forum) => {
        if (forum.groups) {
          const [message] = forum.groups[0].threads[0].messages;
          commit('updateReply', { message, messageLocation: messageCount.replace(/#|\s/g, '') });
        }
      }).catch((error) => {
        state.editMessageId = postMessage.forumThreadMessageId || '';
        globalErrorMessage.value = {
          i18Key: 'forum.errors.not-sent', code: getAxiosResponseErrorCode(error), component: 'forum/store/index.ts', withoutReload: true
        };
        commit('updateReply', { message: { text: postMessage.messageContent }, messageLocation: messageCount.replace(/#|\s/g, '') });
      }).finally(() => {
        commit('setUpdatingMessage', false);
      });
    },
    sortThreadReplies({ commit }, { sortParam, sortOrder }) {
      commit('setMessagesSort', { sortParam, sortOrder });
    },
    sortThreadList({ commit }, { sortParam, sortOrder }) {
      commit('setThreadListSort', { sortParam, sortOrder });
    },
    replyToMessage({ commit }, messageId: string) {
      commit('setOpenReplyToMessageId', messageId);
    },
    editMessage({ commit }, messageId: string) {
      commit('setOpenEditMessageId', messageId);
    },
  },
});

export default module;
