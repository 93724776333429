// this will maybe replace the page store some time

import favIconNew from '@/assets/img/favicon-new.png';
import useNotificationState from '@/state/notificationState';
import {
  computed, Ref, ref, watch
} from 'vue';
import {
  createGlobalState, useEventListener, useFavicon, useTitle, whenever
} from '@vueuse/core';

type GlobalErrorMessage = {
  code: string;
  i18Key: string;
  component: string;
  withoutReload?: boolean;
}

const usePageState = createGlobalState(() => {
  const { totalCount } = useNotificationState();
  const title = ref('ByteCookie');
  const mainClasses = ref<string[]>([]);
  const pageNotFound = ref(false);
  const globalErrorMessage = ref<GlobalErrorMessage|null>(null);
  const lastMouseDownEvent = ref<MouseEvent|null>(null);

  const addMainClass = (className:string) => {
    if (!mainClasses.value.includes(className)) mainClasses.value.push(className);
  };

  const removeMainClass = (className:string) => {
    mainClasses.value = mainClasses.value.filter((name) => name !== className);
  };

  const displayedTitle = useTitle();

  watch([title, totalCount], ([title, totalCount]) => {
    const countDisplay = totalCount ? `(${totalCount}) ` : '';
    displayedTitle.value = countDisplay + title;
  }, {
    immediate: true
  });

  const favIcon = computed(() => (totalCount.value ? favIconNew : '/favicon.ico'));

  useFavicon(favIcon);

  let disablePageNotFoundTOID = 0;
  whenever(pageNotFound, () => {
    if (disablePageNotFoundTOID) clearTimeout(disablePageNotFoundTOID);

    disablePageNotFoundTOID = setTimeout(() => {
      pageNotFound.value = false;
    }, 10000);
  });

  useEventListener(document, 'mousedown', (evt) => {
    lastMouseDownEvent.value = evt;
  });

  return {
    title,
    pageNotFound,
    globalErrorMessage,
    addMainClass,
    removeMainClass,
    mainClasses: mainClasses as Readonly<Ref<string[]>>,
    lastMouseDownEvent
  };
});

export default usePageState;
