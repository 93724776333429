import axios from '@/plugins/axios';
import {
  BaseUserWithRole, Lang, RegisteringCompanyUserResponse, User, UserRole
} from '@/models/User';
import { autoEncodeUserId } from '@/utils/userIdEncoding';
import { AxiosResponse } from 'axios';

interface PreRegisterRequest {
  isBetaTest: boolean;
  fullname: string;
  email: string;
  url?: string;
}

export interface RegisterBasicDataRequest {
  userId: '';
  firstname: string;
  lastname: string;
  email?: string;
  checkedAgbAndPrivacy?: boolean;
  companyName?: string;
  role?: UserRole;
  inviteCode?: string;
  companyInviteId?: string;
  registrationProcessId?: string;
  locale: Lang;
}

export default {
  async registerBasicData(request: RegisterBasicDataRequest): Promise<any> {
    return axios.post('/v1/users/user/register/initial', request);
  },

  async registerSecondStep(
    emailVerificationCode: string,
    password: string,
    userId: string,
    registrationProcessId: string,
  ): Promise<AxiosResponse> {
    return axios.post('/v1/users/user/register/password', {
      emailVerificationCode,
      password,
      userId: autoEncodeUserId(userId),
      registrationProcessId,
    }).catch(({ response }) => response);
    // return {
    //   ...response.data,
    //   token: response.headers.authorization,
    // };
  },

  async resendEmail(userId: string, registrationProcessId: string): Promise<any> {
    return axios.post('/v1/users/user/register/resend-verification-mail', {
      userId: autoEncodeUserId(userId),
      registrationProcessId,
    });
  },

  async preRegister(data: PreRegisterRequest): Promise<any> {
    return (await axios.post('/v1/users/user/preregister', data)).data;
  },

  async submitMobilephone(mobilephoneNumber: string): Promise<any> {
    return axios.post('/v1/users/user/register/mobilephone', { mobilephoneNumber }).catch((error) => error.response);
  },

  async verifyMobilephone(mobilephoneVerificationCode: string): Promise<any> {
    return axios.post('/v1/users/user/register/mobilephone/verify', { mobilephoneVerificationCode })
      .then(({ data, headers }) => {
        localStorage.setItem('token', (headers.authorization ?? ''));
        localStorage.setItem('refresh_token', (headers.refresh_token ?? ''));
        return data;
      }).catch((error) => error.response);
  },

  async getRegisteringUser(pid: string): Promise<BaseUserWithRole> {
    return axios.get(`/v1/users/user/register/${pid}`)
      .then((response) => response.data)
      .catch((error) => error.response);
  },

  async resendSMS(): Promise<any> {
    return axios.post('/v1/users/user/register/mobilephone/resend-verification-sms');
  },

  async getRegisteringCompanyGuest(inviteId: string): Promise<RegisteringCompanyUserResponse> {
    return axios.get(`/v1/users/user/register/guest/${inviteId}`).then((response) => response.data).catch((error) => error.response);
  }
};
