import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import { ExternalCompany } from '@/__generated__/types';

export enum Lang {
  de = 'de-DE',
  en = 'en-US',
}

export enum UserRoles {
  MINIMUM_USER= 'MINIMUM_USER',
  SYSTEM_USER= 'SYSTEM_USER',
  ADMIN= 'ADMIN',
  BC_TEAM= 'BC_TEAM',
  FREELANCER= 'FREELANCER',
  COMPANY_GUEST= 'COMPANY_GUEST',
  COMPANY_EMPLOYEE= 'COMPANY_EMPLOYEE',
  COMPANY_ADMIN= 'COMPANY_ADMIN',
  COMPANY_ROOT= 'COMPANY_ROOT',
  USER_IN_REGISTRATION= 'USER_IN_REGISTRATION',
  USER_IN_APPROVAL= 'USER_IN_APPROVAL',
  ALL_USERS= 'ALL_USERS',
  TAG_MODERATOR= 'TAG_MODERATOR',
  PROJECT_MODERATOR= 'PROJECT_MODERATOR',
  PROJECT_CREATOR= 'PROJECT_CREATOR',
  FORUM_MODERATOR= 'FORUM_MODERATOR',
  VIDEO_CHAT_MODERATOR= 'VIDEO_CHAT_MODERATOR',
  BLOG_ADMIN= 'BLOG_ADMIN',
  AFFILIATE_USER= 'AFFILIATE_USER'
}

export type UserRegistrationStatus = 'NONE' | 'OPEN' | 'MOBILEPHONE_VERIFIED' | 'MOBILEPHONE' | 'EMAIL_VERIFICATION_AND_PASSWORD' | 'INITIAL'

export type UserRole = keyof typeof UserRoles;

export type CompanyRole = 'ROOT' | 'EMPLOYEE' | 'GUEST';

export type RegistrationRole = 'COMPANY_ROOT' | 'COMPANY_GUEST' | 'COMPANY_EMPLOYEE'

export enum UserType {
  FREELANCER = 'freelancer',
  COMPANY = 'company-root',
  GUEST = 'company-guest',
  EMPLOYEE = 'company-employee',
}

export interface BaseUserWithRole {
  userId?: string;
  id?: string;
  firstname?: string;
  lastname?: string;
  roles?: UserRole[];
  companyRole?: CompanyRole;
  role?: RegistrationRole;
  company?: {
    name: string;
    id: string;
    rootUserId: string;
  };
}

export interface User extends BaseUserWithRole {
  userId?: string;
  fullname?: string;
  email?: string;
  preferredLocale?: Lang;
  reputationPoints?: number;
  city?: string;

  // added by frontend:
  // type?: UserType;
  projects?: ProjectListProject[];
}

export interface ExternalUser {
  userId: string;
  firstname: string;
  lastname: string;
  company: ExternalCompany;
  companyRole: CompanyRole;
}

export interface RegisteringCompanyUserResponse {
  firstName: string;
  lastName: string;
  companyName: string;
  inviteId: string;
}

export interface SetPasswortRequest {
  smsCode?: string;
  code?: string;
  userId: string;
  newPassword: string;
}
