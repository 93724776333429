
import BCXPoints from '@/components/molecules/BCXPoints.vue';
import BCXUserBadge from '@/components/molecules/BCXUserBadge.vue';
import useI18n from '@/mixins/useI18n';
import { ProfileStoreKey } from '@/models/Profile';
import { BannerUserState } from '@/models/UserState';
import useProfileState from '@/state/profile/profileState';
import useQuickInfo from '@/state/quickInfo';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import Chevron from '@/components/svg-components/Chevron.vue';
import { sortBy } from 'lodash';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'UserBadgesLegend',
  components: {
    BCXPoints,
    BCXUserBadge,
    Chevron,
  },
  props: {
    viewUser: {
      type: Object as PropType<BannerUserState>,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const { viewUser } = toRefs(props);
    const { tUserFallback: t } = useUser('self');
    const { setShowBadgesLegend } = useQuickInfo();
    const {
      user,
    } = useProfileState(ProfileStoreKey.QUICK_INFO, viewUser);
    const reputationPoints = computed(() => Math.max(user.value?.reputationPoints ?? 0, 0));
    const badges = computed(() => viewUser.value.badges);

    const sortedBadges = computed(() => {
      const shownBadges: { badge: string; position: number }[] = [];
      if (!badges.value) return [];
      badges.value.forEach((badge) => {
        if (badge.includes('STRANGER_KEY')) {
          // do nothing
        } else if ((badge.includes('MEMBER') || badge.includes('RESIDENT'))
            && badges.value.length <= 4) {
          shownBadges.push({ badge, position: 2 });
        } else if (badge.includes('TEAM')) {
          shownBadges.push({ badge, position: 1 });
        } else if (badge.includes('EXPERTISE')
          || badge.includes('COMMUNICATION')
          || badge.includes('RECOMMENDATION')) {
          if (badge.includes('GOLD')) {
            shownBadges.push({ badge, position: 3 });
          } else {
            shownBadges.push({ badge, position: 4 });
          }
        } else if (badge.includes('NETWORKING')) {
          shownBadges.push({ badge, position: 5 });
        } else {
          shownBadges.push({ badge, position: 6 });
        }
      });
      return sortBy(shownBadges, ['position']);
    });

    return {
      emit,
      t,
      setShowBadgesLegend,
      reputationPoints,
      badges,
      sortedBadges,
      user,
    };
  },
});
