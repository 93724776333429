
import {
  defineComponent, toRefs, ref, onUnmounted, computed, PropType
} from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import X from '@/components/svg-components/X.vue';
import axios from '@/plugins/axios';
import BytecookieLogoOnly from '@/components/svg/logo/logo-circles-only.svg?inline';
import { Cropper, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import fileConverter from '@/mixins/fileConverter';
import store from '@/store';
import { logDebug, logError, LogLevel } from '@/utils/logger';
import { User } from '@/models/User';
import useUser from '@/mixins/useUser';

export default defineComponent({
  components: {
    X,
    BCXAvatar,
    BytecookieLogoOnly,
    Cropper,
    Preview
  },
  props: {
    user: {
      type: Object as PropType<User>,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const { user } = toRefs(props);
    const userImageSource = ref('');
    const result = ref({});
    const image = ref({ type: '', src: '' });
    const file = ref(null);
    const isSaving = ref(false);
    const cropper = ref(null) as any;

    const { userId, tUserFallback: t } = useUser(user);

    const isSaveReady = computed(() => !isSaving.value && image.value.src.length > 0);

    axios.get('/v1/users/user/avatar/download').then((response) => {
      if (Object.keys(response.data).length > 0) {
        image.value.src = `data:${response.data.imageType};base64,${response.data.imageData}`;
        image.value.type = response.data.imageType;
      }
    });

    const onChange = ({ coordinates, image }: any) => {
      result.value = { coordinates, image };
    };

    const closePanel = () => emit('close');
    const avatarSaved = () => emit('saved');

    const saveImage = () => {
      if (!cropper.value) return;
      isSaving.value = true;
      const { canvas } = cropper.value.getResult();
      if (canvas) {
        canvas.toBlob(async (blob: any) => {
          const file = new File([blob], '',
            {
              type: blob.type,
              lastModified: new Date().getTime()
            });
          const { string, mime } = await fileConverter.methods.fileToString(file);

          const data = {
            filename: 'avatar',
            imageData: string,
            imageType: mime
          };
          axios.post('/v1/users/user/avatar', data).then(() => {
            logDebug('[AvatarUpload] Saved avatar successfully');
            // The user itself has 2 keys, the "user" and the userId one, reset both of them
            store.commit('avatarCache/resetUserState', 'user');
            store.commit('avatarCache/resetUserState', userId.value);
            avatarSaved();
            closePanel();
          }).catch((error) => {
            logError(LogLevel.ERROR_LOGGING, 'Avatar could not be saved', error);
          }).finally(() => { isSaving.value = false; });
        }, image.value.type);
      }
    };

    // This function is used to detect the actual image type
    const getMimeType = (file: any, fallback = null) => {
      const byteArray = (new Uint8Array(file)).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    };

    const loadImage = (event: any) => {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (image.value.src) {
          URL.revokeObjectURL(image.value.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          image.value = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target?.result, files[0].type) ?? '',
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    };

    onUnmounted(() => {
      if (image.value.src) {
        URL.revokeObjectURL(image.value.src);
      }
    });

    return {
      userImageSource,
      closePanel,
      onChange,
      result,
      file,
      loadImage,
      image,
      saveImage,
      cropper,
      isSaving,
      isSaveReady,
      t,
    };
  },
});
