
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import {
  computed, defineComponent, onMounted, PropType, ref, Ref, toRefs, watch
} from 'vue';
import { find, orderBy, round } from 'lodash';
import TaggingService, { ExternalTagAssignment, Tag, UserVoted } from '@/services/TaggingService';
import { templateRef } from '@vueuse/core';
import VotingService from '@/services/VotingService';
import { UpdateVotingResponse, VoteReason } from '@/models/Voting';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';
import useUser from '@/mixins/useUser';
import BCXTagSelect from './BCXTagSelect.vue';
import TagEntry from './TagEntry.vue';

export default defineComponent({
  components: {
    BCXHelpTextTriggerIcon,
    BCXInfoCollapsible,
    BCXTagSelect,
    TagEntry
  },
  props: {
    type: {
      type: String as PropType<'skill' | 'role'>,
      default: 'skill'
    },
    amountCollapsed: {
      type: Number,
      default: 5
    },
    amountExtended: {
      type: Number,
      default: () => 1000
    },
    businessObjectType: {
      type: String as PropType<'MESSAGE' | 'MESSAGING_GROUP' | 'PROJECT' | 'VOTE' | 'TAG' | 'BLOG_ENTRY'>,
      default: ''
    },
    businessObjectId: {
      type: String,
      default: ''
    },
    disableVoting: {
      type: Boolean,
      default: false
    },
    votingClosed: {
      type: Boolean,
      default: false
    },
    removeableTags: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const {
      type, amountCollapsed, amountExtended, businessObjectType, businessObjectId, disableVoting
    } = toRefs(props);
    const { tUserFallback: t } = useUser('self');
    const isExpanded = ref(false);
    const isInlineHelpShown = ref(false);
    const tagList = ref([]) as Ref<Array<ExternalTagAssignment | Tag>>;
    const tagValue = ref('');
    const showSelect = ref(true);
    const selectedTag = ref({}) as Ref<Tag>;
    const tagType = type.value === 'role' ? 'ROLE' : 'TAG';
    const amountToShow = computed(() => (isExpanded.value ? amountExtended.value : amountCollapsed.value));
    const tagListOfCurrentType = computed(() => (tagList.value as ExternalTagAssignment[]).filter((tag) => tag.tagType === tagType && (tag.tagId || tag.externalId)));
    const tagListCollapsed = computed(() => tagListOfCurrentType.value.filter((tag) => tag.voteResult > 0));

    const tagListLimited = computed(() => (isExpanded.value ? tagListOfCurrentType : tagListCollapsed).value.slice(0, amountToShow.value));

    const tagListSplitPosition = computed(() => round((disableVoting.value ? tagList : tagListLimited).value.length / 2));

    const tagListFirstColumn = computed(() => (disableVoting.value ? tagList : tagListLimited).value.slice(0, tagListSplitPosition.value));
    const tagListSecondColumn = computed(() => (disableVoting.value ? tagList : tagListLimited).value.slice(tagListSplitPosition.value));

    const refTagSelect = templateRef('tagSelect') as Ref<any>;
    const isExpandable = computed(() => (!isExpanded.value
          && tagListOfCurrentType.value.length > 0 && tagListOfCurrentType.value.length > tagListLimited.value.length));
    const isCollapsiable = computed(() => isExpanded.value
          && (tagListOfCurrentType.value.length > tagListCollapsed.value.length || tagListOfCurrentType.value.length > amountCollapsed.value));
    const canAdd = computed(() => tagValue.value.length);

    const selected = (item: Tag) => {
      selectedTag.value = item;
    };

    const resetValues = () => {
      selectedTag.value = {
        tagId: '',
        tagName: '',
        tagType: ''
      };

      // Note: Somehow this doesn't work (reactivity?), but works if we enter any other characters, not an empty string...
      tagValue.value = '';
      // Looks like the value inside of simplesuggest is already empty, thats why nothing happens
      // Thats why we have a workaround here, doing some action directly on the simplesuggest component, to clear the selected field
      const simpleSuggest = refTagSelect.value?.refSimpleSuggest;
      if (simpleSuggest) {
        simpleSuggest.clearSuggestions();
        simpleSuggest.updateTextOutside('');
      }
    };

    function toggleExpanded() {
      isExpanded.value = !isExpanded.value;
      tagList.value = orderBy(tagList.value, ['voteResult', 'tagName'], 'desc');
    }

    const initList = async () => {
      if (businessObjectId.value) {
        // Only get assignedTags if we have an object id (not creating a new thread)
        await TaggingService.getAssignedTags(businessObjectType.value, businessObjectId.value).then((data) => {
          if (!data?.length) return;
          tagList.value = orderBy(
            data.map((tag) => {
              tag.userVoted = tag.userVoted ? tag.userVoted : UserVoted.NOTVOTED;
              return tag;
            }),
            ['voteResult', 'tagName'],
            'desc'
          );
        });
      } else {
        // reset TagList when no businessObjectId is present
        tagList.value = [];
      }
      resetValues();
    };

    onMounted(initList);

    const addTag = () => {
      const simpleSuggest = refTagSelect.value.refSimpleSuggest;
      if (!simpleSuggest.selected && !simpleSuggest.text) return;
      if (tagList.value.map((tag) => tag.tagName).includes(simpleSuggest.text) || tagList.value.map((tag) => tag.tagName).includes(simpleSuggest.selected)) {
        resetValues();
        return;
      }
      if (simpleSuggest.selected) {
        const simpleSuggestSelectedItem = simpleSuggest.selected as Tag;
        if (businessObjectId.value) {
          // Add tag reference to selected item
          TaggingService.assignTag(simpleSuggestSelectedItem.tagId, businessObjectType.value, businessObjectId.value).then(() => {
            // Reload list of tags
            initList();
          });
        } else {
          // tagList / v-model update for create-case
          tagList.value.push({ ...simpleSuggestSelectedItem, businessObjectType: businessObjectType.value });
        }
      } else if (simpleSuggest.text) {
        if (businessObjectId.value) {
          // Create new Tag
          TaggingService.createNewTag(simpleSuggest.text, businessObjectType.value, businessObjectId.value).then(() => {
            // Reload list of tags
            initList();
          });
        } else {
          // tagList / v-model update for create-case
          tagList.value.push({
            businessObjectType: businessObjectType.value,
            tagId: '',
            tagType,
            tagName: simpleSuggest.text
          });
        }
      }
      resetValues();
    };

    const updateVoteCount = (tag: ExternalTagAssignment, data: UpdateVotingResponse, type: UserVoted) => {
      isExpanded.value = true;
      (tagListOfCurrentType as Ref<ExternalTagAssignment[]>).value.forEach((item: ExternalTagAssignment): ExternalTagAssignment => {
        if (item.tagId === tag.tagId) {
          item.downvoteCount = data.metaDataVoting.downvoteCount;
          item.upvoteCount = data.metaDataVoting.upvoteCount;
          item.voteResult = data.metaDataVoting.voteResult;
          item.userVoted = type;
        }
        return item;
      });
    };

    const voteUp = async (tag: ExternalTagAssignment) => {
      // THESE SHOULD UPDATE THE COUNT DIRECTLY
      if (disableVoting.value) return;
      const currentTag = find(tagList.value, (tagListTag: ExternalTagAssignment) => tagListTag.externalId === tag.externalId) as ExternalTagAssignment;
      const data = await VotingService.updateVoting({
        businessObjectId: tag.externalId,
        businessObjectType: 'TAG_ASSIGNMENT',
        reason: VoteReason.Content,
        type: UserVoted.UP
      });
      let userVoted = (UserVoted.DOWN === data.userMetaDataVoting?.userVoted || UserVoted.DOWN) ? UserVoted.NOTVOTED : UserVoted.UP;
      if (currentTag.userVoted === UserVoted.NOTVOTED) {
        userVoted = UserVoted.UP;
      }
      updateVoteCount(tag, data, userVoted);
    };

    const voteDown = async (tag: ExternalTagAssignment) => {
      // THESE SHOULD UPDATE THE COUNT DIRECTLY
      if (disableVoting.value) return;
      const currentTag = find(tagList.value, (tagListTag: ExternalTagAssignment) => tagListTag.externalId === tag.externalId) as ExternalTagAssignment;
      const data = await VotingService.updateVoting({
        businessObjectId: tag.externalId,
        businessObjectType: 'TAG_ASSIGNMENT',
        reason: VoteReason.Content,
        type: UserVoted.DOWN
      });
      let userVoted = (UserVoted.UP === data.userMetaDataVoting?.userVoted || UserVoted.UP) ? UserVoted.NOTVOTED : UserVoted.DOWN;
      if (currentTag.userVoted === UserVoted.NOTVOTED) {
        userVoted = UserVoted.DOWN;
      }
      updateVoteCount(tag, data, userVoted);
    };

    const noRolesLeft = () => {
      showSelect.value = false;
    };

    const selectFocus = () => {
      isExpanded.value = true;
    };

    watch(tagList, (tags) => {
      emit('input', tags);
    }, { deep: true });

    // If no prop is used in template render, we need to watch changes in order to rerender list
    watch(businessObjectId, () => {
      initList();
    });

    const removeTag = (tag: ExternalTagAssignment) => {
      tagList.value = tagList.value.filter((tagListTag) => tagListTag.tagName !== tag.tagName);
    };

    return {
      amountToShow,
      tagList,
      tagValue,
      selectedTag,
      noRolesLeft,
      showSelect,
      isExpanded,
      tagListFirstColumn,
      tagListSecondColumn,
      tagListLimited,
      tagListCollapsed,
      tagListOfCurrentType,
      selected,
      voteUp,
      voteDown,
      canAdd,
      addTag,
      isExpandable,
      isCollapsiable,
      isInlineHelpShown,
      selectFocus,
      toggleExpanded,
      removeTag,
      t,
    };
  }
});
