import {
  computed, inject, Ref, ref
} from 'vue';
import { InviteCode, MIN_DISPLAYED_CODES } from '@/models/Invitation';
import { useStorage } from '@vueuse/core';
import copy from 'copy-text-to-clipboard';

const useInviteTools = () => {
  const showAll = useStorage<boolean>('showAll_invites', false);
  const el = ref<HTMLElement>();
  const invites = inject('invites') as Ref<InviteCode[]>;
  const showExpander = computed(() => invites.value?.length > MIN_DISPLAYED_CODES);
  const revealedLink = ref(-1);

  const getCodeUrl = (code: string) => {
    const url = new URL(window.location.toString());
    url.pathname = 'register/';
    url.hash = '';
    url.searchParams.append('code', code);
    return url.toString();
  };

  const revealLink = (nr: number) => {
    revealedLink.value = (revealedLink.value === nr) ? -1 : nr;
  };

  const currentCode = computed(() => (revealedLink.value >= 0 ? getCodeUrl(invites.value[revealedLink.value].inviteCode) : ''));

  const copyCode = () => {
    if (el.value) {
      const input = el.value.querySelector('input.current-code') as HTMLInputElement;
      input.focus();
      input.select();
      copy(input.value);
    }
  };

  return {
    revealedLink,
    revealLink,
    currentCode,
    invites,
    copyCode,
    el,
    showAll,
    showExpander,
  };
};

export default useInviteTools;
