import { AvailabilityInterface, ProjectAvailabilityInterface } from '@/models/Availability';
import { InfoBoxType } from '@/models/InfoBox';
import { ProfileStoreKey } from '@/models/Profile';
import AvailabilityService from '@/services/AvailabilityService';
import useProfileState from '@/state/profile/profileState';
import { computed } from 'vue';
import { asyncComputed } from '@vueuse/core';
import useUser from '@/mixins/useUser';

const useRecommendations = () => {
  const { user, userId } = useProfileState(ProfileStoreKey.PROFILE);
  const { isFreelancer } = useUser(user);
  const { isFreelancer: isCurrentUserFreelancer } = useUser('self');
  const availability = asyncComputed<AvailabilityInterface | null>(
    async () => (userId.value ? (await AvailabilityService.getAvailabiltyforUser(userId.value)) ?? null : null),
    null,
    { lazy: true }
  );

  const projectAvailability = asyncComputed<ProjectAvailabilityInterface | null>(
    async () => (userId.value ? (await AvailabilityService.getAvailabilityForRecommendedUser(userId.value)) ?? null : null),
    null,
    { lazy: true }
  );

  const sidebarType = computed(() => ((availability.value?.available
    && isFreelancer.value
    && isCurrentUserFreelancer.value) ? InfoBoxType.INFOBOX_RECOMMENDATION : InfoBoxType.INFOBOX_RECOMMENDATION_DISABLED));

  const mergedAvailability = computed<AvailabilityInterface | null>(() => {
    if (availability.value && projectAvailability.value) {
      availability.value.available &&= projectAvailability.value?.available;
      return availability.value;
    }
    return null;
  });

  return {
    user,
    userId,
    sidebarType,
    availability: mergedAvailability
  };
};

export default useRecommendations;
