import { render, staticRenderFns } from "./ConfirmButtons.vue?vue&type=template&id=10b1e978&scoped=true"
import script from "./ConfirmButtons.vue?vue&type=script&lang=ts&setup=true"
export * from "./ConfirmButtons.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ConfirmButtons.vue?vue&type=style&index=0&id=10b1e978&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b1e978",
  null
  
)

export default component.exports