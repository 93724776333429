
import {
  computed, defineComponent, ref, PropType
} from 'vue';
import IconAdd from '@/components/svg/plus-circle.svg?inline';
import useUser from '@/mixins/useUser';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useTriggerLegalTextsPopup from '@/mixins/useTriggerLegalTextsPopup';
import useProjectGuestState, { NewProjectGuest } from '@/state/projectGuestState';
import BCXTextArea from '@/components/molecules/forms/BCXTextArea.vue';
import useVuelidate from '@vuelidate/core';
import useBCValidators from '@/mixins/useBCValidators';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import { AxiosError } from 'axios';
import { TranslatedErrorCode } from '@/models/BackendError';
import getAxiosResponseErrorCode from '@/utils/getAxiosResponseErrorCode';
import BackendErrors from '@/components/login-register/BackendErrors.vue';

export default defineComponent({
  name: 'ProjectGuestsInviteNew',
  components: {
    BCXCheckbox,
    BCXMarkdown,
    IconAdd,
    BCXTextArea,
    BCXValidatedInput,
    BackendErrors,
  },
  props: {
    mode: {
      type: String as PropType<'create' | 'edit'>,
      default: 'create',
    },
    value: {
      type: Object as PropType<NewProjectGuest>,
      default: () => ({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        accepted: false,
      }),
    },
  },
  setup(props) {
    const { tUserFallback: t } = useUser('self');
    const { emailValidation, required } = useBCValidators();
    const { setLegalText } = useTriggerLegalTextsPopup();
    const showTerms = () => setLegalText('terms');
    const showPrivacy = () => setLegalText('privacy');
    const showForm = ref(false);
    const showInlineAdd = computed(() => props.mode === 'edit');
    const toggleForm = () => {
      showForm.value = !showForm.value;
    };
    const {
      newProjectGuest: newGuest, canInviteNewGuest: canAdd, inviteNewGuest, loadGuests, resetNewProjectGuest: resetFields
    } = useProjectGuestState();
    const isMessageEnlarged = ref(false);
    const rules = {
      firstname: { required },
      lastname: { required },
      email: emailValidation
    };

    const v = useVuelidate(rules, newGuest);

    const errorMessage = ref('');
    const errorCodes = ref<(TranslatedErrorCode | string)[]>([]);
    const invitationSent = ref(false);
    const addNewGuest = async () => {
      try {
        v.value.$reset();
        const axiosResponse = await inviteNewGuest();
        if (axiosResponse && axiosResponse.status < 400) {
          errorMessage.value = '';
          errorCodes.value = [];
          resetFields();
        }
      } catch (err: any) {
        errorCodes.value = [];

        const error = err as AxiosError;
        if (error.response) {
          const backendErrors = new TranslatedBackendErrors(error.response);

          if (backendErrors.hasErrors()) {
            errorCodes.value = backendErrors.allErrors;
            errorMessage.value = '';
          } else {
            errorMessage.value = t('project-guests.errors.invite-guest-error', {
              code: getAxiosResponseErrorCode(error) ?? 'UNKNOWN_ERROR',
            }) as string;
          }
        } else {
          errorMessage.value = t('project-guests.errors.invite-guest-error', {
            code: error.code ?? 'NETWORK_ERROR',
          }) as string;
        }
      }
      invitationSent.value = true;

      loadGuests();
    };

    return {
      t,
      v,
      showForm,
      toggleForm,
      newGuest,
      canAdd,
      addNewGuest,
      showTerms,
      showPrivacy,
      showInlineAdd,
      isMessageEnlarged,
      invitationSent,
      errorMessage,
      errorCodes,
    };
  },
});
