
import { computed, defineComponent, toRefs } from 'vue';
import CLevelManager from '@/components/svg/people/c-level-manager.svg?inline';
import Purchasing from '@/components/svg/people/purchasing.svg?inline';
import ProjectManager from '@/components/svg/people/project-manager.svg?inline';

export default defineComponent({
  name: 'BCXPeopleIcon',
  components: {
    CLevelManager,
    Purchasing,
    ProjectManager
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: false,
      default: 40
    }
  },
  setup(props) {
    const { icon } = toRefs(props);

    const iconComponent = computed(() => {
      switch (icon.value) {
        case 'c-level-manager':
          return 'CLevelManager';
        case 'purchasing':
          return 'Purchasing';
        case 'project-manager':
          return 'ProjectManager';
        default:
          return 'CLevelManager';
      }
    });

    return {
      iconComponent
    };
  }
});
