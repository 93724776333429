import { BaseUserWithRole } from '@/models/User';
import { Project } from '@/views/project-forum/models/ProjectForum';

export enum ContractStatus {
  Open = 'OPEN',
  Aborted = 'ABORTED',
  Signed = 'SIGNED',
  Ended = 'ENDED',
}

export interface BaseContract {
  id: string;
  partner: BaseUserWithRole;
  project: Project;
  status: ContractStatus;
  lastChanged: string; // timestamp
  read: boolean;
}
