
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import MyRecommendationsIcon from '@/components/svg/my-recommendations.svg?inline';
import useResponsiveness from '@/mixins/useResponsiveness';
import { NotificationCountType } from '@/models/Notification';
import {
  MyRecommendationsResponse,
  PAGE_SIZE_MY_RECOMMENDATIONS,
  RecommendationFeedbackType,
  RECOMMENDATIONS_EVENT_REFRESH_FEEDBACKS
} from '@/models/Recommendations';
import { clearNotification } from '@/services/NotificationsService';
import recommendationService from '@/services/RecommendationService';
import useNotificationState from '@/state/notificationState';
import useRecommendationState from '@/state/recommendationsState';
import MyRecommendationsBar from '@/views/recommendations/components/my-recommendations/MyRecommendationsBar.vue';
import { computed, ref } from 'vue';
import { templateRef, useIntersectionObserver } from '@vueuse/core';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';
import MyRecommendationsTableNew from '@/views/recommendations/components/my-recommendations/MyRecommendationsTableNew.vue';

export default {
  name: 'MyRecommendations',
  components: {
    MyRecommendationsTableNew,
    BCXHelpTextTriggerIcon,
    MyRecommendationsBar,
    BCXInfoCollapsible,
    BCXContentArea,
    MyRecommendationsIcon
  },
  setup() {
    const isInlineHelpOpened = ref(false);
    const isShowAll = ref(false);
    const { isMobileWidth } = useResponsiveness();
    const { recommendationsEventBus } = useRecommendationState();
    const { resetInterval } = useNotificationState();
    const el = templateRef<HTMLElement>('show-sentinel');
    const { stop } = useIntersectionObserver(el, ([{ isIntersecting }]) => {
      if (!isIntersecting) return;
      stop();
      clearNotification(NotificationCountType.NewRecommendationFeedbacks);
      resetInterval();
    });

    const myRecommendationResponse = ref<MyRecommendationsResponse | null>(null);

    const load = async () => {
      myRecommendationResponse.value = await recommendationService.getMyRecommendations();
    };

    load();

    recommendationsEventBus.on((event) => {
      if (event === RECOMMENDATIONS_EVENT_REFRESH_FEEDBACKS) load();
    });

    const recommendations = computed(() => {
      if (!myRecommendationResponse.value?.recommendations) return [];
      const externalRecommendations = [...myRecommendationResponse.value.recommendations];
      return externalRecommendations.sort((a, b) => b.created.localeCompare(a.created));
    });

    const recommendationsFeedbackCounts = computed(() => {
      const counts:Record<RecommendationFeedbackType, number> = {
        [RecommendationFeedbackType.Positive]: 0,
        [RecommendationFeedbackType.Negative]: 0,
        [RecommendationFeedbackType.Neutral]: 0,
      };
      recommendations.value.forEach((recommendation) => {
        if (recommendation.recommendedUserFeedback) {
          counts[recommendation.recommendedUserFeedback]++;
        }
      });
      return counts;
    });

    const badRecommendationsCount = computed(() => recommendationsFeedbackCounts.value[RecommendationFeedbackType.Negative]);
    const goodRecommendationsCount = computed(() => recommendationsFeedbackCounts.value[RecommendationFeedbackType.Positive]);
    const neutralRecommendationsCount = computed(() => recommendationsFeedbackCounts.value[RecommendationFeedbackType.Neutral]);

    const weekRecommendationsCount = computed(() => {
      const beforeAWeek = (new Date()).getTime() - (1000 * 60 * 60 * 24 * 7);
      return recommendations.value.filter(
        (recommendation) => (new Date(recommendation.created)).getTime() > beforeAWeek
      ).length;
    });

    return {
      isMobileWidth,
      isShowAll,
      isInlineHelpOpened,
      recommendations,
      badRecommendationsCount,
      goodRecommendationsCount,
      neutralRecommendationsCount,
      weekRecommendationsCount,
      PAGE_SIZE_MY_RECOMMENDATIONS
    };
  }
};
