const fakeDocuments = [
  {
    id: '1',
    title: 'Dienstleistungsvertrag Tralalalala',
    customerSignature: true,
    contractorSignature: true,
    lastChanged: '2021-01-01',
    read: true
  },
  {
    id: '2',
    title: 'NDA',
    customerSignature: false,
    contractorSignature: true,
    lastChanged: '2021-01-05',
    read: false
  }
];

export default fakeDocuments;
