import { render, staticRenderFns } from "./ThreadEntry.vue?vue&type=template&id=89b5f91a&scoped=true"
import script from "./ThreadEntry.vue?vue&type=script&lang=ts"
export * from "./ThreadEntry.vue?vue&type=script&lang=ts"
import style0 from "./ThreadEntry.vue?vue&type=style&index=0&id=89b5f91a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b5f91a",
  null
  
)

export default component.exports