export const DAY_MSECS = 86400000;
export const WEEK_MSECS = DAY_MSECS * 7;
export const HALFSHORT_WEEKDAYS: Record<string, string[]> = {
  de: [
    'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'
  ],
  en: [
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
  ]
};
export const LETTER_WEEKDAYS: Record<string, string[]> = {
  de: [
    'S', 'M', 'D', 'M', 'D', 'F', 'S'
  ],
  en: [
    'S', 'M', 'T', 'W', 'T', 'F', 'S'
  ]
};
export const SHORT_MONTHS: Record<string, string[]> = {
  de: [
    'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'
  ],
  en: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
};
export const LONG_MONTHS: Record<string, string[]> = {
  de: [
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ],
  en: [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ]
};
