var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bc-form",class:{
    'c-is-group-scanning': _setup.isGroupScanning,
  },style:({
    '--form-h-gap': _vm.horizontalGap,
    '--v-gap': _vm.verticalGap
  })},[_c('div',{staticClass:"c-form-fields"},[_vm._l((_setup.fields),function(field){return [_c(_setup.BCXFormField,{key:field.name,ref:"formfield",refInFor:true,staticClass:"c-form-field",style:({
          width: field.width,
        }),attrs:{"field":field,"value":_vm.data[field.name],"has-error":!!_setup.internalErrorMessages[field.name]},on:{"input":function($event){return _setup.updateField(field.name, $event)},"blur":function($event){return _setup.onBlur(field)}}}),_c('transition',{key:`errorline-${field.name}`,attrs:{"name":"bcx-transition-collapse"}},[(_setup.lineGroupErrorMessages[field.name])?_c('div',{staticClass:"c-form-error-line"},[_c(_setup.BCXFormFieldError,{attrs:{"errors":_setup.lineGroupErrorMessages[field.name]}})],1):_vm._e()])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }