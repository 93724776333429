import { ProjectWithOffers } from '@/models/MyProjects';
import { BcxTableFilter } from '@/models/BcxTable';

export const projectSortFieldValue = (fieldName: string, obj: ProjectWithOffers): string | number => {
  switch (fieldName) {
    case 'created':
      return obj.project.created ?? '';
    case 'latest':
      return obj.latestOfferCreated;
    case 'title':
      return obj.project.title ?? '';
    case 'offers':
      return obj.offers?.length ?? '';
  }
  return 0;
};

export const searchCallback = (searchTerm: string, object: ProjectWithOffers) => object.project.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());

export const filter: BcxTableFilter = {
  style: 'buttons',
  isExclusive: true,
  states: ['ACTIVE', 'CLOSED'],
  labelCallback: (state: string) => state,
  stateCallback: (states: string[], obj: ProjectWithOffers) => {
    const filterState = obj?.project?.status === 'ACTIVE' ? 'ACTIVE' : 'CLOSED';
    return states.includes(filterState);
  }
};
