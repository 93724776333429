
// simple SVGs, colored:
import Users from '@/components/svg/users.svg?inline';
import Help from '@/components/svg/help.svg?inline';
import Tag from '@/components/svg/tags.svg?inline';
import Lighthouse from '@/components/svg/lighthouse.svg?inline';
import Back from '@/components/svg/chevron_back.svg?inline';
import Times from '@/components/svg/times.svg?inline';
import BarChat from '@/components/svg/bar-chat.svg?inline';
import JoinTable from '@/components/svg/join-table.svg?inline';
import NewTable from '@/components/svg/new-table.svg?inline';
import Participate from '@/components/svg/participate.svg?inline';
import EditCircle from '@/components/svg/edit-circle.svg?inline';
import ToolbarCircle from '@/components/svg/toolbar-circle.svg?inline';

// simple SVGs, using currentColor:
import CommentDots from '@/components/svg/comment-dots.svg?inline';
import DotsDots from '@/components/svg/dots-dots.svg?inline';
import Dashboard from '@/components/svg/dashboard.svg?inline';
import Home from '@/components/svg/home.svg?inline';
import Lightbulb from '@/components/svg/profile-lightbulb.svg?inline';
import lightbulbSmall from '@/components/svg/lightbulb-small.svg?inline';
import Share from '@/components/svg/share.svg?inline';
import SmallBar from '@/components/svg/small-bar.svg?inline';
import Envelope from '@/components/svg/envelope.svg?inline';
import Settings from '@/components/svg/settings.svg?inline';
import Sort from '@/components/svg/sort.svg?inline';
import PenEdit from '@/components/svg/pen-edit.svg?inline';
import Expandable from '@/components/svg/expandable.svg?inline';
import ExpandableVertical from '@/components/svg/expandable_vertical_simple.svg?inline';
import SendArrow from '@/components/svg/send-arrow.svg?inline';
import MenuDots from '@/components/svg/menu-dots.svg?inline';
import Goto from '@/components/svg/goto.svg?inline';
import WritingPen from '@/components/svg/writing_pen.svg?inline';
import StructureFlat from '@/components/svg/structure_flat.svg?inline';
import StructureNested from '@/components/svg/structure_nested.svg?inline';
import SortAZ from '@/components/svg/sort-a-z.svg?inline';
import HelpMenu from '@/components/svg/help_menu.svg?inline';

// SVG Components (with props):
import Chevron from '@/components/svg-components/Chevron.vue';
import Search from '@/components/svg-components/Search.vue';
import X from '@/components/svg-components/X.vue';
import Triangle from '@/components/svg-components/Triangle.vue';
import {
  computed, defineComponent, PropType,
} from 'vue';
import LinkIcon from '@/components/svg/link.svg?inline';
import Filter from '@/components/svg/filter.svg?inline';

const icons: any = {
  BarChat,
  Help,
  HelpMenu,
  Lighthouse,
  LinkIcon,
  Goto,
  Back,
  DotsDots,
  Chevron,
  CommentDots,
  Dashboard,
  EditCircle,
  Envelope,
  Expandable,
  ExpandableVertical,
  Home,
  JoinTable,
  Lightbulb,
  lightbulbSmall,
  MenuDots,
  NewTable,
  Participate,
  PenEdit,
  WritingPen,
  Search,
  SendArrow,
  Settings,
  Share,
  SmallBar,
  Sort,
  Times,
  Triangle,
  Users,
  X,
  Tag,
  Filter,
  StructureFlat,
  StructureNested,
  SortAZ,
  ToolbarCircle
};

export default defineComponent({
  name: 'BCXIcon',
  inheritAttrs: false,
  props: {
    mdi: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    highlight: { type: Boolean },
    addOutsideArea: {
      type: Number,
      default: 0
    },
    setOutsideArea: {
      type: Object as PropType<{ width: number; height: number } | null>,
      default: null
    }
  },
  setup(props, { listeners }) {
    const isClickable = !!listeners.click;

    return {
      component: computed(() => icons[props.icon]),
      isClickable,
    };
  }
});
