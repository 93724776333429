// So sad this has to exist ;-(

import { Project, ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import { Tag } from '@/models/Tags';

const convertProjectToProjectListItemProject = (project: Project): ProjectListProject => ({
  creator: project.creator,
  created: project.created ?? '1850-01-01',
  lastUpdated: '1850-01-01',
  latestAddedReply: project.latestAddedReply ?? '1850-01-01',
  hoursPerWeek: project.hoursPerWeek,
  status: project.status,
  title: project.title,
  dependingOnComplexity: project.dependingOnComplexity,
  durationAmount: project.durationAmount,
  durationUnit: project.durationUnit,
  id: project.id,
  location: project.projectLocation,
  tagAssignmentList: project.tagAssignmentList as unknown as Tag[],
  metaDataProject: project.metaDataProject,
  voteResult: project.metaDataVoting.voteResult,
  numberOfReplies: 9999,
  numberOfViews: 9999,
  startTime: project.startDate
});

export default convertProjectToProjectListItemProject;
