

import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import { ProfileStoreKey } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import { DELETED_USER_FULLNAME } from '@/state/quickInfo';
import Vue, {
  computed, onMounted, provide, watch, watchEffect
} from 'vue';
import ProfileFreelancer from '@/views/profile/components/profile-types/ProfileFreelancer.vue';
import ProfileGuest from '@/views/profile/components/profile-types/ProfileGuest.vue';
import ProfileCompany from '@/views/profile/components/profile-types/ProfileCompany.vue';
import { useRouter } from 'vue2-helpers/vue-router';
import BCXConfirm from '@/components/molecules/BCXConfirm.vue';
import useIsLeavingDirty from '@/utils/isLeavingDirty';
import useUser from '@/mixins/useUser';
import usePageState from '@/state/pageState';
import MarvinInfoBox from '@/views/recommendations/components/MarvinInfoBox.vue';

export default Vue.extend({
  name: 'Profile',
  components: {
    MarvinInfoBox,
    ProfileCompany,
    ProfileGuest,
    ProfileFreelancer,
    BCXConfirm,
  },
  setup() {
    const {
      init, isSelf, user, profile, isEditSectionOpen, isSectionFilled, isQuickInfo, isLoading, editSections
    } = useProfileState(ProfileStoreKey.PROFILE);
    const { t } = useI18n();
    const router = useRouter();
    const { isMobileWidth } = useResponsiveness();
    const { pageNotFound } = usePageState();
    init();

    const {
      isCustomer, isGuest, isEmployee, displayName
    } = useUser(user);

    const showProfile = computed(() => !!user.value && !!profile.value);

    const checkForDeletedUser = async () => {
      const ivUserLoaded = setInterval(() => {
        if (!isLoading.value) {
          clearInterval(ivUserLoaded);
          if ((user.value?.fullname ?? '').trim().toLowerCase() === DELETED_USER_FULLNAME) {
            router.push('/user-search');
          }
        }
      }, 250);
    };

    watch(() => user.value?.fullname, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        checkForDeletedUser();
      }
    });

    const {
      isLeavingDirty, isLeavingMessage, onConfirm, isDirty
    } = useIsLeavingDirty();
    watchEffect(() => {
      if (editSections.value.length > 0) {
        if (editSections.value.length > 1 || !editSections.value.includes('basic')) {
          isDirty.value = true;
        }
      } else {
        isDirty.value = false;
      }
    });

    onMounted(() => {
      checkForDeletedUser();
    });

    // check if loading of profile fails:
    watch(isLoading, (is) => {
      if (!is && !profile.value) {
        pageNotFound.value = true;
        router.push('/user-search');
      }
    });

    return {
      isLoading,
      isMobileWidth,
      isEditSectionOpen,
      showProfile,
      isSelf,
      profile,
      isSectionFilled,
      isQuickInfo,
      isCustomer,
      isGuest,
      isEmployee,
      isLeavingDirty,
      isLeavingMessage,
      onConfirm,
      isDirty,
    };
  },
});
