
import Vue, {
  computed, defineComponent, PropType, ref, toRefs,
  watch
} from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useStore } from 'vue2-helpers/vuex';
import { whenever } from '@vueuse/core';
import ThreadEntry from './ThreadEntry.vue';
import CreateMessage from './CreateMessage.vue';
import { Message, MessagePost } from '../models/Forum';

const SLOW_CONNECTION_TIMEOUT = 1000; // ms

export default defineComponent({
  name: 'ThreadReply',
  components: {
    ThreadEntry,
    CreateMessage,
    BCXMarkdown,
  },
  props: {
    reply: {
      type: Object as PropType<Message>,
      default: null,
    },
    threadId: {
      type: String,
      default: undefined,
    },
    disableReply: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
      default: undefined,
    },
    forumId: {
      type: String,
      default: undefined,
    },
    editMessageId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const isSmartphoneWidth = computed(() => store.state.page.isSmartphoneWidth);
    const isUpdatingMessage = computed(() => store.state.forum.isUpdatingMessage);
    const currentMessageUpdating = ref(false);
    const {
      reply, editMessageId, threadId, forumId
    } = toRefs(props);
    const editMessageVisible = computed({
      get: () => editMessageId.value === reply.value.messageId,
      set: (value: boolean) => {
        emit('edit-message', value ? props.reply.messageId : '');
      },
    });
    const editMessageAttachedTo = computed(() => {
      if (isMobileLayout.value) {
        return '#app';
      }
      return `#message-${reply.value.messageId}`;
    });

    const messageRecentlyUpdated = ref(false);

    watch(() => isUpdatingMessage.value, (value) => {
      if (value) {
        setTimeout(() => {
          if (messageRecentlyUpdated.value && isUpdatingMessage.value) {
            currentMessageUpdating.value = true;
          }
        }, SLOW_CONNECTION_TIMEOUT);
      } else {
        currentMessageUpdating.value = false;
      }
    });

    whenever(() => messageRecentlyUpdated.value && editMessageId.value.length > 0, () => {
      messageRecentlyUpdated.value = false;
    });

    const toggleEditMessage = () => {
      emit('edit-message', reply.value.messageId);
    };

    const postMessage = (postMessage: MessagePost) => {
      emit('post-message', postMessage);
    };

    const postMessageEdit = (text: string) => {
      const postMessage = {
        forumId: forumId.value,
        threadId: threadId.value,
        forumThreadMessageId: reply.value.messageId,
        messageContent: text,
      };
      emit('update-message', postMessage, props.reply.messageCount);
      messageRecentlyUpdated.value = true;
      toggleEditMessage();
    };

    return {
      isMobileLayout,
      isSmartphoneWidth,
      isUpdatingMessage,
      editMessageVisible,
      editMessageAttachedTo,
      currentMessageUpdating,
      postMessage,
      postMessageEdit,
      toggleEditMessage,
    };
  },
});
