import { BaseUserWithRole, ExternalUser, User } from '@/models/User';
import { City } from '@/models/Geo';
import { Tag } from '@/models/Tags';
import { Thread } from '@/views/forum/models/Forum';
import { ExternalTagAssignment, UserVoted } from '@/services/TaggingService';
import { MetaDataVoting, UserMetaDataVoting } from '@/models/Voting';
import { ExternalCompany } from '@/__generated__/types';

type projectType = string | 'HANDS_ON' | 'CONSULTING' | 'TRAINING' | 'PROJECT_MANAGEMENT' | 'CONCEPT' | 'NONE';
export type status = string | 'HOLD' | 'ACTIVE' | 'CLOSED';
type projectSubStatusHold = string | 'NEEDS_APPROVAL' | 'NEEDS_ACTION';
type projectSubStatusActive = string | 'PUBLIC_OPEN' | 'PUBLIC_CLOSED' | 'PRIVATE';
type projectSubStatusClosed = string | 'USER' | 'AUTO' | 'VIOLATION';
type projectLocation = string | 'REMOTE_ONLY' | 'ONSITE_ONLY' | 'ONSITE_AND_REMOTE';
type durationUnit = string | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH';
type remoteWork = string | 'ONE_DAY_PER_WEEK' | 'TWO_DAYS_PER_WEEK' | 'THREE_DAYS_PER_WEEK' | 'FOUR_DAYS_PER_WEEK' | 'NEGOTIABLE';
type paymentType = string | 'PREPAY' | 'POSTPAY' | 'DIRECT';
type companySize = string | 'UNKNOWN' | 'SMALLER_THAN_50' | 'RANGE_50_500' | 'GREATER_THAN_500';

export interface PostTagAssignmentList {
  externalTagId?: string;
  newTagName?: string;
  tagType?: string;
}
export interface PostProject {
  id?: string;
  title: string;
  descriptionLong: string;
  status: status;
  testFlag: boolean;
  subStatusHold?: projectSubStatusHold;
  subStatusActive?: projectSubStatusActive;
  subStatusClosed?: projectSubStatusClosed;
  type: projectType;
  paymentType: paymentType;
  cityPostalCode: string;
  cityPlacename: string;
  countryId: string;
  hourlyRateInEUR: number;
  startDate: string;
  startDateLatest: any;
  optionToExtendDuration: boolean;
  remotePercentage: number;
  companyId: string;
  companySize: companySize;
  projectLocation: projectLocation;
  remoteWork?: remoteWork;
  teamSize: number;
  industryTagId: string;
  durationAmount: number;
  durationUnit: durationUnit;
  hoursPerWeek: number;
  dependingOnComplexity?: boolean;
  tagAssignmentList: PostTagAssignmentList[];
  created?: string;
  creator?: BaseUserWithRole;
}
export interface UserMetaDataProject{
  flagged: boolean;
  read: boolean;
}
export interface MetaDataProject {
  testFlag?: boolean;
}

export interface ProjectOffersInfo {
  acceptsOffers: boolean;
  maxOffers: number;
  remainingOffers: number;
}
export interface Project {
  id: string;
  title: string;
  descriptionLong: string;
  status: status;
  flagged: boolean;
  paymentType: string;
  city: City;
  durationAmount: number;
  durationUnit: durationUnit;
  hoursPerWeek: number;
  dependingOnComplexity?: boolean;
  industryTagId: string;
  projectLocation: projectLocation;
  remoteWork: remoteWork;
  teamSize: number;
  type: projectType;
  hourlyRateInEUR: number;
  startDate: string;
  startDateLatest: any;
  optionToExtendDuration: boolean;
  remotePercentage: number;
  creator: ExternalUser;
  companySize: companySize;
  tagAssignmentList: ExternalTagAssignment[];
  metaDataVoting: MetaDataVoting;
  userMetaDataVoting: UserMetaDataVoting;
  userMetaDataProject: UserMetaDataProject;
  metaDataProject: MetaDataProject;
  company?: ExternalCompany;
  created?: string;
  autoRejectAllOffers?: boolean;
  latestAddedReply?: string;
  offersInfo: ProjectOffersInfo;
}

export interface ProjectListProject {
  creator: ExternalUser;
  created: string;
  lastUpdated: string;
  latestAddedReply: string;
  id: string;
  title: string;
  status: status;
  startTime: string;
  dependingOnComplexity?: boolean;
  durationAmount: number;
  durationUnit: durationUnit;
  hoursPerWeek: number;
  location: projectLocation;
  tagAssignmentList: Tag[];
  voteResult: number;
  numberOfReplies: number;
  numberOfViews: number;
  metaDataProject: MetaDataProject;
}
export interface ProjectForumMessage {
  messageId: string;
  text: string;
  created: string;
  creator: User;
  downvoteCount: number;
  upvoteCount: number;
  voteResult: number;
}

export interface ProjectListSort {
  sortParam: string;
  sortOrder: 'ASC' | 'DESC';
}

export const emptyProject: Project = {
  city: {
    adminCode1: '', adminName1: '', countryCode: '', placeName: '', postalCode: ''
  },
  creator: {
    firstname: '', lastname: '', userId: '', company: {}, companyRole: 'GUEST'
  },
  flagged: false,
  companySize: 'RANGE_50_500',
  descriptionLong: '',
  durationAmount: 0,
  durationUnit: '',
  id: '',
  hourlyRateInEUR: 0,
  hoursPerWeek: 40,
  industryTagId: '',
  optionToExtendDuration: true,
  paymentType: '',
  projectLocation: '',
  remotePercentage: 0,
  remoteWork: '',
  startDate: '',
  startDateLatest: '',
  status: '',
  teamSize: 0,
  title: '',
  type: '',
  metaDataVoting: {
    downvoteCount: 0,
    upvoteCount: 0,
    voteResult: 0,
  },
  metaDataProject: {
    testFlag: false,
  },
  tagAssignmentList: [],
  userMetaDataVoting: {
    userVoted: UserVoted.NOTVOTED
  },
  userMetaDataProject: {
    flagged: false,
    read: false,
  },
  offersInfo: {
    acceptsOffers: true,
    maxOffers: 1,
    remainingOffers: 1,
  },
};

export const emptyProjectThread: Thread = {
  created: '',
  creator: {
    firstname: '', fullname: '', lastname: '', userId: '',
  },
  forumId: '',
  groupId: '',
  threadId: '',
  groupType: '',
  forumThreadMetaData: {
    numberOfReplies: 0,
    numberOfViews: 0,
    latestReplyMessageUser: { firstname: '', lastname: '', userId: '' },
    latestReplyMessageUserId: '',
    latestReplyMessageId: '',
    latestReplyMessageTimestamp: '',
    userSubscribed: false,
    voteResult: 0
  },
  initialMessage: {
    created: '',
    creator: {
      firstname: '', fullname: '', lastname: '', userId: '',
    },
    downvoteCount: 0,
    messageId: '',
    text: '',
    upvoteCount: 0,
    voteResult: 0,
  },
  title: '',
  messages: [],
  tagAssignmentList: [],
};
