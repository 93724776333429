
import { ForumMaxChars } from '@/views/forum/models/Forum';
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import { mapActions, mapGetters, mapState } from 'vuex';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import ProjectsIcon from '@/components/svg/projects.svg?inline';
import ProjectListItem from '@/views/project-forum/components/ProjectListItem.vue';
import ProjectListItemSkeleton from '@/views/project-forum/components/ProjectListItemSkeleton.vue';
import { PostSubscription, Subscription } from '@/views/subscriptions/models/Subscription';
import ProjectListFilter from '@/views/project-forum/components/ProjectListFilter.vue';
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import BCXInfoHelpText from '@/components/molecules/BCXInfoHelpText.vue';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';
import useUser from '@/mixins/useUser';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';

export default Vue.extend({
  name: 'ProjectList',
  components: {
    BCXHelpTextTriggerIcon,
    BCXInfoHelpText,
    BCXInfoCollapsible,
    BCXBreadcrumbs,
    ProjectsIcon,
    ProjectListItem,
    ProjectListItemSkeleton,
    ProjectListFilter,
  },
  provide: () => ({
    maxCreateMessageTextLength: ForumMaxChars.PROJECT_REPLY
  }),
  setup() {
    const { isGuest } = useUser('self');

    return {
      isGuest
    };
  },
  data() {
    return {
      watchProjects: false,
      showMobileSort: false,
      showTags: true,
      activeFilters: ['all'],
      isInlineHelpOpened: false,
    };
  },
  computed: {
    ...mapGetters('projectForum', ['getProjectListSorted', 'getNumberOfProjects']),
    ...mapState('projectForum', ['projectListSort', 'isFetchingProjects']),
    ...mapGetters('page', ['isMobileLayout']),
    ...mapState('subscriptions', ['subscriptionList']),
    sortParam(): string {
      return this.projectListSort.sortParam;
    },
    sortOrder(): string {
      return this.projectListSort.sortOrder;
    },
    breadcrumbItems(): Array<any> {
      const breadcrumbItems: Array<any> = [
        { text: this.tUser('project-forum_USERTYPE.labels.projects'), to: { name: 'projectList' } },
        { text: this.tUser('project-forum_USERTYPE.labels.all-projects'), to: { name: 'projectList' } },
      ];
      if (this.isMobileLayout) { // display logo as first item
        breadcrumbItems.unshift({ text: 'ByteCookie' });
      }
      return breadcrumbItems;
    },
    sortItems(): Array<any> {
      return [
        { name: this.tUser('project-forum_USERTYPE.labels.sort.created'), value: 'CREATED' },
        { name: this.tUser('project-forum_USERTYPE.labels.sort.latest'), value: 'LATEST' },
        { name: this.tUser('project-forum_USERTYPE.labels.sort.title'), value: 'TITLE' },
        { name: this.tUser('project-forum_USERTYPE.labels.sort.views'), value: 'VIEWS' },
        { name: this.tUser('project-forum_USERTYPE.labels.sort.votes'), value: 'VOTES' },
      ];
    },
    createProjectLink(): RawLocation {
      return {
        name: 'createProject',
      };
    },
    dummyThreadLink(): RawLocation {
      return {
        name: 'projectForumThread',
      };
    },
    allProjectsSubscribed(): Subscription | false {
      let isSubscribed: Subscription | false = false;
      this.subscriptionList.forEach((subscription: Subscription) => {
        if (subscription.type === 'PROJECTS') {
          isSubscribed = subscription;
        }
      });
      return isSubscribed;
    },
    projectCountText(): string {
      if (this.getNumberOfProjects === 1) {
        return `${this.getNumberOfProjects} ${this.tUser('project-forum_USERTYPE.labels.project')}`;
      }
      return `${this.getNumberOfProjects} ${this.tUser('project-forum_USERTYPE.labels.projects')}`;
    },
    filters(): any {
      return Object.entries(this.tUser('project-forum_USERTYPE.overview.filters'))
        .map(([value, text]) => ({ text, value }));
    },
    filteredProjects(): ProjectListProject[] {
      const hasAllFilter = this.activeFilters.includes('all');
      const hasOpenFilter = this.activeFilters.includes('open');
      const hasClosedFilter = this.activeFilters.includes('closed');
      const hasTestFilter = this.activeFilters.includes('test');

      return this.getProjectListSorted.filter((project: ProjectListProject) => {
        if (project.metaDataProject?.testFlag) return hasTestFilter;
        return (
          hasAllFilter
            || (hasClosedFilter && project.status === 'CLOSED')
            || (hasOpenFilter && project.status === 'ACTIVE')
        );
      });
    }
  },
  watch: {
    watchProjects(newValue) {
      const { allProjectsSubscribed } = this;
      if (newValue && !allProjectsSubscribed) {
        const postSubscriptionData: PostSubscription = {
          businessObjectId: '',
          subscriptionType: 'PROJECTS',
        };
        this.postSubscription(postSubscriptionData);
      } else if (!newValue && allProjectsSubscribed) {
        this.deleteSubscription(allProjectsSubscribed.subscriptionId);
      }
    },
    allProjectsSubscribed(newValue) {
      if (newValue !== false) {
        this.watchProjects = true;
      }
    }
  },
  mounted() {
    if (this.allProjectsSubscribed) {
      this.watchProjects = true;
    }
  },
  methods: {
    ...mapActions('projectForum', ['sortProjectList']),
    ...mapActions('subscriptions', ['postSubscription', 'deleteSubscription']),

    tUser(key: string, ...params:any) {
      const { tUserFallback } = useUser('self');
      return tUserFallback(key, ...params);
    },
    createProject() {
      this.$router.push({ name: 'createProject' });
    },
    toggleShowTags() {
      this.showTags = !this.showTags;
    },
    toggleShowMobileSort() {
      this.showMobileSort = !this.showMobileSort;
    },
    isSortActive(sort: string):string {
      return (sort === this.projectListSort.sortParam) ? 'active' : '';
    },
    sort(sortParam: string) {
      let { sortOrder } = this;
      if (sortParam === this.sortParam && this.sortOrder === 'ASC') {
        sortOrder = 'DESC';
      } else if (sortParam === this.sortParam && this.sortOrder === 'DESC') {
        sortOrder = 'ASC';
      } else if (sortParam === 'VOTES') {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      this.sortProjectList({ sortParam, sortOrder });
    },
  },
});

