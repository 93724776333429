import { updatePermissions } from '@/ability/helpers';
import { ExternalUser, User } from '@/models/User';
import router from '@/router';
import AuthenticationService from '@/services/AuthenticationService';
import useTokenState from '@/state/tokenState';
import getJSONFromLocalStorage from '@/utils/getJSONFromLocalStorage';
import { useLocalStorage } from '@vueuse/core';
import moment from 'moment';
import { RawLocation } from 'vue-router';
import { Module } from 'vuex';

const { accessToken, clear: clearToken, refreshToken } = useTokenState();

interface State {
  user: User | ExternalUser | null;
  redirectUrl: RawLocation | null;
}

const module: Module<State, any> = {
  state: {
    user: getJSONFromLocalStorage('user'),
    redirectUrl: null,
  },
  getters: {
    user: (state) => state.user,
    getRedirectUrl: (state) => state.redirectUrl,
  },
  mutations: {
    authSuccess(state, data) {
      accessToken.value = data.token;
      refreshToken.value = data.refreshToken;
    },

    clearUserAndToken(state) {
      state.user = null;
      localStorage.removeItem('user');
      clearToken();
    },

    setUser(state, user: User | ExternalUser) {
      const storedUser = useLocalStorage('user', JSON.stringify(user));
      state.user = user;
      storedUser.value = JSON.stringify(user);
    },

    setRedirectUrl(state, data: RawLocation | null) {
      state.redirectUrl = data;
    }
  },
  actions: {
    login({ commit, getters }, params: {
      email: string;
      password: string;
    }) {
      return new Promise<void>((resolve, reject) => {
        AuthenticationService.login(
          params.email,
          params.password,
        )
          .then((data) => {
            commit('authSuccess', { token: data.token });
            commit('setUser', data.externalUser);
            localStorage.setItem('refresh_token', data.refreshToken);
            refreshToken.value = data.refreshToken;

            updatePermissions((data.externalUser?.roles ?? []), (data.externalUser?.permissions ?? []));

            if (data.metaData) {
              if (data.metaData.mobilephoneNumber) {
                commit('mobileNumber', { mobileNumber: data.metaData.mobilephoneNumber });
              }
              if (data.metaData.registrationProcessId) {
                commit('registrationProcessId', {
                  registrationProcessId: data.metaData.registrationProcessId,
                });
              }
              commit('registrationStepPlain', { registrationStepPlain: data.metaData.nextRegistrationStep });
              const expiresAt = moment().add(process.env.VUE_APP_REGISTRATION_EXPIRES_AFTER, 'minutes').format();
              commit('expiresAt', { expiresAt });
              router.push({ name: getters.registrationStepPlainName });
            } else if (getters.getRedirectUrl) {
              router.push(getters.getRedirectUrl);
              commit('setRedirectUrl', null);
            } else {
              router.push({ name: 'home' });
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    logout({ commit }) {
      commit('clearUserAndToken');
      ['forum-overview-visited', 'locale', 'selected_audio_input', 'selected_camera',
        'threadsViewed', 'video_expansion_panels', 'user_roles', 'user_permissions',
        'token_expires', 'availability', 'last_reload'].forEach((item) => localStorage.removeItem(item));
      sessionStorage.clear();
    },
  },
};

export default module;
