
import useI18n from '@/mixins/useI18n';
import {
  computed, defineComponent, PropType, toRef
} from 'vue';
import { CompanyGuestListResponse } from '@/models/Company';
import BytecookieLogo from '@/components/svg/logo/logo-circles-only.svg?inline';
import BCXAvatar from './BCXAvatar.vue';

export default defineComponent({
  name: 'BCXUnregisteredUser',
  components: {
    BCXAvatar,
    BytecookieLogo,
  },
  props: {
    user: {
      type: Object as PropType<CompanyGuestListResponse>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const user = toRef(props, 'user');
    const displayName = computed(() => `${user.value.firstname} ${user.value.lastname}`);

    const userIdImage = computed(() => user.value?.company?.rootUserId ?? null);

    return {
      t,
      displayName,
      userIdImage,
    };
  }
});
