
import { computed, defineComponent, PropType } from 'vue';
import useI18n from '@/mixins/useI18n';
import ProjectGuestsInviteNew from '@/views/project-forum/components/ProjectGuestsInviteNew.vue';
import ProjectGuestsAddExisting from '@/views/project-forum/components/ProjectGuestsAddExisting.vue';
import ProjectGuestsTable from '@/views/project-forum/components/ProjectGuestsTable.vue';
import useProjectGuestState from '@/state/projectGuestState';

export default defineComponent({
  name: 'ProjectGuests',
  components: {
    ProjectGuestsInviteNew,
    ProjectGuestsAddExisting,
    ProjectGuestsTable,
  },
  props: {
    mode: {
      type: String as PropType<'create' | 'edit'>,
      default: 'create',
    },
  },
  setup(props) {
    const { t } = useI18n();

    const { projectGuestList, projectList, companyGuestList } = useProjectGuestState();

    const hasGuests = computed(() => projectGuestList.value.length > 0);

    return {
      t,
      projectList,
      hasGuests,
      companyGuestList,
    };
  },
});
