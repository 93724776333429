
import { ForumMaxChars } from '@/views/forum/models/Forum';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import ProjectForumSection from '@/views/project-forum/components/ProjectForumSection.vue';
import BcxProgressCookies from '@/components/molecules/BCXProgressCookies.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import LightBulb from '@/components/svg/profile-lightbulb.svg?inline';
import ProjectTitle from '@/views/project-forum/components/form-components/ProjectTitle.vue';
import Client from '@/views/project-forum/components/form-components/Client.vue';
import Conditions from '@/views/project-forum/components/form-components/Conditions.vue';
import Description from '@/views/project-forum/components/form-components/Description.vue';
import TagList from '@/components/tagging/TagList.vue';
import { Tag } from '@/services/TaggingService';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import {
  computed, defineComponent, onMounted, reactive, ref, watchEffect
} from 'vue';
import BCXConfirm from '@/components/molecules/BCXConfirm.vue';
import useIsLeavingDirty from '@/utils/isLeavingDirty';
import { useStore } from 'vue2-helpers/vuex';
import { useRouter } from 'vue2-helpers/vue-router';
import useUser from '@/mixins/useUser';
import { PostProject } from '@/views/project-forum/models/ProjectForum';
import ProjectNewProjectLimitReached from '@/views/project-forum/components/ProjectNewProjectLimitReached.vue';
import useUserProjectCount from '@/mixins/useUserProjectCount';
import { Profile } from '@/models/Profile';
import { getOwnProfile } from '@/services/Profile/Api';
import MarvinInfoBox from '@/views/recommendations/components/MarvinInfoBox.vue';

export default defineComponent({
  components: {
    MarvinInfoBox,
    ProjectNewProjectLimitReached,
    BCXBreadcrumbs,
    ProjectForumSection,
    BcxProgressCookies,
    BCXCheckbox,
    LightBulb,
    ProjectTitle,
    Client,
    Conditions,
    Description,
    TagList,
    BCXMarkdown,
    BCXConfirm
  },
  provide: () => ({
    maxCreateMessageTextLength: ForumMaxChars.PROJECT_REPLY
  }),
  setup() {
    const store = useStore();
    const router = useRouter();
    const { tUserFallback: t, isCustomer, user } = useUser('self');
    const profile = ref<Profile>();

    const { isRemainingProjectsLoaded, noRemainingProjectsLeft, loadRemainingProjectsCount } = useUserProjectCount();
    loadRemainingProjectsCount();

    const thread = reactive({
      title: '',
      content: ''
    });

    const title = ref('');

    const projectClient = ref({
      countryCode: '',
      city: '',
      companySize: 'RANGE_50_500',
      industry: '',
      cityPlaceName: '',
      cityPostalCode: ''
    });

    const projectConditions = ref({
      location: 'REMOTE_ONLY',
      remoteWork: 'TWO_DAYS_PER_WEEK',
      teamSize: 3,
      startDate: '',
      durationAmount: 3,
      durationUnit: 'MONTH',
      hoursPerWeek: 40,
      dependingOnComplexity: false
    });

    const projectDescription = ref('');

    const currentStatus = ref(1);
    const statusCount = 5;
    const statusPages = ['title', 'projectClient', 'projectConditions', 'projectDescription', 'projectTags'];
    const currentStatusPage = computed(() => statusPages[currentStatus.value - 1]);
    const nextStatus = () => {
      currentStatus.value++;
    };
    const previousStatus = () => {
      currentStatus.value--;
    };

    const getCookieStatusClass = (status: number): string => `progress-cookies--cookie ${status > currentStatus.value ? 'previous-cookie' : 'current-cookie'}`;
    const currentCookieStatus = computed(() => currentStatus.value);

    const titleKeyDown = (ev: any) => {
      if (ev.keyCode === 13) {
        nextStatus();
      }
    };

    const projectRoleTags = ref<Tag[]>([]);
    const projectSkillTags = ref<Tag[]>([]);
    const testFlag = ref(false);
    const isSaving = ref(false);

    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const countries = computed(() => store.state.projectForum.countries);
    const cities = computed(() => store.state.projectForum.cities);
    const industries = computed(() => store.state.projectForum.industries);

    const breadcrumbItems = computed(() => {
      const breadcrumbItems:Array<{text:string; to?:any}> = [
        {
          text: t('project-forum_USERTYPE.labels.projects').toString(),
          to: { name: 'projectList' }
        },
        {
          text: t('project-forum_USERTYPE.labels.create-project').toString(),
          to: { name: 'createProject' }
        }
      ];
      if (isMobileLayout.value) {
        // display logo as first item
        breadcrumbItems.unshift({ text: 'ByteCookie' });
      }
      return breadcrumbItems;
    });

    const requirementsMet = computed(() => [
      true, // not used
      title.value.length > 2,
      (projectClient.value.countryCode.length > 0 && projectClient.value.cityPlaceName.length > 0),
      projectConditions.value.location.length > 0,
      projectDescription.value.length > 2
    ]);

    const cookieCount = computed(() => statusCount - 1);
    const showTestFlag = false;

    const postNewProject = (postProject: PostProject) => store.dispatch('projectForum/postNewProject', postProject);
    const fetchCountries = () => store.dispatch('projectForum/fetchCountries');
    const fetchIndustries = () => store.dispatch('projectForum/fetchIndustries');

    const projectListLink = () => {
      router.push({ name: 'projectList' });
    };

    const {
      isLeavingDirty, isLeavingMessage, onConfirm, isDirty
    } = useIsLeavingDirty();
    watchEffect(() => {
      isDirty.value = (currentStatus.value > 1 || title.value.length > 0);
    });

    const finish = () => {
      if (!isSaving.value) {
        isSaving.value = true;
        const postProject: PostProject = {
          title: title.value,
          descriptionLong: projectDescription.value.trim(),
          status: 'ACTIVE',
          testFlag: testFlag.value,
          type: 'NONE',
          paymentType: 'DIRECT',
          cityPlacename: projectClient.value.cityPlaceName,
          cityPostalCode: projectClient.value.cityPostalCode,
          countryId: projectClient.value.countryCode,
          hourlyRateInEUR: 0,
          startDate: projectConditions.value.startDate,
          startDateLatest: projectConditions.value.startDate,
          optionToExtendDuration: true,
          remotePercentage: 0,
          companyId: '',
          companySize: projectClient.value.companySize,
          projectLocation: projectConditions.value.location,
          remoteWork: projectConditions.value.remoteWork,
          teamSize: projectConditions.value.teamSize || 0,
          industryTagId: projectClient.value.industry,
          durationAmount: projectConditions.value.durationAmount || 0,
          durationUnit: projectConditions.value.durationUnit,
          hoursPerWeek: projectConditions.value.hoursPerWeek || 0,
          dependingOnComplexity: projectConditions.value.dependingOnComplexity,
          tagAssignmentList: [
            ...projectRoleTags.value.map((tag: Tag) => {
              if (tag.tagId !== '') {
                return { externalTagId: tag.tagId, newTagName: tag.tagName, newTagType: tag.tagType };
              }
              return { externalTagId: '', newTagName: tag.tagName, newTagType: tag.tagType };
            }),
            ...projectSkillTags.value.map((tag: Tag) => {
              if (tag.tagId !== '') {
                return { externalTagId: tag.tagId, newTagName: tag.tagName, newTagType: tag.tagType };
              }
              return { externalTagId: '', newTagName: tag.tagName, newTagType: tag.tagType };
            })
          ]
        };
        if (projectConditions.value.location !== 'ONSITE_AND_REMOTE') {
          postProject.remoteWork = undefined;
        }
        postNewProject(postProject)
          .then((project) => {
            isDirty.value = false;
            router.push({ name: 'projectGuests', params: { projectId: project.id } });
          })
          .finally(() => {
            isSaving.value = false;
          });
      }
    };

    onMounted(async () => {
      fetchCountries();
      fetchIndustries();
      profile.value = await getOwnProfile();
      if (isCustomer.value) {
        const employeeCount: number = profile.value?.company?.employeeCount ?? 0;
        if (employeeCount > 0) {
          if (employeeCount > 500) {
            projectClient.value.companySize = 'GREATER_THAN_500';
          } else if (employeeCount > 50) {
            projectClient.value.companySize = 'RANGE_50_500';
          } else {
            projectClient.value.companySize = 'SMALLER_THAN_50';
          }
        }
      }
    });

    return {
      t,
      breadcrumbItems,
      thread,
      title,
      projectClient,
      projectConditions,
      projectDescription,
      currentStatus,
      currentCookieStatus,
      currentStatusPage,
      statusCount,
      nextStatus,
      previousStatus,
      getCookieStatusClass,
      projectRoleTags,
      projectSkillTags,
      testFlag,
      isSaving,
      isMobileLayout,
      countries,
      cities,
      industries,
      titleKeyDown,
      isLeavingDirty,
      isLeavingMessage,
      onConfirm,
      requirementsMet,
      cookieCount,
      showTestFlag,
      postNewProject,
      projectListLink,
      finish,
      noRemainingProjectsLeft,
      isRemainingProjectsLoaded,
      user
    };
  }
});
