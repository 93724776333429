var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"bc-checkbox",class:[{
    'bc-checkbox--readonly': _vm.readonly,
    'bc-checkbox--is-radio': _vm.isRadio,
    'bc-checkbox--invalid': _vm.invalid,
    'bc-checkbox--is-box-outside': _vm.isBoxOutside,
  }, [`bc-checkbox--type-${_vm.type}`, `bc-checkbox--align-${_vm.align}`]],style:({
    '--cb-gap': _vm.gap
  })},[_c('input',_vm._b({ref:"input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.checked},on:{"change":_setup.onChange}},'input',_vm.$attrs,false)),_c('div',[(_vm.isRadio)?_c('div',{staticClass:"c-selected-radio-dot"}):_c(_setup.IconCheckmark)],1),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }