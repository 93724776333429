import { render, staticRenderFns } from "./ProjectForumThread.vue?vue&type=template&id=9924ab0e&scoped=true"
import script from "./ProjectForumThread.vue?vue&type=script&lang=ts"
export * from "./ProjectForumThread.vue?vue&type=script&lang=ts"
import style0 from "./ProjectForumThread.vue?vue&type=style&index=0&id=9924ab0e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9924ab0e",
  null
  
)

export default component.exports