import axios from '@/plugins/axios';
import { autoEncodeUserId } from '@/utils/userIdEncoding';
import { AxiosResponse } from 'axios';
import { SetPasswortRequest } from '@/models/User';

interface VerifyCodeResponse extends AxiosResponse {
  data: {
    mobilephone: string;
  };
}

export default {
  async verifyCode(
    code: string,
    userId: string,
  ): Promise<VerifyCodeResponse> {
    return axios.post('/v1/users/user/password/reset/verify', {
      code,
      userId: autoEncodeUserId(userId)
    }).catch(({ response }) => response);
  },

  async resetPassword(
    email: string,
  ): Promise<any> {
    return axios.post('/v1/users/user/password/reset', { email });
  },

  async setPassword(request: SetPasswortRequest): Promise<AxiosResponse> {
    return axios.post('/v1/users/user/password/reset/set-password', request).catch(({ response }) => response);
  },

  async resendEmail(
    email: string,
  ): Promise<any> {
    return axios.post('/v1/users/user/password/reset/resend-email', { email });
  },

  async resendSmsCode(
    code: string,
    userId: string,
  ): Promise<AxiosResponse> {
    return axios.post('/v1/users/user/password/reset/resend-sms', {
      code,
      userId: autoEncodeUserId(userId),
    }).catch(({ response }) => response);
  },

};
