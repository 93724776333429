
import IconAdd from '@/components/svg/checkmark-circle.svg?inline';
import IconAddFilled from '@/components/svg/checkmark-circle-inverted.svg?inline';
import IconEdit from '@/components/svg/edit-circle.svg?inline';
import IconCircle from '@/components/svg/circle.svg?inline';
import IconRemove from '@/components/svg/minus-circle.svg?inline';
import IconMoveUp from '@/components/svg/arrow-up-circle.svg?inline';
import IconUpload from '@/components/svg/upload-circle.svg?inline';
import IconDownload from '@/components/svg/download-circle.svg?inline';
import IconQuestionMark from '@/components/svg/questionmark-circle.svg?inline';
import IconPlus from '@/components/svg/plus-circle.svg?inline';
import IconPlusFilled from '@/components/svg/plus-filled-circle.svg?inline';
import IconCopy from '@/components/svg/copy.svg?inline';
import IconView from '@/components/svg/view.svg?inline';
import IconX from '@/components/svg/x-circle.svg?inline';
import IconOpenEnvelope from '@/components/svg/envelope-open.svg?inline';
import IconResendEnvelope from '@/components/svg/envelope-resend.svg?inline';
import { defineComponent, PropType } from 'vue';

const components = {
  IconAdd,
  IconAddFilled,
  IconCopy,
  IconRemove,
  IconMoveUp,
  IconUpload,
  IconDownload,
  IconQuestionMark,
  IconEdit,
  IconCircle,
  IconPlus,
  IconPlusFilled,
  IconView,
  IconX,
  IconOpenEnvelope,
  IconResendEnvelope,
};

type ComponentNames = keyof typeof components;
type CamelToKebab<S extends string> = S extends `${infer A}${infer B}`
  ? B extends Uncapitalize<B>
    ? `${Uncapitalize<A>}${CamelToKebab<B>}`
    : `${Uncapitalize<A>}-${CamelToKebab<B>}`
  : S;
type ExtractIconName<T> = T extends `Icon${infer Name}`
  ? CamelToKebab<Name>
  : never;
type ValidIconNames = ExtractIconName<ComponentNames>;

export default defineComponent({
  name: 'EditIcon',
  components,
  props: {
    icon: {
      type: String as PropType<ValidIconNames>,
      required: true
    },
    disabled: Boolean,
  },
});
