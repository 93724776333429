
import { User } from '@/models/User';
import { businessObjectType, VoteReason } from '@/models/Voting';
import {
  computed, ComputedRef, defineComponent, Ref, ref, toRefs, watch, nextTick, onMounted
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';

import IconUpvote from '@/components/svg/voting-up.svg?inline';
import IconDownvote from '@/components/svg/voting-down.svg?inline';

import { VotingState } from '@/store/voting';
import useUser from '@/mixins/useUser';

export default defineComponent({
  components: {
    IconUpvote,
    IconDownvote,
  },
  props: {
    componentStyle: {
      type: String as () => 'column' | 'row',
      default: 'column'
    },
    userVoting: {
      type: String as ()=> 'UP' | 'DOWN' | null,
      default: undefined
    },
    voteResult: {
      type: Number,
      default: 0,
    },
    showVoteResult: {
      type: Boolean,
      default: true,
    },
    businessObjectType: {
      type: String as () => businessObjectType,
      default: 'MESSAGE'
    },
    businessObjectId: {
      type: String,
      required: true,
      default: undefined,
    },
    showDownvote: {
      type: Boolean,
      default: true,
    },
    ratedUser: {
      type: Object as ()=> User,
      required: true,
    },
    directUpvote: {
      type: String as () => VoteReason,
      default: undefined
    }
  },
  setup(props) {
    const {
      userVoting, voteResult, businessObjectType, businessObjectId, ratedUser: ratedUserProp, directUpvote
    } = toRefs(props);

    const store = useStore();

    const votingResult: Ref<number> = ref(0);
    const userVoted: Ref<'UP' | 'DOWN' | null> = ref(null);

    const userVotedUp: ComputedRef<boolean> = computed(() => userVoted.value === 'UP');
    const userVotedDown: ComputedRef<boolean> = computed(() => userVoted.value === 'DOWN');

    const { user, isFreelancer } = useUser('self');
    const { user: ratedUser, isFreelancer: isRatedUserFreelancer } = useUser(ratedUserProp.value);

    const isUpvoteDisabled: ComputedRef<boolean> = computed(() => userVotedDown.value
      || (ratedUser.value.userId === user.value.userId)
      || ((!isFreelancer.value && !isRatedUserFreelancer.value)
        && user.value.company.id === ratedUser.value.company.id));
    const isDownvoteDisabled: ComputedRef<boolean> = computed(() => userVotedUp.value
      || (ratedUser.value.userId === user.value.userId)
      || ((!isFreelancer.value && !isRatedUserFreelancer.value)
        && user.value.company.id === ratedUser.value.company.id));

    const activeVoting: ComputedRef<'UP' | 'DOWN' | null> = computed(() => {
      switch (store.state.voting.activeType) {
        case 'upvote':
          return 'UP';
        case 'downvote':
          return 'DOWN';
        default:
          return null;
      }
    });

    const updatedVote = computed(() => store.state.voting.updatedVoting);

    watch(updatedVote, (newValue) => {
      if (newValue.businessObjectId === businessObjectId.value) {
        votingResult.value = newValue.metaDataVoting.voteResult;
        userVoted.value = activeVoting.value;
        nextTick(() => {
          setTimeout(() => {
            store.dispatch('voting/resetVotingReporting');
          }, 800);
        });
      }
    }, { deep: true });

    function vote(payLoad: VotingState) {
      store.dispatch('voting/setVotingReporting', payLoad);
    }

    function voteUp() {
      const payLoad: VotingState = {
        activeType: 'upvote',
        user: ratedUser.value,
        businessObjectId: businessObjectId.value,
        businessObjectType: businessObjectType.value,
        updatedVoting: null,
        directUpvote: directUpvote.value
      };
      vote(payLoad);
    }

    function voteDown() {
      const payLoad: VotingState = {
        activeType: 'downvote',
        user: ratedUser.value,
        businessObjectId: businessObjectId.value,
        businessObjectType: businessObjectType.value,
        updatedVoting: null,
      };
      vote(payLoad);
    }

    onMounted(() => {
      votingResult.value = voteResult.value;
      userVoted.value = userVoting.value;
    });

    return {
      userVoted,
      userVotedUp,
      userVotedDown,
      isUpvoteDisabled,
      isDownvoteDisabled,
      voteUp,
      voteDown,
      votingResult,
    };
  },
});
