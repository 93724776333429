
import { computed, defineComponent, ref } from 'vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import useUser from '@/mixins/useUser';
import useProjectGuestState from '@/state/projectGuestState';
import AddIcon from '@/views/profile/components/AddIcon.vue';

export default defineComponent({
  name: 'ProjectGuestsAddExisting',
  components: {
    BCXSearchSelect,
    AddIcon,
  },
  setup() {
    const { tUserFallback: t } = useUser('self');
    const {
      companyGuestListRegistered, companyGuestList, projectGuestList, addGuestToProject
    } = useProjectGuestState();
    const guestsInProject = computed(() => projectGuestList.value.map((guest) => guest.id));
    const companyGuestSelectList = computed(() => companyGuestListRegistered.value.map((guest) => {
      if (!guestsInProject.value.includes(guest.id) && guest.active) {
        const user = useUser(guest);
        return {
          value: guest.id as string,
          text: user.displayName.value as string,
        };
      }
      return null;
    }).filter((entry) => entry !== null));
    const searchInput = ref('');
    const canAdd = computed(() => searchInput.value.length > 0);
    const addGuest = async () => {
      const guest = companyGuestListRegistered.value.find((entry) => entry.id === searchInput.value);
      if (guest) {
        await addGuestToProject(guest.id as string);
        searchInput.value = '';
      }
    };

    return {
      t,
      searchInput,
      companyGuestList,
      companyGuestListRegistered,
      companyGuestSelectList,
      addGuest,
      canAdd,
    };
  },
});
