
import Vue, { onUnmounted, watch } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import ProjectForumTopic from '@/views/project-forum/components/ProjectForumTopic.vue';
import ThreadReplies from '@/views/forum/components/ThreadReplies.vue';
import BCXNewStuffBanner from '@/components/molecules/BCXNewStuffBanner.vue';
import { ForumMaxChars, MessagePost } from '@/views/forum/models/Forum';
import ForumService from '@/services/ForumService';
import { PostSubscription } from '@/views/subscriptions/models/Subscription';
import useIsLeavingDirty from '@/utils/isLeavingDirty';
import BCXConfirm from '@/components/molecules/BCXConfirm.vue';
import useUser from '@/mixins/useUser';
import usePageState from '@/state/pageState';
import { useStore } from 'vue2-helpers/vuex';
import ProjectOffersListBox from '@/views/project-forum/components/sidebar-actions/ProjectOffersListBox.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import MarvinInfoBox from '@/views/recommendations/components/MarvinInfoBox.vue';

export default Vue.extend({
  name: 'ProjectForumThread',
  components: {
    MarvinInfoBox,
    ProjectOffersListBox,
    ProjectForumTopic,
    ThreadReplies,
    'new-messages-banner': BCXNewStuffBanner,
    BCXConfirm,
  },
  provide: () => ({
    maxCreateMessageTextLength: ForumMaxChars.PROJECT_REPLY
  }),
  beforeRouteUpdate(to, _from, next) {
    this.clearProjectAndThread();
    const { projectId } = to.params;
    this.fetchProject(projectId).then(() => {
      this.fetchProjectThread(projectId).then(() => {
        this.updateThreadMeta();
        next();
      }).catch(() => {
        this.pageNotFound();
      });
    });
  },
  setup() {
    const store = useStore();
    const {
      isDirty, isLeavingDirty, isLeavingMessage, onConfirm
    } = useIsLeavingDirty();
    const { tUserFallback: t } = useUser('self');
    const { isSidebarVisible } = useResponsiveness();

    const setIsDirty = (value: boolean) => {
      isDirty.value = value;
    };

    onUnmounted(() => {
      clearInterval(window.threadRefreshInterval);
      store.dispatch('projectForum/clearProjectAndThread');
    });

    return {
      isDirty,
      isLeavingDirty,
      isLeavingMessage,
      onConfirm,
      setIsDirty,
      isSidebarVisible,
      t
    };
  },
  data() {
    return {
      newMessagesAvailable: false,
      intervalSeconds: 60,
      numberOfReplies: 0,
      numberOfEdits: 0,
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('projectForum', ['getForumId', 'getThreadId',
      'getThreadRepliesTotal', 'getThreadRepliesSorted', 'getOpenEditMessageId']),
    ...mapState('projectForum', ['thread', 'messagesSort', 'project']),
    ...mapGetters('subscriptions', ['getSubscriptionsByObjectId']),
    isProjectClosed(): boolean {
      return this.project.status === 'CLOSED';
    },
    threadSubscribed(): boolean {
      return this.thread.forumThreadMetaData.userSubscribed || Object.keys(this.getSubscriptionsByObjectId).includes(this.project.id);
    },
    isTestProject(): boolean {
      return this.project.metaDataProject.testFlag || false;
    },
    userIsCreator(): boolean {
      return this.user.userId === this.project.creator.userId;
    },
  },
  mounted() {
    const { projectId } = this.$route.params;
    this.$store.dispatch('projectForum/fetchProject', projectId).then(() => {
      this.$store.dispatch('subscriptions/fetchSubscriptions');
      this.$store.dispatch('projectForum/fetchProjectThread', projectId).then((forum) => {
        const threadsViewed = JSON.parse(window.localStorage.getItem('threadsViewed') || '{}');
        const { threadId, forumId, groupId } = forum.groups[0].threads[0];
        const viewTimestamp = threadsViewed[threadId] || '';
        const currentTimestamp: number = (new Date()).getTime();
        if (viewTimestamp === undefined || viewTimestamp + 30 * 60000 < currentTimestamp) { // Thread-View is counted only every 30 minutes
          ForumService.trackPageViewThread(forumId, threadId, groupId);
          threadsViewed[threadId] = currentTimestamp;
          window.localStorage.setItem('threadsViewed', JSON.stringify(threadsViewed));
        }

        this.$nextTick(() => {
          this.updateThreadMeta();
        });
      }).catch(() => this.pageNotFound());
    }).catch(() => this.pageNotFound());

    window.threadRefreshInterval = setInterval(() => {
      this.updateThreadMeta(true);
    }, this.intervalSeconds * 1000);
  },
  methods: {
    ...mapActions('projectForum', ['fetchProject', 'fetchProjectThread', 'clearProjectAndThread', 'postThreadMessage', 'updateThreadMessage',
      'editThreadMessage', 'sortThreadReplies', 'refreshThread']),
    ...mapActions('subscriptions', ['postSubscription', 'deleteSubscription', 'fetchSubscriptions']),
    loadNewMessages() {
      this.refreshThread().then(() => {
        this.newMessagesAvailable = false;
      });
    },
    updateThreadMeta(compare = false) {
      const { threadId, forumId, groupId } = this.thread;
      ForumService.getThreadMeta(forumId, groupId, threadId).then((threadMeta) => {
        if (compare) {
          const sumOfActionsMeta = (threadMeta.numberOfReplies ?? 0) + (threadMeta.numberOfEdits ?? 0);
          if (sumOfActionsMeta > (this.numberOfReplies + this.numberOfEdits)) {
            this.newMessagesAvailable = true;
          }
        }
        this.numberOfReplies = threadMeta.numberOfReplies;
        this.numberOfEdits = threadMeta.numberOfEdits;
      });
    },
    closeNewMessagesBanner() {
      this.newMessagesAvailable = false;
      this.intervalSeconds = Math.round(this.intervalSeconds * 1.25);
    },
    postMessage(postMessage: MessagePost) {
      this.postThreadMessage(postMessage).then(() => {
        this.fetchSubscriptions();
        this.setIsDirty(false);
      });
      this.numberOfReplies++;
    },
    updateMessage(postMessage: MessagePost, messageCount: string) {
      this.updateThreadMessage({ postMessage, messageCount });
      this.numberOfEdits++;
    },
    editMessage(editMessageId: string) {
      if (this.getOpenEditMessageId === editMessageId) {
        this.editThreadMessage('');
      } else {
        this.editThreadMessage(editMessageId);
      }
    },
    sortReplies(sortParam: string, sortOrder: string) {
      this.sortThreadReplies({ sortParam, sortOrder });
    },
    subscribeToProject() {
      if (!this.threadSubscribed) {
        const postSubscriptionData: PostSubscription = {
          businessObjectId: this.project.id,
          subscriptionType: 'PROJECT',
        };
        this.postSubscription(postSubscriptionData);
      }
    },
    unsubscribeFromProject() {
      if (Object.keys(this.getSubscriptionsByObjectId).length > 0) {
        const subscription = this.getSubscriptionsByObjectId[this.project.id];
        if (subscription) {
          const { subscriptionId } = subscription;
          this.deleteSubscription(subscriptionId);
        }
      }
    },
    increaseNumberOfReplies() {
      this.numberOfReplies += 1;
    },
    pageNotFound() {
      const { pageNotFound } = usePageState();

      pageNotFound.value = true;
      this.$router.push('/projects');
    }
  },
});
