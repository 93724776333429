
import {
  computed,
  defineComponent, toRefs
} from 'vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import moment from 'moment';
import { AvailabilityDatePickerConfigInterface, AvailabilityInterface } from '@/models/Availability';
import AvailabilityService from '@/services/AvailabilityService';
import { mdiClose, mdiChevronDown } from '@mdi/js';
import { or } from '@vueuse/math';
import { mapGetters } from 'vuex';
import useAvailabilityState from '@/state/availabilityState';

export default defineComponent({
  props: {
    forceDot: {
      type: Boolean
    }
  },
  setup(props) {
    const { forceDot } = toRefs(props);
    const { isAvailabilityADot, isSmartphoneWidth } = useResponsiveness();
    const { isAvailabilityPanelOpen } = useAvailabilityState();

    const isDot = or(forceDot, isAvailabilityADot);

    const openPanelIndex = computed({
      get() {
        return isAvailabilityPanelOpen.value ? 0 : undefined;
      },
      set(index?: number) {
        isAvailabilityPanelOpen.value = index !== undefined;
      }
    });

    return {
      isDot,
      isSmartphoneWidth,
      isAvailabilityPanelOpen,
      openPanelIndex
    };
  },
  data() {
    return {
      available: true,
      availableNow: true,
      availableFulltime: true,
      availableFrom: '',
      availableTo: '',
      availableHoursPerWeek: 1,
      minAvailableHoursPerWeek: 1,
      maxAvailableHoursPerWeek: 60,
      menuAvailableFrom: false,
      menuAvailableTo: false,
      mdiClose,
      mdiChevronDown,
    };
  },

  computed: {
    ...mapGetters('page', ['isMobileLayout']),

    datepickerSettingsFrom(): AvailabilityDatePickerConfigInterface {
      return {
        locale: 'de-de',
        'first-day-of-week': '1',
        min: this.minDatepickerDate,
        flat: true,
        'locale-first-day-of-year': 4,
      };
    },

    regexDate(): RegExp {
      return new RegExp(/^[\d]{4}-[\d]{2}-[\d]{2}$/);
    },

    datepickerSettingsTo(): AvailabilityDatePickerConfigInterface {
      return {
        locale: 'de-de',
        'first-day-of-week': '1',
        min: this.minDatepickerToDate,
        flat: true,
        'locale-first-day-of-year': 4,
      };
    },

    availabilityLabels(): any {
      return this.$t('availability');
    },

    availableFromFormatted(): string {
      if (this.regexDate.test(this.availableFrom)) {
        return moment(this.availableFrom).format(this.dateFormat);
      }
      return '-';
    },

    availableToFormatted(): string {
      if (this.regexDate.test(this.availableTo)) {
        return moment(this.availableTo).format(this.dateFormat);
      }
      return '-';
    },

    dateFormat(): string {
      return this.$t('availability.momentDateFormat').toString();
    },

    availabilityMode(): number {
      if (!this.available) {
        return 0;
      } if (!this.availableNow || !this.availableFulltime) {
        return 2;
      }
      return 1;
    },

    availabilityText(): string {
      if (this.availabilityMode === 0) {
        return this.availabilityLabels.unavailable;
      } if (!this.availableNow && !this.availableFulltime) {
        return `<span>${this.availabilityLabels.from}</span> ${this.availableFromFormatted}
        • ${this.availableHoursPerWeek} ${this.availabilityLabels.hoursPerWeekShort}`;
      } if (!this.availableNow && this.availableFulltime) {
        return `<span>${this.availabilityLabels.from}</span>
 ${this.availableFromFormatted} • ${this.availabilityLabels.fullTime}`;
      } if (this.availableNow && !this.availableFulltime) {
        return `${this.availabilityLabels.available} • ${this.availableHoursPerWeek} ${this.availabilityLabels.hoursPerWeekShort}`;
      }
      return `${this.availabilityLabels.available} • ${this.availabilityLabels.fullTime}`;
    },

    availabilityColor(): string {
      if (this.availabilityMode === 1) {
        return 'green';
      } if (this.availabilityMode === 2) {
        return 'yellow';
      }
      return 'red';
    },

    availabilityColorClass(): string {
      return `${this.availabilityColor}--text availability-indicator bg-${this.availabilityColor}`;
    },

    rowClassAvailable(): string {
      return `switch-row ${this.available ? 'switch--available' : 'switch--unavailable'}`;
    },

    rowClassAvailableNow(): string {
      return `switch-row ${this.availableNow ? 'switch--now' : 'switch--later'}`;
    },

    rowClassAvailableFulltime(): string {
      return `switch-row ${this.availableFulltime ? 'switch--fulltime' : 'switch--parttime'}`;
    },

    minDatepickerDate(): string {
      return moment().format('YYYY-MM-DD');
    },

    minDatepickerToDate(): string {
      if (this.availableFromFormatted === '-') {
        return this.minDatepickerDate;
      }
      return this.availableFrom;
    },

  },

  watch: {
    available(value: boolean): void {
      if (!value) {
        this.availableNow = true;
        this.availableFulltime = true;
      }
    },

    availableNow(value: boolean): void {
      if (!value && this.availableFromFormatted === '-') {
        this.availableFrom = moment().format('YYYY-MM-DD');
        this.$nextTick(() => {
          this.saveAvailability();
        });
      }
    },

    availableFrom(value: string): void {
      if (moment(value).unix() > moment(this.availableTo).unix()) {
        this.availableTo = moment(value).add(1, 'day').format('YYYY-MM-DD');
        this.$nextTick(() => {
          this.saveAvailability();
        });
      }
    },

    availableHoursPerWeek(value: string): void {
      if (value !== '') {
        const intvalue = parseInt(value);
        if (intvalue > this.maxAvailableHoursPerWeek) {
          this.$nextTick(() => {
            this.availableHoursPerWeek = this.maxAvailableHoursPerWeek;
            if (this.$refs.tfAvailableHoursPerWeek) (this.$refs.tfAvailableHoursPerWeek as any).$el.querySelector('.v-input__slot').classList.add('danger');
            setTimeout(() => {
              (this.$refs.tfAvailableHoursPerWeek as any).$el.querySelector('.v-input__slot').classList.remove('danger');
            }, 500);
          });
        } else if (intvalue < this.minAvailableHoursPerWeek) {
          this.$nextTick(() => {
            this.availableHoursPerWeek = this.minAvailableHoursPerWeek;
            (this.$refs.tfAvailableHoursPerWeek as any).$el.querySelector('.v-input__slot').classList.add('danger');
            setTimeout(() => {
              (this.$refs.tfAvailableHoursPerWeek as any).$el.querySelector('.v-input__slot').classList.remove('danger');
            }, 500);
          });
        }
      }
    },
  },

  mounted() {
    this.restoreAvailability();
    this.fetchAvailability();
    document.querySelectorAll('body').forEach((el) => {
      el.addEventListener('click', (ev: Event) => {
        const { availabilityPanel = null } = this.$refs as { availabilityPanel: any };
        const datepickers = document.querySelectorAll('.bc-datepicker--availability');
        let clickInDatePicker = false;
        if (datepickers.length > 0) {
          datepickers.forEach((datePicker) => {
            if (ev.target instanceof Node && (ev.target === datePicker || datePicker?.contains(ev.target))) {
              clickInDatePicker = true;
            }
          });
        }
        if (
          ev?.target instanceof Node
          && availabilityPanel?.$el
          && ev.target !== availabilityPanel.$el
          && !availabilityPanel.$el.contains(ev.target)
          && !clickInDatePicker
          && availabilityPanel.isActive
          && !this.menuAvailableFrom
          && !this.menuAvailableTo
        ) {
          availabilityPanel.toggle();
        }
      });
    });
  },

  methods: {
    restoreAvailability(): void {
      if (window.localStorage.getItem('availability') !== null) {
        const availability = AvailabilityService.restoreAvailability();
        if (availability !== false) {
          this.setAvailability(availability);
        }
      }
    },

    fetchAvailability(): void {
      AvailabilityService.fetchAvailability().then((availability) => {
        this.setAvailability(availability);
      });
    },

    setMenuAvailableFrom(value: boolean): void {
      this.menuAvailableFrom = value;
    },

    setMenuAvailableTo(value: boolean): void {
      this.menuAvailableTo = value;
    },

    saveAvailability(): void {
      this.menuAvailableFrom = false;
      this.menuAvailableTo = false;
      AvailabilityService.saveAvailability(
        this.available,
        this.availableNow,
        this.availableFulltime,
        this.availableFrom,
        this.availableTo,
        this.availableHoursPerWeek,
      ).then((availability) => {
        if (availability !== false) {
          this.setAvailability(availability);
        }
      });
    },

    setAvailability(availability: AvailabilityInterface): void {
      const { availability: availabilityState } = useAvailabilityState();
      this.available = availability?.available ? availability.available : false;
      if (this.available && availability.available) {
        this.availableNow = availability.availableNow;
        this.availableFulltime = availability.availableFulltime;
        this.availableFrom = availability.availableFrom || '';
        this.availableTo = availability.availableTo || '';
        this.availableHoursPerWeek = availability.availableHoursPerWeek || 1;
      }
      availabilityState.value = availability;
    },

    changeExpansionPanelState(): void {
      let sendSaveAvailability = false;
      const panelActive = !(this.$refs?.availabilityPanel as any)?.isActive; // inverted logic
      if (!panelActive) {
        if (!this.availableNow && this.availableFromFormatted === '-') {
          this.availableFrom = moment().format('YYYY-MM-DD');
          sendSaveAvailability = true;
        }
        if (!this.availableFulltime && this.availableHoursPerWeek.toString() === '') {
          this.availableFulltime = true;
          sendSaveAvailability = true;
        }
        if (sendSaveAvailability) {
          this.$nextTick(() => {
            this.saveAvailability();
          });
        }
      }
    },

    setAvailable(value: boolean): void {
      if (this.available !== value) {
        this.available = value;
        this.$nextTick(() => {
          this.saveAvailability();
        });
      }
    },

    setAvailableNow(value: boolean): void {
      if (this.availableNow !== value) {
        this.availableNow = value;
        if (value) {
          this.$nextTick(() => {
            this.saveAvailability();
          });
        }
      }
    },

    setAvailableTo(value: string): void {
      this.availableTo = value;
      this.$nextTick(() => {
        this.saveAvailability();
      });
    },

    setAvailableFulltime(value: boolean): void {
      if (this.availableFulltime !== value) {
        this.availableFulltime = value;
        this.$nextTick(() => {
          this.saveAvailability();
        });
      }
    },

    selectContentAvailableHoursPerWeek(): void {
      const elm = (this.$refs?.tfAvailableHoursPerWeek as any)?.$el;
      elm.querySelector('input').select();
    },

  },
});
