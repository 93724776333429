import { render, staticRenderFns } from "./EditProjectForumSection.vue?vue&type=template&id=19181671&scoped=true"
import script from "./EditProjectForumSection.vue?vue&type=script&lang=ts"
export * from "./EditProjectForumSection.vue?vue&type=script&lang=ts"
import style0 from "./EditProjectForumSection.vue?vue&type=style&index=0&id=19181671&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19181671",
  null
  
)

export default component.exports