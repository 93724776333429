
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import SidebarContent from '@/components/SidebarContent.vue';
import useInfoBoxState from '@/state/infoBox';
import {
  computed, defineComponent, toRef, watch
} from 'vue';
import useUser from '@/mixins/useUser';
import { sample } from 'lodash';

export default defineComponent({
  name: 'SidebarContainer',
  components: {
    SidebarContent,
    BCXMarkdown,
  },
  setup(_, { slots, emit }) {
    const {
      infoBoxState, resetInfoBox, hasCustomSidebar,
    } = useInfoBoxState();
    const { tUserFallback: t } = useUser('self');

    const hasSlotContent = computed(() => !!slots?.default);

    const isOpened = toRef(infoBoxState, 'isOpened');

    const showCloseIcon = computed(() => hasSlotContent.value && hasCustomSidebar.value);

    const anythingInside = computed(() => infoBoxState.isActive || hasCustomSidebar.value);

    const text = computed(() => {
      if (!infoBoxState.text) return '';
      const textOrTexts = t(infoBoxState.text);
      return textOrTexts ? (Array.isArray(textOrTexts) ? sample(textOrTexts) : textOrTexts) as string : '';
    });

    watch(anythingInside, (anything) => {
      emit('anything-inside', anything);
    }, {
      immediate: true
    });

    return {
      showCloseIcon,
      isOpened,
      hasSlotContent,
      resetInfoBox,
      infoBoxState,
      text,
      t
    };
  }
});
