
import {
  computed, defineComponent, ref, Ref, watch
} from 'vue';
import BCXButtonDropdown from '@/components/molecules/forms/BCXButtonDropdown.vue';
import BCXButtonDropdownResetButton from '@/components/molecules/forms/BCXButtonDropdownResetButton.vue';
import BCXMultiselectSection from '@/components/molecules/forms/BCXMultiselectSection.vue';
import useI18n from '@/mixins/useI18n';
import useUserSearch from '@/views/user-search/store/useUserSearch';
import {
  SearchAvailability, SearchAvailabilityWithin, SearchCriteriumType
} from '@/views/user-search/models/UserSearch';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import hasArrayEqualContents from '@/utils/hasArrayEqualContents';

export default defineComponent({
  name: 'UserSearchAvailabilitySelect',
  components: {
    BCXCheckbox,
    BCXSearchSelect,
    BCXMultiselectSection,
    BCXButtonDropdownResetButton,
    BCXButtonDropdown
  },
  setup() {
    const { t } = useI18n();
    const showDropdown: Ref<boolean> = ref(false);
    let previousWithinValue: SearchAvailabilityWithin = 'W2';

    const {
      searchCriteriaItems, selectItems, filters, availabilityWithinList, initialFilters
    } = useUserSearch();

    const isAvailabilityWithinSelected = computed({
      get() {
        return filters.availabilityWithin !== 'ALL';
      },
      set(is:boolean) {
        if (!is) {
          filters.availabilityWithin = 'ALL';
        } else {
          filters.availabilityWithin = previousWithinValue;
        }
      }
    });

    watch(() => filters.availabilityWithin, (avWithin) => {
      if (avWithin !== 'ALL') previousWithinValue = avWithin;
    });

    const selectionCount = computed(() => filters.availabilities.length + (isAvailabilityWithinSelected.value ? 1 : 0));

    const setShowDropdown = (value: boolean) => {
      showDropdown.value = value;
    };

    // const hasCustomSelection = computed(() => selectionCount.value < searchCriteriaItems.availabilities.length);
    const hasCustomSelection = computed(() => !hasArrayEqualContents(filters.availabilities, initialFilters.availabilities)
      || filters.availabilityWithin !== initialFilters.availabilityWithin);

    const resetFilter = () => {
      filters.availabilities = searchCriteriaItems[SearchCriteriumType.Availability];
      filters.availabilityWithin = initialFilters.availabilityWithin;
    };

    const title = computed(() => t('user-search.filters.names.availabilities'));

    const titleWithCount = computed(() => (hasCustomSelection.value ? `${title.value} (${selectionCount.value})` : title.value));

    const isStartGroupDisabled = computed(() => !filters.availabilities.length || (
      filters.availabilities.length === 1 && filters.availabilities?.at(0) === SearchAvailability.Unavailable));

    const hasError = computed(() => !filters.availabilities.length);

    return {
      setShowDropdown,
      showDropdown,
      resetFilter,
      hasCustomSelection,
      titleWithCount,
      searchCriteriaItems,
      selectItems,
      filters,
      t,
      SearchCriteriumType,
      isStartGroupDisabled,
      hasError,
      availabilityWithinList,
      isAvailabilityWithinSelected
    };
  }
});
