
import BytecookieLogoOnly from '@/components/svg/logo/logo-circles-only.svg?inline';
import useMobileNavigationVisibility from '@/mixins/useMobileNavigationVisibility';
import useResponsiveness from '@/mixins/useResponsiveness';
import useRouterIgnoreDuplicate from '@/mixins/useRouterIngoreDuplicate';
import { computed, defineComponent, PropType } from 'vue';
import { traverseNavigationTreeItems } from '@/navigation/traverseNavigationTreeItems';
import useI18n from '@/mixins/useI18n';
import { useRoute } from 'vue2-helpers/vue-router';
import { RouterEntry } from '@/models/Router';
import useNavigationTree from '@/navigation/useNavigationTree';

export default defineComponent({
  name: 'BCXBreadcrumbs',
  components: {
    BytecookieLogoOnly
  },
  props: {
    items: {
      type: Array as () => RouterEntry[],
      default: () => []
    },
    auto: {
      type: Boolean
    },
    flat: {
      type: Boolean,
      required: false,
      default: () => false
    },
    rootPage: {
      type: String as PropType<'bytecookie' | 'projects'>,
      default: 'bytecookie'
    }
  },
  setup(props) {
    const { isMobileWidth } = useResponsiveness();
    const { pushRoute } = useRouterIgnoreDuplicate();
    const goHome = () => pushRoute('/home');
    const { t } = useI18n();
    const route = useRoute();
    const { navigation } = useNavigationTree();

    const breadcrumbItems = computed(() => {
      if (props.auto) {
        let items: any[];
        let path = route?.fullPath ?? '';
        let maxLoops = 10;

        do {
          items = [];
          traverseNavigationTreeItems(navigation, (treeItem) => {
            if (treeItem._to === path) {
              if (treeItem._level) {
                items.unshift({
                  text: t(`navigationbcx.${treeItem?._parent?._key}.${treeItem._key}`)
                });
              }
              switch (props.rootPage) {
                case 'projects':
                  items.unshift({
                    text: t('project-forum.labels.projects'),
                    to: { path: '/projects' }
                  });
                  break;
                default:
                  items
                    .unshift({
                      text: 'ByteCookie',
                      to: { path: '/home' }
                    });
              }
            }
          });
          // creep up path until we hit something!
          path = path.split('/').slice(0, -1).join('/');
        } while ((!items.length || path.length > 1) && --maxLoops);
        return items;
      }
      return props.items;
    });

    const { isHidden: isBreadcrumbsHidden } = useMobileNavigationVisibility();
    return {
      goHome,
      isBreadcrumbsHidden,
      isMobileWidth,
      breadcrumbItems
    };
  }
});
