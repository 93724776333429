import { computed, Ref } from 'vue';
import { BcxTableFilter } from '@/models/BcxTable';

export type BcxTablePinnedItemPosition = {
  position: number;
  idField: string;
  idValue: string | number;
}

export type BcxTableSearchFilterSortOptions = {
  searchCallback?: (searchTerm: string, obj:unknown) => boolean;
  filter?: BcxTableFilter;
  sortValueCallback?: (fieldName: string, obj:unknown) => string | number;

  states?: string[];
  sortField?: string;
  isAscending?: boolean;
  searchTerm?: string;

  pinnedItems?: BcxTablePinnedItemPosition[];
}

const useBcxTableSearchFilterSort = (options: BcxTableSearchFilterSortOptions, rows: Ref<Array<unknown>>) => {
  const searchedRows = computed(() => {
    if (options.searchCallback && options.searchTerm) {
      return rows.value.filter((row) => options?.searchCallback?.(options?.searchTerm ?? '', row));
    }
    return rows.value;
  });

  const searchedFilteredRows = computed(() => {
    if (options.filter && options.states && options.filter.stateCallback) {
      return searchedRows.value.filter((row) => options?.filter?.stateCallback(options?.states ?? [], row));
    }
    return searchedRows.value;
  });

  const searchedFilteredSortedRows = computed(() => {
    if (options.sortValueCallback && options.sortField) {
      const { isAscending } = options;
      return [...searchedFilteredRows.value.sort(
        (rowA, rowB) => {
          const aValue = options?.sortValueCallback?.(options?.sortField ?? '', rowA) ?? '';
          const bValue = options?.sortValueCallback?.(options?.sortField ?? '', rowB) ?? '';
          const comparison = (typeof aValue === 'number' && typeof bValue === 'number') ? (aValue - bValue) : aValue.toString().localeCompare(bValue.toString());

          return isAscending
            ? comparison : -comparison;
        }
      )];
    }
    return searchedFilteredRows.value;
  });

  const searchedFilteredSortedRowsWithPinnedItems = computed(() => {
    if (options.pinnedItems?.length) {
      const newRows = [...searchedFilteredSortedRows.value] as Record<string, string|number>[];

      // First, position pinned positions by position.
      // So inserting them won't shift items that were inserted before.
      const sortedPinnedItems = [...options.pinnedItems]
        .sort((a, b) => (a.position
          - b.position));

      // remove all rows that will get pinned. This way, double-moving of rows
      // with shared positions will be prevented.
      const unPinnedRows = newRows.filter((row) => !sortedPinnedItems.find((item) => item.idValue === row[item.idField]));

      // insert all the pinned rows one after another:
      sortedPinnedItems.forEach((item) => {
        const row = newRows.find((row) => row[item.idField] === item.idValue);
        if (row) {
          unPinnedRows.splice(item.position, 0, row);
        }
      });

      return [...unPinnedRows];
    }
    return searchedFilteredSortedRows.value;
  });

  return {
    finalRows: searchedFilteredSortedRowsWithPinnedItems
  };
};

export default useBcxTableSearchFilterSort;
