
import Vue, { computed, PropType, toRefs } from 'vue';
import IconEdit from '@/components/svg/edit-circle.svg?inline';
import IconChevronDown from '@/components/svg/chevron-down.svg?inline';
import EditProjectForumSection from '@/views/project-forum/components/EditProjectForumSection.vue';
import useIsLeavingDirty from '@/utils/isLeavingDirty';
import useUser from '@/mixins/useUser';
import useI18n from '@/mixins/useI18n';

export type ProjectForumSectionType = 'description' | 'client' | 'project-guests' | 'project-title' | 'roles' | 'skills' | 'conditions';

export default Vue.extend({
  name: 'ProjectForumSection',
  components: {
    IconEdit,
    IconChevronDown,
    EditProjectForumSection,
  },
  props: {
    type: {
      type: String as PropType<ProjectForumSectionType>,
      default: undefined,
      // required: true,
    },
    editValue: {
      type: [String, Number, Object, Array],
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isExpandable: {
      type: Boolean,
      default: false,
    },
    showTestFlag: {
      type: Boolean,
      default: false,
    },
    explainText: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { te } = useI18n();
    const { explainText, helpText } = toRefs(props);
    const {
      isDirty, isLeavingDirty, isLeavingMessage, onConfirm
    } = useIsLeavingDirty();

    const { tUserFallback: t } = useUser('self');

    const formExplainText = computed(() => {
      if (explainText.value.length > 0) {
        return explainText.value;
      }
      return `${t(`project-forum_USERTYPE.form-section.${props.type.toLowerCase()}.explain`) || ''}`;
    });

    const formHelpText = computed(() => {
      if (helpText.value.length > 0) {
        return helpText.value;
      } if (te(`project-forum_USERTYPE.form-section.${props.type.toLowerCase()}.help`)) {
        return te(`project-forum_USERTYPE.form-section.${props.type.toLowerCase()}.help`);
      }
      return null;
    });

    return {
      isLeavingDirty,
      isLeavingMessage,
      isDirty,
      onConfirm,
      formExplainText,
      formHelpText,
    };
  },
  data() {
    return {
      edit: false,
      expanded: true,
    };
  },
  computed: {
    showEditButton(): boolean {
      return this.isEditable && !this.edit;
    },
    contentCollapsed(): boolean {
      return !this.expanded && !this.edit;
    },
    showExpansionButton(): boolean {
      return this.isExpandable && !this.expanded && !this.edit;
    },
  },
  watch: {
    isExpandable(newValue) {
      this.expanded = !newValue;
    },
    edit(newValue) {
      this.isDirty = newValue;
    }
  },
  mounted() {
    this.expanded = !this.isExpandable;
  },
  methods: {
    toggleEditSection() {
      this.edit = !this.edit;
    },
    expandContent() {
      if (this.isExpandable) {
        this.expanded = !this.expanded;
      }
    },
    editSave(data: any) {
      this.$emit('edit-save', data);
      this.toggleEditSection();
    },
  }
});
