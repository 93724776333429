import { RouteConfig } from 'vue-router';
import useI18n from '@/mixins/useI18n';
import LandingpageSwitch from '@/views/landingpage/LandingpageSwitch.vue';
import LandingpageFreelancer from '@/views/landingpage/LandingpageFreelancer.vue';
import LandingpageCustomer from '@/views/landingpage/LandingpageCustomer.vue';

const { t } = useI18n();

const routes: RouteConfig[] = [
  {
    name: 'Landingpage',
    path: '/landingpage',
    component: LandingpageSwitch,
    meta: {
      layout: 'landingpage',
      title: t('landingpage.title_switch'),
      description: t('landingpage.meta_switch'),
    }
  }, {
    name: 'landingpage-freelancer',
    path: '/freelancer',
    component: LandingpageFreelancer,
    meta: {
      layout: 'landingpage',
      title: t('landingpage.title_freelancer'),
      description: t('landingpage.meta_freelancer'),
    }
  }, {
    name: 'landingpage-customer',
    path: '/customer',
    component: LandingpageCustomer,
    meta: {
      layout: 'landingpage',
      title: t('landingpage.title_customer'),
      description: t('landingpage.meta_customer'),
    }
  }
];

export default routes;
