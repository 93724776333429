import authentication from '@/views/authentication/routes';
import blog from '@/views/blog/routes';
import forum from '@/views/forum/routes';
import home from '@/views/home/routes';
import indexRoutes from '@/views/index/routes';
import invites from '@/views/invites/routes';
import landingpage from '@/views/landingpage/routes';
import myProjects from '@/views/my-projects/routes';
import passwordReset from '@/views/password-reset/routes';
import profile from '@/views/profile/routes';
import projectForum from '@/views/project-forum/routes';
import recommendations from '@/views/recommendations/routes';
import registration from '@/views/registration/routes';
import requester from '@/views/requester/routes';
import settings from '@/views/settings/routes';
import subscriptions from '@/views/subscriptions/routes';
import userSearch from '@/views/user-search/routes';
import virtualPub from '@/views/virtual-pub/routes';
import testingArea from '@/views/testingarea/routes';
import guestsEmployees from '@/views/project-guests/routes';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...authentication,
    ...blog,
    ...forum,
    ...home,
    ...indexRoutes,
    ...invites,
    ...landingpage,
    ...myProjects,
    ...passwordReset,
    ...profile,
    ...projectForum,
    ...recommendations,
    ...registration,
    ...virtualPub,
    ...subscriptions,
    ...userSearch,
    ...settings,
    ...requester,
    ...testingArea,
    ...guestsEmployees
  ],
});

export default router;
