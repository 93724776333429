
import { ForumMaxChars } from '@/views/forum/models/Forum';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ForumListGroup from '@/views/forum/components/ForumListGroup.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import { RawLocation } from 'vue-router';
import BCXSkeleton from '@/components/molecules/BCXSkeleton.vue';

export default Vue.extend({
  components: {
    ForumListGroup,
    BCXBreadcrumbs,
    BCXSkeleton,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('forum', ['getForumList', 'getForumListWithTitle']),
    ...mapGetters('page', ['isMobileLayout']),
    provide: () => ({
      maxCreateMessageTextLength: ForumMaxChars.TALK_CONTENT
    }),
    breadcrumbItems(): Array<any> {
      const breadcrumbItems: Array<any> = [
        {
          text: this.$t('breadcrumb.community') as string,
          disabled: true,
        },
        {
          text: this.$tc('forum.labels.threads', 2) as string,
          exact: true,
          to: { name: 'forums' },
        },
      ];
      if (this.isMobileLayout) { // display logo as first item
        breadcrumbItems[0].text = 'ByteCookie';
      }
      return breadcrumbItems;
    },
    linkToFeatureSuggestion(): RawLocation {
      return { name: 'threads', params: { forumId: '74502bb7-1b2e-4c42-9454-e48d0dee6220', groupId: '94f5c84b-9cee-43e5-907d-95d091a00f52' } };
    },
  },
});
