
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import useProfileState from '@/state/profile/profileState';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import taggingService from '@/services/TaggingService';
import { asyncComputed } from '@vueuse/core';
import { and, not } from '@vueuse/math';
import {
  computed, defineComponent, onMounted, ref, watch
} from 'vue';
import { ProfileMaxChars, SkillListItem } from '@/models/Profile';
import { cloneDeep } from 'lodash';
import { updateSkills } from '@/services/Profile/Api';
import { convertToItemList } from '@/utils/selectItemTools';
import useListTools from '@/mixins/useListTools';
import EditIcon from '@/components/molecules/EditIcon.vue';
import AddIcon from '@/views/profile/components/AddIcon.vue';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProfileSkillsEditor',
  components: {
    AddIcon,
    EditIcon,
    BCXSearchSelect,
    BCXInfoSection,
    ProfileEditActionButtons,
  },
  setup() {
    const { profile } = useProfileState();
    const skillRoleIdToAdd = ref('');
    const skillIdToAdd = ref('');
    const skillToAdd = ref('');
    const skillList = ref<SkillListItem[] | null>(null);
    const skillSearch = ref('');
    const isDirty = ref(false);
    const { tUserFallback: t } = useUser('self');

    const { addItem, deleteItem: removeSkill, moveUp: moveUpListItem } = useListTools<SkillListItem>(skillList);

    onMounted(() => {
      skillList.value = profile.value?.skills?.skillList
        ? cloneDeep(profile.value?.skills?.skillList) as SkillListItem[]
        : [];
    });

    const skillOptions = asyncComputed(async () => (
      skillSearch.value?.length
        ? convertToItemList((await taggingService.getSkills(skillSearch.value))
          .filter(({ name }) => name),
        'id', 'name')
        : []
    ), []);

    const skillRoleOptions = asyncComputed(async () => {
      const roles = await taggingService.getAllRoles();
      const sortedRoles = roles
        .filter(({ tagName }) => tagName !== undefined && tagName !== null)
        .sort((a, b) => (a.tagName ?? '').localeCompare(b.tagName ?? '')); // Verwenden Sie ?? um sicherzustellen, dass kein undefined-Wert verwendet wird
      return convertToItemList(sortedRoles, 'tagId', 'tagName');
    }, []);

    const isSkillListFull = computed(() => skillList.value && skillList.value.length >= 5); // why the fuck does conditional chaining NOT work here?!
    const canAdd = and(skillToAdd, skillRoleIdToAdd);
    const isIncompleteEntry = and(skillToAdd, not(skillRoleIdToAdd), not(isSkillListFull));

    const addSkill = () => {
      if (canAdd.value) {
        addItem({
          skillRoleTagId: skillRoleIdToAdd.value,
          skillRoleTagName: skillRoleOptions.value.find(({ value }) => value === skillRoleIdToAdd.value)?.text,
          skillTagId: skillIdToAdd.value,
          skillTagName: skillOptions.value.find(({ value }) => value === skillIdToAdd.value)?.text ?? skillToAdd.value,
        });
        skillRoleIdToAdd.value = '';
        skillToAdd.value = '';
        skillIdToAdd.value = '';
      }
    };

    const isInvalid = and(isIncompleteEntry, isDirty);

    watch(skillRoleIdToAdd, (v) => {
      if (v) isDirty.value = false;
    });

    const save = async () => {
      if (isIncompleteEntry.value) {
        isDirty.value = true;
        return 'abort';
      }
      if (canAdd.value) {
        addSkill();
      }
      if (skillList.value) {
        await updateSkills({
          userSkills: { userSkillList: skillList.value }
        });
      }
      return false;
    };

    /**
     * @param {number} [idx] - The index of the item to move (optional)
     */
    const moveUp = (idx:number) => {
      if (skillList.value) {
        const userSkillIds = skillList.value.map((skill) => skill.userSkillId); // save userSkillIds
        moveUpListItem(idx);
        skillList.value = skillList.value.map((skill, idx) => ({ // re-assign userSkillIds
          ...skill,
          userSkillId: userSkillIds[idx]
        }));
      }
    };

    return {
      t,
      isSkillListFull,
      isInvalid,
      profile,
      skillRoleOptions,
      skillOptions,
      skillRoleIdToAdd,
      skillIdToAdd,
      canAdd,
      skillList,
      skillSearch,
      save,
      moveUp,
      addSkill,
      removeSkill,
      skillToAdd,
      maxLength: {
        skill: ProfileMaxChars.SKILL
      }
    };
  },
});
