import { render, staticRenderFns } from "./LPAccordionItem.vue?vue&type=template&id=6dec08ac&scoped=true"
import script from "./LPAccordionItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./LPAccordionItem.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./LPAccordionItem.vue?vue&type=style&index=0&id=6dec08ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dec08ac",
  null
  
)

export default component.exports