import useTokenState from '@/state/tokenState';
import axios, {
  AxiosError,
  AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig
} from 'axios';
import { v4 as uuidv4 } from 'uuid';
import useI18n from '@/mixins/useI18n';
import { Ref, computed, ref } from 'vue';
import useAppVersionState from '@/state/appVersionState';
import { logDebug } from '@/utils/logger';
import usePageState from '@/state/pageState';

interface FailedQueueItem {
  resolve: (token: string) => void;
  reject: (error: any) => void;
  url?: string;
  config?: InternalAxiosRequestConfig;
  timestamp?: number;
}

export const axiosBaseConfig: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL || '',
  headers: {
    'Content-Type': 'application/json'
  }
};

interface RefreshedRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

const {
  tryTokenRenewal, isLoggedIn, accessTokenBearer, accessToken, isOnline, isAccessTokenExpiringSoonRequest, isRefreshing
} = useTokenState();
const {
  currentAppVersion, latestAppVersion, isAppUpdateAvaliable, isNotificationIgnored, ignoreNotificationUntil
} = useAppVersionState();

export const failedQueue: Ref<FailedQueueItem[]> = ref([]);

const processQueue = (error: any = null, token: string | null = null): void => {
  logDebug('Processing queue', failedQueue.value);
  failedQueue.value.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve(token);
    }
  });

  failedQueue.value = [];
};

const { locale } = useI18n();

const acceptLanguageHeader = computed(() => {
  if (locale.value === 'de') {
    return 'de-DE;q=1.0';
  }
  return 'en-GB;q=1.0';
});

const _axios = axios.create(axiosBaseConfig);

export const requestInterceptor = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const { t } = useI18n();
  const token: string | null = localStorage.getItem('token');

  const isTokenRefresh = (config?.url ?? '').includes('/users/user/token');

  if (token && isAccessTokenExpiringSoonRequest.value && !isTokenRefresh && isLoggedIn.value) {
    if (!isRefreshing.value) {
      logDebug('Refreshing token before next request');
      await tryTokenRenewal(() => {
        config.headers['Authorization'] = accessTokenBearer.value;
        processQueue(null, accessToken.value);
      });
    } else {
      return new Promise((resolve, reject) => {
        if (!isTokenRefresh) {
          logDebug(`Adding request ${config.url} to queue`);
          failedQueue.value.push({
            resolve: () => {
              config.headers['Authorization'] = accessTokenBearer.value;
              resolve(config);
            },
            reject,
            url: config.url,
            config,
            timestamp: new Date().getTime()
          });
        } else {
          reject(config);
        }
      });
    }
  }

  if (accessTokenBearer.value && !isTokenRefresh) {
    config.headers.Authorization = accessTokenBearer.value;
  } else {
    config.timeout = 10000;
    delete config.headers.Authorization;
  }

  if (['post', 'put', 'delete', 'patch'].includes(config.method as string)) {
    config.headers['X-Request-ID'] = uuidv4();
  }
  config.headers['Accept-Language'] = acceptLanguageHeader.value;
  // store.commit('errorMessage', { errorMessage: null });
  // store.commit('validationErrors', { validationErrors: {} });

  return new Promise((resolve, reject) => {
    if (isOnline.value) {
      resolve(config);
    } else {
      reject(new AxiosError('Device offline!', t('common.offlineError').toString(), config));
    }
  });
};

_axios.interceptors.request.use(requestInterceptor);

const ignoreErrorCodes = [
  'BC-SEC-011',
];

// Add a response interceptor
export const responseSuccessInterceptor = async (response: AxiosResponse): Promise<AxiosResponse> => {
  const versionHeader: string | undefined = response.headers['x-ui-version'];
  const beVersionHeader: string | undefined = response.headers['x-be-version'];
  if (versionHeader) {
    if (currentAppVersion.value === '') {
      currentAppVersion.value = versionHeader;
    }
    latestAppVersion.value = versionHeader;
    logDebug('Current App Version', currentAppVersion.value);
    logDebug('Latest App Version Frontend:', latestAppVersion.value, 'Backend:', (beVersionHeader ?? 'empty'));
    if (isAppUpdateAvaliable.value && isNotificationIgnored.value) {
      logDebug('App update available, but notification is ignored until:', new Date(ignoreNotificationUntil.value).toLocaleString());
    }
  }
  return response;
};

export const responseErrorInterceptor = async (error: any) => {
  const originalRequest: RefreshedRequestConfig = (error.config as RefreshedRequestConfig);
  const errorCode = error.response?.data?.error?.code;
  if (!errorCode || !ignoreErrorCodes.includes(errorCode)) {
    if (isLoggedIn.value && error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing.value) {
        return new Promise((resolve, reject) => {
          failedQueue.value.push({
            resolve: () => {
              if (originalRequest.headers) {
                originalRequest.headers['Authorization'] = accessTokenBearer.value;
              }
              resolve(axios(originalRequest));
            },
            reject,
            url: error.config.url,
            config: error.config,
            timestamp: new Date().getTime()
          });
        });
      }

      originalRequest._retry = true;

      tryTokenRenewal(() => {
        logDebug('Token refreshed after 401, retrying request');
        if (originalRequest.headers) {
          originalRequest.headers['Authorization'] = accessTokenBearer.value;
        }
        processQueue(null, accessToken.value);
      });
    }
    // if (error?.response?.status === 400 && error.response?.data?.validationError) {
    //   store.commit('validationErrors', { validationErrors: error.response.data.validationError });
    // } else {
    //   store.commit('errorMessage', { errorMessage: error.response.data.error.message });
    // }
  }

  return Promise.reject(error);
};

_axios.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export default _axios;
