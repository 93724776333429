import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';
import { AvailabilityInterface } from '@/models/Availability';

const useAvailabilityState = createGlobalState(() => {
  const isAvailabilityPanelOpen = ref(false);
  const availability = ref<AvailabilityInterface | null>(null);

  return { isAvailabilityPanelOpen, availability };
});

export default useAvailabilityState;
