const toggleArrayItem = (array:string[], item:string): string[] => {
  const itemIndex:number = array.indexOf(item);

  if (itemIndex >= 0) array.splice(itemIndex, 1);
  else {
    array.push(item);
  }

  return array;
};

export default toggleArrayItem;
