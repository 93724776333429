
import useFullPageState from '@/state/fullPageState';
import {
  computed, onBeforeUnmount, onMounted, provide, ref, defineComponent
} from 'vue';
import SidebarContainer from '@/components/SidebarContainer.vue';
import Toolbar from '@/components/toolbar/Toolbar.vue';
import BCXNavigation from '@/components/navigation/BCXNavigation.vue';
import NavigationPanel from '@/components/navigation/NavigationPanel.vue';
import BCXFloatingNavigation from '@/components/navigation/BCXFloatingNavigation.vue';
import { createVuexHelpers } from 'vue2-helpers';
import { useRoute } from 'vue2-helpers/vue-router';
import useResponsiveness from '@/mixins/useResponsiveness';
import BCXMessenger from '@/components/messenger/BCXMessenger.vue';
import { templateRef } from '@vueuse/core';
import useMessengerState from '@/state/messenger/messengerState';
import useMobileNavigationVisibility from '@/mixins/useMobileNavigationVisibility';
import QuickInfoLayer from '@/components/molecules/QuickInfoLayer.vue';
import useMessengerRefresher from '@/state/messenger/useMessengerRefresher';
import VotingReportingLayer from '@/components/voting/VotingReportingLayer.vue';
import BCXFooter from '@/components/BCXFooter.vue';
import onSizeChange from '@/mixins/onSizeChange';
import useDontShrink from '@/mixins/useDontShrink';

export default defineComponent({
  name: 'Full',

  components: {
    QuickInfoLayer,
    BCXMessenger,
    BCXFloatingNavigation,
    NavigationPanel,
    BCXNavigation,
    SidebarContainer,
    Toolbar,
    VotingReportingLayer,
    BCXFooter
  },

  setup() {
    const route = useRoute();
    const { useState } = createVuexHelpers();
    const {
      isSidebarVisible,
      isMobileLayout,
      isMini,
    } = useResponsiveness();
    const { isParticipateVisible } = useState('page', ['isParticipateVisible']);
    const { isAnythingInsideSidebarBox, isOpened } = useMessengerState();
    const main = templateRef<HTMLElement>('main');
    const sidebarChat = templateRef<HTMLElement>('sidebarChat');
    const sidebarChatHeight = ref(0);
    const sidebarBox = templateRef<HTMLElement>('sidebarBox');
    const sidebarBoxHeight = ref(0);
    const mainContent = templateRef<HTMLElement>('mainContent');
    const isAnythingInsideSidebar = ref(false);
    const { isOnFullPage } = useFullPageState();

    isOnFullPage.value = true;
    onBeforeUnmount(() => {
      isOnFullPage.value = false;
    });

    const { init, isHidden: isMobileLayerHidden } = useMobileNavigationVisibility();
    init(mainContent);

    useDontShrink(mainContent);

    useMessengerRefresher();

    provide('sidebarChatHeight', sidebarChatHeight);
    provide('sidebarBoxHeight', sidebarBoxHeight);

    // useNotificationUpdater();

    onSizeChange(sidebarChat, ({ height }) => {
      sidebarChatHeight.value = height;
      main.value.style.setProperty('--sidebar-chat-height', `${height}px`);
    });

    onSizeChange(sidebarBox, ({ height }) => {
      sidebarBoxHeight.value = height;
      main.value.style.setProperty('--sidebar-box-height', `${height}px`);
    });

    const appClasses = computed(() => route?.meta?.mainClasses);

    const showMessenger = () => {
      isOpened.value = !isOpened.value;
    };

    const isMobileMessagePopup = computed(() => isOpened.value && isMobileLayout.value);

    const setIsAnythingInsideSidebar = (is: boolean) => { isAnythingInsideSidebar.value = is; isAnythingInsideSidebarBox.value = is; };

    onMounted(() => {
      const query = route?.query;
      if (query?.openChat) {
        showMessenger();
      }
    });

    return {
      isAnythingInsideSidebar,
      isMini,
      isMobileLayerHidden,
      isMobileLayout,
      isMobileMessagePopup,
      isOpened,
      isParticipateVisible,
      isSidebarVisible,
      appClasses,
      setIsAnythingInsideSidebar,
      showMessenger
    };
  },

});
