
import Vue from 'vue';
import ThreadListItem from '@/views/forum/components/ThreadListItem.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXSkeleton from '@/components/molecules/BCXSkeleton.vue';
import ThreadListItemSkeleton from '@/views/forum/components/ThreadListItemSkeleton.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce, truncate } from 'lodash';
import useMobileNavigationVisibility from '@/mixins/useMobileNavigationVisibility';
import { PostSubscription } from '@/views/subscriptions/models/Subscription';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { ForumMaxChars, GROUP_ID_ARTICLES } from '../models/Forum';

export default Vue.extend({
  name: 'ThreadOverview',
  components: {
    ThreadListItem,
    ThreadListItemSkeleton,
    BCXBreadcrumbs,
    BCXSkeleton,
    BCXMarkdown,
  },
  provide: () => ({
    maxCreateMessageTextLength: ForumMaxChars.TALK_CONTENT
  }),
  beforeRouteLeave(_to, _from, next) {
    this.clearThreadList();
    next();
  },
  data() {
    return {
      watchForum: false,
      showTags: true,
      showMobileSort: false,
      hasBeenScrolled: false,
      scrollPostionY: 0,
    };
  },
  computed: {
    ...mapState('forum', ['forum', 'thread', 'threadList', 'threadsSort', 'isFetchingThreads']),
    ...mapGetters('forum', ['getThreadListSorted', 'getThreadSortParam', 'getForumId', 'getGroupId', 'getForumTitle']),
    ...mapGetters('page', ['isMobileLayout']),
    ...mapGetters('subscriptions', ['getSubscriptionsByObjectId']),
    isScrollingUp(): boolean {
      const { isHidden } = useMobileNavigationVisibility();
      return !isHidden.value && this.scrollPostionY > 30;
    },
    sortParam(): string {
      return this.threadsSort.sortParam;
    },
    sortOrder(): string {
      return this.threadsSort.sortOrder;
    },
    sortItems(): Array<any> {
      return [
        { name: this.$t('forum.labels.sort.time'), value: 'TIMESTAMP' },
        { name: this.$t('forum.labels.sort.name'), value: 'NAME' },
        { name: this.$t('forum.labels.sort.title'), value: 'TITLE' },
        { name: this.$t('forum.labels.sort.views'), value: 'VIEWS' },
        { name: this.$t('forum.labels.sort.votes'), value: 'VOTES' }
      ];
    },
    getThreadCountText(): string {
      return `${this.getThreadListSorted.length} ${this.$tc('forum.labels.threads', this.getThreadListSorted.length)}`;
    },
    breadcrumbItems(): Array<any> {
      const breadcrumbItems: Array<any> = [
        {
          text: this.$t('breadcrumb.community') as string
        },
        {
          text: this.$tc('forum.labels.threads', 2) as string,
          to: { name: 'forums' }
        },
        {
          text: this.isMobileLayout ? truncate(this.getForumTitle, { length: 50 }) : this.getForumTitle,
          to: { name: 'threads', params: { forumId: this.getForumId } }
        }
      ];
      if (this.isMobileLayout) {
        // display logo as first item
        breadcrumbItems[0].text = 'ByteCookie';
      }

      return breadcrumbItems;
    },

    showNewThreadButton(): boolean {
      return ![GROUP_ID_ARTICLES].includes(this.getGroupId);
    },

    threadGroupSubscribed(): boolean {
      return Object.keys(this.getSubscriptionsByObjectId).includes(this.getGroupId);
    },
    forumGroupIcon(): string {
      if (this.forum.title && this.forum.title.toLowerCase() !== 'blog-forum') {
        return `forum-${this.forum.title.toLowerCase()}.svg`;
      }
      return 'forum-bytecookie.svg';
    },
    threadDescription(): string {
      const descriptionKey = this.forum.groups[0].title.length
        ? this.forum.groups[0].title
          .toLowerCase()
          .replace(/[^\w]/g, '_')
          .replace(/[_]{2,}/g, '_')
        : 'general';
      return this.$t(`forum.descriptions.threads_overview.${this.forum.title.toLowerCase()}.${descriptionKey}`) as string;
    }
  },
  watch: {
    watchForum(newValue) {
      if (newValue && !this.threadGroupSubscribed) {
        const postSubscriptionData: PostSubscription = {
          businessObjectId: this.getGroupId,
          subscriptionType: 'FORUM_TALKS'
        };
        this.postSubscription(postSubscriptionData).then(() => {
          this.fetchSubscriptions();
        });
      } else if (!newValue && this.threadGroupSubscribed) {
        const { subscriptionId } = this.getSubscriptionsByObjectId[this.getGroupId];
        if (subscriptionId) {
          this.deleteSubscription(subscriptionId);
        }
      }
    },
    threadGroupSubscribed(newValue) {
      this.watchForum = newValue;
    }
  },
  mounted() {
    let lastScroll = 0;
    debounce(() => {
      this.hasBeenScrolled = false;
    }, 1000);
    document.addEventListener('scroll', debounce(() => {
      const currentScroll = window.scrollY;
      const navHeight = 48;
      const { body } = document;
      const html = document.documentElement;

      const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      this.scrollPostionY = currentScroll;

      if (this.isMobileLayout) {
        if (currentScroll < lastScroll && currentScroll > navHeight && currentScroll + navHeight < documentHeight - window.innerHeight) {
          this.hasBeenScrolled = true;
        }
        lastScroll = currentScroll;
      }
    }, 50));

    if (this.threadGroupSubscribed) {
      this.watchForum = true;
    }

    const storageKey = 'forum-overview-visited';
    const storage: string = window.localStorage.getItem(storageKey) || '';
    if (storage === '') {
      window.localStorage.setItem(storageKey, JSON.stringify([this.getForumId]));
    } else {
      const parsedStorage = JSON.parse(storage);
      if (parsedStorage.includes(this.getForumId)) {
        this.hasBeenScrolled = true;
      } else {
        parsedStorage.push(this.getForumId);
        window.localStorage.setItem(storageKey, JSON.stringify(parsedStorage));
      }
    }
  },
  methods: {
    ...mapActions('forum', ['fetchThreadList', 'sortThreadList', 'clearThreadList', 'fetchThreadList']),
    ...mapActions('subscriptions', ['postSubscription', 'deleteSubscription', 'fetchSubscriptions']),
    toggleShowTags() {
      this.showTags = !this.showTags;
    },
    toggleShowMobileSort() {
      this.showMobileSort = !this.showMobileSort;
    },
    newThread() {
      this.$router.push({ name: 'createThread', params: { forumId: this.getForumId, groupId: this.getGroupId } });
    },
    isSortActive(sort: string) {
      return sort === this.sortParam ? 'active' : '';
    },
    sort(sortParam: string) {
      let { sortOrder } = this;
      if (sortParam === this.sortParam && this.sortOrder === 'ASC') {
        sortOrder = 'DESC';
      } else if (sortParam === this.sortParam && this.sortOrder === 'DESC') {
        sortOrder = 'ASC';
      } else if (sortParam === 'VOTES') {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      this.sortThreadList({ sortParam, sortOrder });
    }
  }
});
