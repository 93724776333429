
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import { RawLocation } from 'vue-router';
import { mapGetters } from 'vuex';
import { Tag } from '@/models/Tags';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import ProjectListItemOpenState from '@/views/project-forum/components/ProjectListItemOpenState.vue';
import { defineComponent, PropType } from 'vue';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import useUser from '@/mixins/useUser';
import useQuickInfo from '@/state/quickInfo';
import { User } from '@/models/User';
import { getProjectCreatorName } from '@/utils/user';

export default defineComponent({
  components: { ProjectListItemOpenState, BCXAvatar, TagDisplay },
  props: {
    project: {
      type: Object as PropType<ProjectListProject>,
      required: true,
      default() {
        return {};
      },
    },
    showCreator: {
      type: Boolean,
      required: false,
      default: true
    },
    showTags: {
      type: Boolean,
      required: true,
      default: true,
    },
    statusOnTop: Boolean,
    isNarrow: Boolean,
    hideForumDates: Boolean,
    hideForumCounts: Boolean,
    isStateClickable: Boolean,
    hideVotes: Boolean,
  },
  data() {
    return {
      status: Math.floor(Math.random() * 3),
      createdLatestHasTwoRows: false,
    };
  },
  computed: {
    ...mapGetters('page', ['isMobileLayout']),
    ...mapGetters('forum', ['getForumId', 'getGroupId']),
    hideConditions(): boolean {
      return typeof this.project.durationUnit === 'undefined';
    },
    displayAsNarrow(): boolean {
      return this.isMobileLayout || this.isNarrow;
    },
    creationDateFormatted(): string {
      return this.formatDate(this.project.created ?? '');
    },
    latestReplyDateFormatted(): string|undefined {
      return this.formatDate(this.project.latestAddedReply ?? '');
    },
    numberOfReplies(): number {
      return this.project.numberOfReplies || 0;
    },
    repliesLabel(): string {
      if (this.numberOfReplies === 1) {
        return this.tUser('project-forum_USERTYPE.overview.replies.singular') as string;
      }
      return this.tUser('project-forum_USERTYPE.overview.replies.plural') as string;
    },
    viewsLabel(): string {
      if (this.numberOfViews === 1) {
        return this.tUser('project-forum_USERTYPE.overview.views.singular') as string;
      }
      return this.tUser('project-forum_USERTYPE.overview.views.plural') as string;
    },
    hasReplies(): boolean {
      return this.numberOfReplies > 0;
    },
    numberOfViews(): number {
      return this.project.numberOfViews || 0;
    },
    voteResult(): number {
      return this.project.voteResult ?? 0;
    },
    startDateFormatted(): string {
      return this.formatDate(this.project.startTime ?? '', 'short');
    },
    projectDurationText(): string {
      const projectDurationUnitText = this.tUser(`project-forum_USERTYPE.form-section.conditions.duration.dropdown.${this.project.durationUnit}`) as string;
      return `${this.project.durationAmount} ${projectDurationUnitText}`;
    },
    projectLocationText(): string {
      return this.tUser(`project-forum_USERTYPE.form-section.conditions.location.values.${this.project.location}`) as string;
    },

    projectRoleTags(): Tag[] {
      return (this.project.tagAssignmentList as Tag []).filter((tag: Tag) => tag.tagType === 'ROLE');
    },
    projectTags(): Tag[] {
      return (this.project.tagAssignmentList as Tag[]).filter((tag: Tag) => tag.tagType === 'TAG');
    },
    creator(): string {
      return getProjectCreatorName(this.project);
    },
    titleText(): string {
      if (this.project.metaDataProject?.testFlag) {
        return `[TEST] ${this.project.title}`;
      }
      return this.project.title ?? '';
    },
    routerLink(): RawLocation {
      return { name: 'projectForumThread', params: { projectId: this.project.id ?? '' } };
    },
  },
  mounted() {
    if (this.isMobileLayout) {
      this.$nextTick(() => {
        this.createdLatestHasTwoRows = this.checkCreatedLatestHasTwoRows();
        window.addEventListener('resize', () => {
          this.createdLatestHasTwoRows = this.checkCreatedLatestHasTwoRows();
        });
      });
    }
  },
  methods: {
    tUser(key: string, ...params:any) {
      const { tUserFallback } = useUser('self');
      return tUserFallback(key, ...params);
    },
    goToThread() {
      this.$router.push(this.routerLink);
    },
    checkCreatedLatestHasTwoRows(): boolean {
      const createdLatestElement = this.$refs.createdLatest as Element;
      if (createdLatestElement) {
        const createdElement = createdLatestElement.querySelector('.created-on') as HTMLElement;
        const latestReplyElement = createdLatestElement.querySelector('.latest-reply') as HTMLElement;
        if (Math.ceil(createdElement.clientWidth) + Math.ceil(latestReplyElement.clientWidth) > Math.ceil(createdLatestElement.clientWidth)) {
          return true;
        }
      }
      return false;
    },
    formatDate(timestamp: string, dateformat: 'short'|'diff' = 'diff'): string {
      const { getFormattedTimeByAge, getFormattedDate } = useFormattedDateTime();
      if (dateformat === 'diff') return getFormattedTimeByAge(timestamp);
      return getFormattedDate(timestamp);
    },
    showQuickInfo(event: PointerEvent) {
      const { showQuickInfo } = useQuickInfo();

      const el = event.target as HTMLElement;

      showQuickInfo(el, this.project?.creator as User);
    }
  },
});

