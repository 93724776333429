
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import Chevron from '@/components/svg-components/Chevron.vue';
import X from '@/components/svg-components/X.vue';
import { InfoBoxType } from '@/models/InfoBox';
import useMessengerState from '@/state/messenger/messengerState';
import {
  computed, defineComponent, PropType, ref, watch
} from 'vue';
import { useVModel } from '@vueuse/core';
import { not } from '@vueuse/math';
import BCXButton from '@/components/molecules/BCXButton.vue';

export default defineComponent({
  name: 'SidebarContent',
  components: {
    BCXButton,
    BCXIcon,
    Chevron,
    BCXCollapsible,
    X,
  },
  props: {
    isOpened: Boolean,
    showClose: Boolean,
    hideExpand: Boolean,
    hasBackButton: Boolean,
    type: {
      type: String as PropType<InfoBoxType>,
      default: InfoBoxType.INFOBOX_INFO
    }
  },
  setup(props) {
    const { isOpened: isMessengerOpened } = useMessengerState();
    const isOpenedVM = useVModel(props, 'isOpened');
    const hasScrollbarsWhenNotMaximized = ref(true);
    const isProbablyMaximized = not(isMessengerOpened);
    const innerElement = ref<HTMLElement | null>(null);

    const toggleOpened = () => {
      isOpenedVM.value = !isOpenedVM.value;
    };

    const contentClass = computed(() => (isMessengerOpened.value ? 'c-content--compact' : 'c-content--expanded'));

    const toggleMessengerOpened = () => {
      isMessengerOpened.value = !isMessengerOpened.value;
      isOpenedVM.value = true;
    };

    const getInnerScrollbars = () => {
      if (innerElement.value) {
        const {
          clientHeight,
          scrollHeight
        } = innerElement.value;
        return scrollHeight > clientHeight;
      }
      return true;
    };

    const setContentMetrics = (inner: HTMLElement) => {
      innerElement.value = inner.children?.[0] as HTMLElement ?? null;
      if (!isProbablyMaximized.value) {
        hasScrollbarsWhenNotMaximized.value = getInnerScrollbars();
      }
    };

    watch(isProbablyMaximized, (is) => {
      if (!is) hasScrollbarsWhenNotMaximized.value = getInnerScrollbars();
    });

    const hasExpandButton = computed(() => hasScrollbarsWhenNotMaximized.value);

    return {
      isMessengerOpened,
      hasExpandButton,
      setContentMetrics,
      isOpenedVM,
      toggleOpened,
      toggleMessengerOpened,
      contentClass
    };
  },
});
