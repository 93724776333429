import { AxiosResponse } from 'axios';
import { CompanyGuestListResponse, InviteCompanyGuestRequest } from '@/models/Company';
import axios from '@/plugins/axios';

export default {
  async inviteCompanyGuest(companyId: string, data: InviteCompanyGuestRequest): Promise<AxiosResponse> {
    return axios.post(`/v1/companies/${companyId}/guests`, data);
  },
  async getCompanyGuestList(companyId: string): Promise<CompanyGuestListResponse[]> {
    if (!companyId) return [];
    return (await axios.get(`/v1/companies/${companyId}/guests`))?.data ?? null;
  },
  async deleteCompanyGuest(companyId: string, inviteId: string, complete = false): Promise<AxiosResponse> {
    return axios.delete(`/v1/companies/${companyId}/guests/${inviteId}`, { params: { complete } });
  },
  async reactivateCompanyGuest(companyId: string, inviteId: string): Promise<AxiosResponse> {
    return axios.put(`/v1/companies/${companyId}/guests/${inviteId}`);
  }
};
