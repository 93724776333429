import { Tag } from '@/models/Tags';
import { BaseUserWithRole, User } from '@/models/User';

export const FORUM_ID_BLOG = '1fe0eedf-480c-47ff-ac78-75813e914d0b';

export const GROUP_ID_BUG = '85aaf998-23fe-4d25-b515-d2b71665f3ee';
export const GROUP_ID_FEATURE = '94f5c84b-9cee-43e5-907d-95d091a00f52';
export const GROUP_ID_FEEDBACK = '0b9cebc0-52bd-4dc5-b572-ee0218d5e843';
export const GROUP_ID_ARTICLES = 'dc593833-907f-4b7e-ad09-2092ed66a681';

export enum ForumMaxChars {
  TALK_TITLE = 100,
  TALK_CONTENT = 5000,
  PROJECT_REPLY = 2000,
  PROJECT_TITLE = 60,
  PROJECT_DESCRIPTION = 5000
}
export enum ForumMaxNum {
  PROJECT_DURATION = 999,
  PROJECT_HOURS = 99
}

export interface MessagePost{
  forumId: string;
  forumThreadId: string;
  forumThreadMessageId?: string;
  messageContent: string;
  replyToMessageId?: string;
  replyToUserId: string;
}

export interface ThreadUpdate{
  title: string;
  description?: string;
}
export interface ThreadPost{
    forumId: string;
    title: string;
    description: string;
}

export interface MessagesSort {
  sortParam: string;
  sortOrder: 'ASC'|'DESC';
}

export interface ThreadsSort {
  sortParam: string;
  sortOrder: 'ASC'|'DESC';
}

export interface Message {
  messageId: string;
  created: string;
  creator: User;
  edited?: boolean;
  flagged?: boolean;
  text: string;
  messageCount?: string;
  downvoteCount: number;
  upvoteCount: number;
  voteResult: number;
  userVoted?: string;
  replyToMessageId?: string;
  subMessages?: Array<Message>;
  subReplyLink?: string | false;
  error?: string;
}
export type Messages = Array<Message>;

export interface ThreadMetaResponse {
  numberOfReplies: number;
  numberOfEdits: number;
}
export interface ForumThreadMetaData {
  latestReplyMessageId: string;
  latestReplyMessageTimestamp: string;
  latestReplyMessageUser: BaseUserWithRole;
  latestReplyMessageUserId: string;
  numberOfReplies: number;
  numberOfViews: number;
  userSubscribed: boolean;
  voteResult: number;
}
export interface Thread {
  forumId: string;
  groupId: string;
  threadId: string;
  groupType: string;
  created: string; // eg. "2021-11-24T18:02:06.009Z"
  title: string;
  creator: User;
  forumThreadMetaData: ForumThreadMetaData;
  initialMessage: Message;
  messages: Array<Message>;
  tagAssignmentList: Tag[];
}

export type Threads = Array<Thread>;

export interface ForumThreadGroupMetaData {
  latestAddedThreadCreator: BaseUserWithRole;
  latestAddedThreadId: string;
  latestAddedThreadTitle: string;
  latestAddedThreadTs: string;
  numberOfThreads: number;
}
export interface Group {
  forumId: string;
  groupId: string;
  title: string;
  description: string;
  groupType: string;
  allThreadsLoaded: boolean;
  forumThreadGroupMetaData: ForumThreadGroupMetaData;
  threads: Threads;
}
export type Groups = Array<Group>;

export interface Forum {
  forumId: string;
  title: string;
  description: string;
  groupType: string;
  groups: Groups;
}

export type Forums = Array<Forum>;

export const emptyForum: Forum = {
  forumId: '',
  title: '',
  description: '',
  groupType: '',
  groups: [
    {
      forumId: '',
      groupId: '',
      title: '',
      description: '',
      groupType: '',
      allThreadsLoaded: false,
      forumThreadGroupMetaData: {
        latestAddedThreadCreator: {
          userId: '',
          firstname: '',
          lastname: '',
        },
        latestAddedThreadId: '',
        latestAddedThreadTitle: '',
        latestAddedThreadTs: '',
        numberOfThreads: 0,
      },
      threads: [
        {
          forumId: '',
          groupId: '',
          threadId: '',
          groupType: '',
          title: '',
          created: '',
          creator: {
            userId: '',
            firstname: '',
            lastname: '',
            fullname: '',
          },
          initialMessage: {
            messageId: '',
            text: '',
            creator: {
              userId: '',
              firstname: '',
              lastname: '',
              fullname: '',
            },
            created: '',
            upvoteCount: 0,
            downvoteCount: 0,
            voteResult: 0,
          },
          forumThreadMetaData: {
            numberOfReplies: 0,
            numberOfViews: 0,
            latestReplyMessageId: '',
            latestReplyMessageTimestamp: '',
            latestReplyMessageUser: {
              userId: '',
              firstname: '',
              lastname: '',
            },
            latestReplyMessageUserId: '',
            userSubscribed: false,
            voteResult: 0,
          },
          messages: [
          ],
          tagAssignmentList: []
        },
      ],
    },
  ],
};
