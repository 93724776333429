var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.field)?_c('div',{staticClass:"bc-form-field"},[_c('h4',{staticClass:"c-headline",class:{'c-error-headline': _vm.hasError}},[_vm._v(" "+_vm._s(_vm.field.type !== 'checkbox' ? _vm.field.label : ' ')+" ")]),_c('div',{staticClass:"c-form-input",class:`c-form-input--${_vm.field.type}`},[(_vm.field.type === 'select')?_c(_setup.BCXSearchSelect,{staticClass:"bcx-full-width",style:({
        width: _vm.field.fieldWidth
      }),attrs:{"placeholder":_vm.field.placeholder ?? '',"list":_vm.field.options,"disabled":_vm.field.disabled,"invalid":_vm.hasError},on:{"input":_setup.onDelayedBlur},model:{value:(_setup.vmValue),callback:function ($$v) {_setup.vmValue=$$v},expression:"vmValue"}}):(_vm.field.type==='textarea')?_c(_setup.BCXTextArea,{style:({
        width: _vm.field.fieldWidth
      }),attrs:{"placeholder":_vm.field.placeholder ?? '',"height":_vm.field?.textareaOptions?.height,"enlarged-height":_vm.field?.textareaOptions?.enlargedHeight,"maxlength":_vm.field?.textareaOptions?.maxLength,"show-enlarge-icon":!!_vm.field?.textareaOptions?.enlargedHeight,"is-enlarged":_setup.isTextareaEnlarged,"disabled":_vm.field.disabled,"invalid":_vm.hasError},on:{"update:isEnlarged":function($event){_setup.isTextareaEnlarged=$event},"update:is-enlarged":function($event){_setup.isTextareaEnlarged=$event},"blur":function($event){return _setup.emit('blur')}},model:{value:(_setup.vmValue),callback:function ($$v) {_setup.vmValue=$$v},expression:"vmValue"}}):(_vm.field.type==='checkbox')?_c(_setup.BCXCheckbox,{attrs:{"checked":!!_setup.vmValue,"disabled":_vm.field.disabled},on:{"input":function($event){return _vm.$emit('input', _setup.vmValue ? '' : '1')},"blur":function($event){return _setup.emit('blur')}}},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]):(_vm.field.type==='date')?_c(_setup.BCXDatepickerField,{attrs:{"earliest":_vm.field?.dateOptions?.earliest,"latest":_vm.field?.dateOptions?.latest,"is-secondary":_vm.field?.dateOptions?.secondary},on:{"blur":function($event){return _setup.emit('blur')}},model:{value:(_setup.vmDateValue),callback:function ($$v) {_setup.vmDateValue=$$v},expression:"vmDateValue"}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.vmValue),expression:"vmValue"}],staticClass:"bcx-input",class:{'invalid': _vm.hasError},style:({
        width: _vm.field.fieldWidth ?? '100%'
      }),attrs:{"type":"text","placeholder":_vm.field.placeholder ?? '',"disabled":_vm.field.disabled},domProps:{"value":(_setup.vmValue)},on:{"blur":function($event){return _setup.emit('blur')},"input":function($event){if($event.target.composing)return;_setup.vmValue=$event.target.value}}}),(_vm.field.suffix)?_c('span',{staticClass:"c-suffix"},[_vm._v(_vm._s(_vm.field.suffix))]):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }