import axios from '@/plugins/axios';
import {
  PostSubscription, SubscriptionFeedList, SubscriptionsList, Subscription,
  subscriptionType
} from '@/views/subscriptions/models/Subscription';

export default {

  async getAllSubscriptions(): Promise<SubscriptionsList> {
    /*
     * Open-API Description
     * https://api.dev.bytecookie.net/api/openapi-ui/index.html#/UserSubscription/get_api_v1_users_subscriptions
     */
    return axios.get('/v1/users/subscriptions').then(({ data }) => data);
  },

  async postSubscriptions(postSubscriptionData: PostSubscription): Promise<Subscription> {
    /*
     * Open-API Description
     * https://api.dev.bytecookie.net/api/openapi-ui/index.html#/UserSubscription/post_api_v1_users_subscriptions
     */
    return axios.post('/v1/users/subscriptions', postSubscriptionData).then(({ data }) => data);
  },

  async getSubscriptionFeed(): Promise<SubscriptionFeedList> {
    /*
     * Open-API Description
     * https://api.dev.bytecookie.net/api/openapi-ui/index.html#/UserSubscription/get_api_v1_users_subscriptions_feed
    */
    return axios.get('/v1/users/subscriptions/feed').then(({ data }) => data);
  },

  async deleteSubscription(subscriptionId: string): Promise<void> {
    /*
     * Open-API Description
      * https://api.dev.bytecookie.net/api/openapi-ui/index.html#/UserSubscription/delete_api_v1_users_subscriptions_subscriptionId
      * subscriptionId: string
    */
    return axios.delete(`/v1/users/subscriptions/${subscriptionId}`).then(({ data }) => data);
  },

  async subscribeGuest(guestId: string, businessObjectId: string, subscriptionType: subscriptionType): Promise<Subscription> {
    /*
     * Open-API Description
     * https://api.dev.bytecookie.net/api/openapi-ui/index.html#/UserSubscription/post_api_v1_users_subscriptions_guest__guestId_
     * guestId: string
     * businessObjectId: string
     * subscriptionType: subscriptionType
     */
    return axios.post(`/v1/users/subscriptions/guest/${guestId}`, { businessObjectId, subscriptionType }).then(({ data }) => data);
  },

  async unsubscribeGuestFromProject(guestId: string, projectId: string): Promise<void> {
    /*
     * Open-API Description
     * https://api.dev.bytecookie.net/api/openapi-ui/index.html#/UserSubscription/delete_api_v1_users_subscriptions_guest__guestId_
     * guestId: string
     * projectId: string
     * subscriptionType: subscriptionType
     */
    return axios.delete(`/v1/users/subscriptions/guest/${guestId}/project/${projectId}`).then(({ data }) => data);
  }

};
