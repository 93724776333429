
import {
  computed, defineComponent, PropType, toRef
} from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import { User } from '@/models/User';
import { getDisplayName } from '@/utils/user';

export default defineComponent({
  name: 'BCXUserListItem',
  components: {
    BCXAvatar
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
  },
  setup(props) {
    const userProp = toRef(props, 'user');

    const displayName = computed(() => getDisplayName(userProp.value));
    const userId = computed(() => userProp.value.id ?? userProp.value.userId ?? userProp.value.company?.rootUserId);
    return {
      displayName,
      userId
    };
  }
});
