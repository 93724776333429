
import BytecookieLogo from '@/components/svg/logo/logo-customer-landingpage.svg?inline';
import { computed, defineComponent, inject } from 'vue';
import LPCustomerStep from '@/views/landingpage/components/LPCustomerStep.vue';
import useI18n from '@/mixins/useI18n';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useMediaQuery } from '@vueuse/core';
import LPBGArrow from '@/views/landingpage/components/LPBgArrow.vue';
import LPButton from '@/views/landingpage/components/LPButton.vue';
import useMultiLineBreaks from '@/mixins/useMultiLineBreaks';
import LPExplainVideo from '@/views/landingpage/components/LPExplainVideo.vue';
import LPAccordion from './components/LPAccordion.vue';

export default defineComponent({
  name: 'LandingpageCustomer',
  components: {
    LPExplainVideo,
    LPButton,
    LPBGArrow,
    BCXMarkdown,
    BytecookieLogo,
    LPCustomerStep,
    LPAccordion
  },
  setup() {
    const { t } = useI18n();
    const isMobile = useMediaQuery('(width < 800px)');
    const steps = computed(() => t('landingpage-customer.steps'));
    const toggleInvitePopup = inject<CallableFunction>('toggleInviteCodePopup');
    const isSmartphone = useMediaQuery('(max-width: 799px)');

    // eslint-disable-next-line no-nested-ternary
    const lineBreak = computed(() => (isMobile.value ? 'narrow' : 'wide'));
    const linebreaks = t('landingpage-customer.linebreaks') as any;
    const { tl } = useMultiLineBreaks(linebreaks, lineBreak);

    return {
      steps,
      isMobile,
      toggleInvitePopup,
      isSmartphone,
      tl
    };
  }
});

