import { RouteConfig } from 'vue-router';
import useI18n from '@/mixins/useI18n';
import Logout from '@/views/authentication/pages/Logout.vue';
import Login from '../pages/Login.vue';

const { t } = useI18n();

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'login-register',
      title: t('landingpage.title_login'),
      description: t('landingpage.meta_login'),
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      layout: 'login-register',
      robots: 'noindex, follow',
    }
  }
];

export default routes;
