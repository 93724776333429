

import {
  defineComponent, ref, onMounted, computed, watch, provide, nextTick, watchEffect
} from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXConfirm from '@/components/molecules/BCXConfirm.vue';
import TagList from '@/components/tagging/TagList.vue';
import { Tag } from '@/services/TaggingService';
import { Editor } from '@toast-ui/vue-editor';
import ToastEditor from '@toast-ui/editor';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import { useRouter } from 'vue2-helpers/vue-router';
import useToastUiEditorTools, { buttonSVGs, ButtonType } from '@/mixins/useToastUiEditorTools';
import useIsLeavingDirty from '@/utils/isLeavingDirty';
import MarvinInfoBox from '@/views/recommendations/components/MarvinInfoBox.vue';
import { Forum, ForumMaxChars, GROUP_ID_BUG } from '../models/Forum';

export default defineComponent({
  components: {
    MarvinInfoBox,
    BCXAvatar,
    BCXBreadcrumbs,
    BCXUserBanner,
    BCXConfirm,
    TagList,
    Editor,
  },
  setup() {
    provide('maxCreateMessageTextLength', ForumMaxChars.TALK_CONTENT);
    const store = useStore();
    const { t, tc } = useI18n();
    const router = useRouter();

    const time = ref('');
    const title = ref('');
    const titleMinLength = ref(3);
    const descriptionMinLength = ref(3);
    const tags = ref([]);
    const tagsMinCount = ref(0);
    const isSending = ref(false);
    const maxChars = ref({ content: ForumMaxChars.TALK_CONTENT, title: ForumMaxChars.TALK_TITLE });

    const thread = computed(() => store.state.forum.thread);
    const getForumId = computed(() => store.getters['forum/getForumId']);
    const getForumTitle = computed(() => store.getters['forum/getForumTitle']);
    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const user = computed(() => store.getters.user);
    const isUserAdmin = computed(() => (user.value.roles as Array<string>).includes('ADMIN'));

    const toastUiEditor = ref<ToastEditor>({} as ToastEditor);

    const maxCharsContent = computed(():number => (isUserAdmin.value ? 0 : ForumMaxChars.TALK_CONTENT));

    const { charsLeft, markdownText, createButton } = useToastUiEditorTools(toastUiEditor, maxCharsContent);

    const isReadyToSave = computed(() => title.value.length >= titleMinLength.value
      && markdownText.value.length >= descriptionMinLength.value
        && tags.value.length >= tagsMinCount.value
      && !isSending.value);
    const {
      isLeavingDirty, isDirty, onConfirm, isLeavingMessage
    } = useIsLeavingDirty();
    watchEffect(() => {
      isDirty.value = title.value.length > 0 || (markdownText.value.length ?? 0) > 0 || tags.value.length > 0;
    });
    const submitNewThread = () => {
      if (isReadyToSave.value) {
        isSending.value = true;
        const { forumId, groupId } = thread.value;
        const postData = {
          forumId,
          groupId,
          title: title.value,
          description: toastUiEditor.value.getHTML(),
          tagAssignmentList: tags.value.map((tag: Tag) => {
            if (tag.tagId !== '') {
              return { externalTagId: tag.tagId, newTagName: tag.tagName, newTagType: tag.tagType };
            }
            return { externalTagId: '', newTagName: tag.tagName, newTagType: tag.tagType };
          }),
        };

        store.dispatch('forum/postThread', postData).then((forum: Forum) => {
          const [thread] = forum.groups[0].threads;
          isDirty.value = false;
          router.push({ name: 'thread', params: { forumId: thread.forumId, groupId: thread.groupId, threadId: thread.threadId } });
        }).finally(() => {
          isSending.value = false;
        });
      }
    };

    const addImageBlobHook = (blob: File, callback: any) => {
      /* if (blob.size < 1024 * 20) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        let base64FileString = '';
        reader.onload = () => {
          base64FileString = reader.result as string;
        };
        callback(`data:${blob.type};base64,${base64FileString}`, '');
      } */
      callback('', '');
    };
    const onKeyDown = (ev: KeyboardEvent | 'wysiwyg') => {
      if (typeof ev === 'object' && ev.type === 'keydown') {
        if ((ev.code === 'Enter') && ev.ctrlKey) {
          submitNewThread();
        }
      }
    };
    const onLoad = (editor: ToastEditor) => {
      toastUiEditor.value = editor;
      nextTick(() => {
        if (isUserAdmin.value) {
          toastUiEditor.value.insertToolbarItem({ groupIndex: 0, itemIndex: 10 }, 'image');
        } else {
          toastUiEditor.value.addHook('addImageBlobHook', addImageBlobHook);
        }
      });
    };

    const breadcrumbItems = computed(() => {
      const breadcrumbs = [
        {
          text: t('breadcrumb.community') as string,
        },
        {
          text: tc('forum.labels.threads', 2) as string,
          to: { name: 'forums' },
        }, {
          text: getForumTitle.value,
          to: { name: 'threads', params: { forumId: getForumId.value } },
        },
      ];
      if (isMobileLayout.value) {
        breadcrumbs[0].text = 'ByteCookie';
      }
      return breadcrumbs;
    });

    const charactersLeft = computed(() => {
      const charactersLeft = maxChars.value.title - title.value.length;
      if (charactersLeft > 0) {
        return charactersLeft;
      }
      return 0;
    });

    watch(() => title.value, (newValue: string) => {
      title.value = newValue.replace(/[^\u00C0-\u017FA-Za-z\d \-.,@$€%=?!()[\]/]+/, '');
    });

    const goBack = () => {
      router.push({ name: 'threads', params: { forumId: getForumId.value } });
    };

    const getTimeString = (): string => {
      const time = new Date();
      const hours = time.getHours();
      const minutes = time.getMinutes();
      return `${(hours < 10 ? '0' : '') + hours}:${(minutes < 10 ? '0' : '') + minutes}`;
    };

    onMounted(() => {
      time.value = getTimeString();
      setInterval(() => {
        time.value = getTimeString();
      }, 15000);

      if (thread.value && thread.value.groupId === GROUP_ID_BUG) {
        toastUiEditor.value.setMarkdown(`${t('forum.templates.bug')}`);
      }
    });

    return {
      t,
      time,
      isMobileLayout,
      user,
      isUserAdmin,
      title,
      getForumTitle,
      titleMinLength,
      descriptionMinLength,
      tags,
      tagsMinCount,
      isSending,
      breadcrumbItems,
      charactersLeft,
      submitNewThread,
      goBack,
      maxChars,
      charsLeft,
      isReadyToSave,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
        autofocus: false,
        toolbarItems: [
          ['bold', 'italic', 'quote', 'ul', 'ol', 'indent', 'outdent', 'link',
            {
              el: createButton('undo'),
              command: 'undo',
              tooltip: t('buttons.undo')
            },
            {
              el: createButton('redo'),
              command: 'redo',
              tooltip: t('buttons.redo')
            }]
        ],
      },
      onKeyDown,
      onLoad,
      isLeavingDirty,
      onConfirm,
      isDirty,
      isLeavingMessage
    };
  }
});
