
import { computed, defineComponent, PropType } from 'vue';
import { ExternalProjectListEntry } from '@/__generated__/types';
import BCXItemsWithPoints from '@/components/molecules/BCXItemsWithPoints.vue';
import ProjectListItemOpenState from '@/views/project-forum/components/ProjectListItemOpenState.vue';
import { toRefs } from '@vueuse/core';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProjectMinimalListItem',
  components: { ProjectListItemOpenState, BCXItemsWithPoints },
  props: {
    project: {
      type: Object as PropType<ExternalProjectListEntry>,
      required: true
    },
    linkToProject: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { project, linkToProject } = toRefs(props);
    const { getFormattedDate } = useFormattedDateTime();
    const { tUserFallback: t } = useUser('self');

    const projectLink = computed(() => ({ name: 'projectForumThread', params: { projectId: project.value.id } }));

    const creationDateFormatted = computed(() => getFormattedDate(project.value.created));

    return {
      t,
      creationDateFormatted,
      projectLink
    };
  }
});
