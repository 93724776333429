import { createGlobalState, useAsyncState } from '@vueuse/core';
import {
  computed, onMounted, onUnmounted, reactive, ref, watch
} from 'vue';
import CompanyService from '@/services/CompanyService';
import SubscriptionService from '@/services/SubscriptionService';
import { CompanyGuestListResponse, CompanyGuestStatus, InviteCompanyGuestRequest } from '@/models/Company';
import { useStore } from 'vue2-helpers/vuex';

export interface NewProjectGuest {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
  accepted: boolean;
}

const useProjectGuestState = createGlobalState((initialCompanyId: string | null = null) => {
  const store = useStore();
  const companyId = ref<string | null>(initialCompanyId);
  const { state: guestList, execute: loadGuests } = useAsyncState<CompanyGuestListResponse[]>(
    async () => CompanyService.getCompanyGuestList(companyId.value ?? initialCompanyId ?? ''), []
  );
  const companyGuestList = computed(() => {
    if (guestList.value instanceof Array) {
      return guestList.value.map((guest) => {
        let status = CompanyGuestStatus.INVITED;
        if (guest.active !== undefined) {
          status = guest.active ? CompanyGuestStatus.ACTIVE : CompanyGuestStatus.DEACTIVATED;
        }
        return {
          ...guest,
          status
        };
      });
    }
    return [];
  });
  const companyGuestListRegistered = computed(() => companyGuestList.value.filter((guest) => guest.memberSince));

  const projectList = ref<string[]>([]);
  const projectGuestList = computed(() => companyGuestList.value
    .filter((guest) => guest.projects?.filter((project) => projectList.value.includes(project.id)).length));

  const newProjectGuest = reactive<NewProjectGuest>({
    firstname: '',
    lastname: '',
    email: '',
    message: '',
    accepted: false
  });

  const resetNewProjectGuest = () => {
    newProjectGuest.firstname = '';
    newProjectGuest.lastname = '';
    newProjectGuest.email = '';
    newProjectGuest.message = '';
    newProjectGuest.accepted = false;
  };

  const canInviteNewGuest = computed(() => newProjectGuest.firstname !== ''
      && newProjectGuest.lastname !== ''
      && newProjectGuest.email !== ''
      && newProjectGuest.accepted);

  const inviteNewGuest = async () => {
    if (canInviteNewGuest.value && companyId.value
      && projectList.value.length > 0
      && companyId.value !== '') {
      const inviteCompanyGuestRequest: InviteCompanyGuestRequest = {
        firstName: newProjectGuest.firstname,
        lastName: newProjectGuest.lastname,
        email: newProjectGuest.email,
        message: newProjectGuest.message,
        projectList: projectList.value
      };
      return CompanyService.inviteCompanyGuest(companyId.value, inviteCompanyGuestRequest);
    }
    return null;
  };

  const addGuestToProject = async (guestId: string, projectId: string | undefined = undefined) => {
    if (!projectId && projectList.value.length > 0) {
      projectList.value.forEach(async (projectId) => {
        await SubscriptionService.subscribeGuest(guestId, projectId, 'PROJECT');
        loadGuests();
      });
    } else if (projectId) {
      await SubscriptionService.subscribeGuest(guestId, projectId, 'PROJECT');
      loadGuests();
    }
  };

  const removeProjectFromGuest = (guestId: string, projectId: string) => {
    guestList.value = guestList.value.map((guest) => {
      if (guest.id === guestId) {
        guest.projects = guest.projects?.filter((project) => project.id !== projectId);
      }
      return guest;
    });
  };

  const removeGuestFromProject = async (guestId: string, projectId: string | undefined = undefined, reload = true) => {
    if (!projectId && projectList.value.length > 0) {
      projectList.value.forEach(async (projectId) => {
        await SubscriptionService.unsubscribeGuestFromProject(guestId, projectId);
        removeProjectFromGuest(guestId, projectId);
      });
    } else if (projectId) {
      await SubscriptionService.unsubscribeGuestFromProject(guestId, projectId);
      removeProjectFromGuest(guestId, projectId);
    }
  };

  const disableCompanyGuest = async (inviteId: string) => {
    if (companyId.value) {
      await CompanyService.deleteCompanyGuest(companyId.value, inviteId)
        .then(() => {
          guestList.value.forEach((guest) => {
            if (guest.inviteId === inviteId) {
              guest.active = false;
            }
          });
        });
      loadGuests();
    }
  };

  const deleteCompanyGuest = async (inviteId: string) => {
    if (companyId.value) {
      await CompanyService.deleteCompanyGuest(companyId.value, inviteId, true)
        .then(() => {
          guestList.value = guestList.value.filter((guest) => guest.inviteId !== inviteId);
        });
    }
  };

  const reactivateCompanyGuest = async (inviteId: string) => {
    if (companyId.value) {
      await CompanyService.reactivateCompanyGuest(companyId.value, inviteId)
        .then(() => {
          guestList.value.forEach((guest) => {
            if (guest.inviteId === inviteId) {
              guest.active = true;
              console.debug(inviteId, guest.active);
            }
          });
        });
      loadGuests();
    }
  };

  watch(() => companyId.value, (newVal) => {
    if (newVal) {
      loadGuests();
    }
  });

  onMounted(() => {
    if (projectList.value.length === 0 && store.state.projectForum?.project?.id) {
      projectList.value = [store.state.projectForum.project.id];
    }
  });

  onUnmounted(() => {
    projectList.value = [];
  });

  return {
    companyId,
    companyGuestList,
    companyGuestListRegistered,
    projectList,
    projectGuestList,
    newProjectGuest,
    canInviteNewGuest,
    inviteNewGuest,
    disableCompanyGuest,
    deleteCompanyGuest,
    reactivateCompanyGuest,
    addGuestToProject,
    removeGuestFromProject,
    resetNewProjectGuest,
    loadGuests,
  };
});

export default useProjectGuestState;
