import axios from '@/plugins/axios';
import { Notifications, NotificationType, NotificationCountType } from '@/models/Notification';

const FAKE_NOTIFICATION_RESULT = false;

const FAKE_NOTIFICATION_DATA:Notifications = {
  [NotificationType.Messages]: {
    [NotificationCountType.DirectMessage]: 5,
    [NotificationCountType.ForumMessage]: 3,
    [NotificationCountType.ProjectMessage]: 2,
  },
  [NotificationType.Projects]: {
    [NotificationCountType.NewProjectChanges]: 2,
    [NotificationCountType.NewProjectComments]: 3,
    [NotificationCountType.NewProjects]: 3,
    [NotificationCountType.NewRecommendations]: 2,
    [NotificationCountType.NewRecommendationFeedbacks]: 5,
    [NotificationCountType.ChangedProjectOffers]: 2,
    [NotificationCountType.NewProjectOffers]: 30,
  },
  [NotificationType.Talks]: {
    [NotificationCountType.NewTalkComments]: 3,
    [NotificationCountType.NewTalks]: 2,
  }
};

// eslint-disable-next-line import/prefer-default-export
export async function getAllNotifications(): Promise<Notifications | null> {
  return (FAKE_NOTIFICATION_RESULT) ? structuredClone(FAKE_NOTIFICATION_DATA) : (await axios.get('/v1/notifications/widget/overview').catch(() => null))?.data ?? null;
}

export async function clearNotification(type: NotificationCountType | '') {
  const translation: Partial<Record<NotificationCountType, string>> = {
    [NotificationCountType.ProjectMessage]: 'MESSAGING_PROJECT',
    [NotificationCountType.DirectMessage]: 'MESSAGING_PRIVATE',
    [NotificationCountType.ForumMessage]: 'MESSAGING_TALK',

    [NotificationCountType.NewTalks]: 'SUBSCRIPTION_FORUMTHREAD_NEW',
    [NotificationCountType.NewTalkComments]: 'SUBSCRIPTION_FORUMTHREAD_MESSAGE_NEW',

    [NotificationCountType.NewProjects]: 'SUBSCRIPTION_PROJECTS_NEW',
    [NotificationCountType.NewProjectComments]: 'SUBSCRIPTION_PROJECT_MESSAGE_NEW',
    [NotificationCountType.NewProjectChanges]: 'SUBSCRIPTION_PROJECT_CHANGED',
    [NotificationCountType.NewRecommendations]: 'RECOMMENDATIONS_NEW',
    [NotificationCountType.NewRecommendationFeedbacks]: 'RECOMMENDATION_FEEDBACK_NEW',
  };

  return axios.post('/v1/notifications/widget/clear', { type: type ? translation[type] : 'ALL' });
}

export async function clearNotifications(which: NotificationType | ''): Promise<void> {
  const availableClearances: Partial<Record<NotificationType, NotificationCountType[]>> = {
    [NotificationType.Talks]: [
      NotificationCountType.NewTalkComments,
      NotificationCountType.NewTalks
    ],
    [NotificationType.Messages]: [
      NotificationCountType.DirectMessage,
      NotificationCountType.ProjectMessage,
      NotificationCountType.ForumMessage
    ],
    [NotificationType.Projects]: [
      NotificationCountType.NewProjects,
      NotificationCountType.NewProjectComments,
      NotificationCountType.NewProjectChanges,
      NotificationCountType.NewRecommendations,
      NotificationCountType.NewRecommendationFeedbacks
    ],
  };

  if (!which) await clearNotification('');
  else {
    const clearances: NotificationCountType[] = availableClearances?.[which] ?? [];
    if (!clearances.length) throw new Error(`NO CLEARANCES FOUND FOR ${which}!!!`);
    const clearancePromises = clearances.map((clearance) => clearNotification(clearance));
    await Promise.all(clearancePromises);
  }
}
