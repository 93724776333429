
import BCXItemsWithPoints from '@/components/molecules/BCXItemsWithPoints.vue';
import FlagIcon from '@/components/svg/flag_outline.svg?inline';
import ReplyIcon from '@/components/svg/goto.svg?inline';
import Reporting from '@/components/voting/Reporting.vue';
import { ExternalRecommendation } from '@/models/Recommendations';
import { SaveReportingSourceType } from '@/models/Reporting';
import { User } from '@/models/User';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'RecommendationActions',
  components: {
    Reporting,
    BCXItemsWithPoints,
    FlagIcon,
    ReplyIcon
  },
  props: {
    user: {
      type: Object as PropType<User>,
      default: null
    },
    recommendation: {
      type: Object as PropType<ExternalRecommendation>,
      default: null
    },
    isFeedback: {
      type: Boolean
    }
  },
  setup(props) {
    const { isFeedback, recommendation } = toRefs(props);

    const isFlagged = computed(() => (isFeedback.value ? recommendation.value?.recommendedUserFeedbackResponseMessageFlagged
      : recommendation.value?.recommendingMessageFlagged));

    const sourceType = computed<SaveReportingSourceType>(() => (isFeedback.value ? 'RECOMMENDATION_FEEDBACKMESSAGE' : 'RECOMMENDATION_MESSAGE'));

    const canReport = computed(() => {
      if (isFeedback.value) return !!recommendation.value?.recommendedUserFeedbackResponseMessage;
      return !!recommendation.value?.recommendingMessage;
    });

    const { tUserFallback: t } = useUser('self');

    return {
      isFlagged,
      sourceType,
      canReport,
      t,
    };
  }
});
