
import Vue, { computed, onMounted, nextTick } from 'vue';
import BCXMessengerUserBanner from '@/components/messenger/User/BCXMessengerUserBanner.vue';
import BCXMessengerChatMessages from '@/components/messenger/Chat/BCXMessengerChatMessages.vue';
import BCXMessengerChatInput from '@/components/messenger/Chat/BCXMessengerChatInput.vue';
import BCXMessengerChatHeader from '@/components/messenger/Chat/BCXMessengerChatHeader.vue';
import BCXMessengerUserSelect from '@/components/messenger/User/BCXMessengerUserSelect.vue';
import useMessengerState from '@/state/messenger/messengerState';
import { BOT_NAMES, CHAT_TYPE_DIRECT } from '@/models/Messenger';
import BCXMessengerInputResizer from '@/components/messenger/Chat/BCXMessengerInputResizer.vue';
import BCXMessengerChatBot from '@/components/messenger/Chat/BCXMessengerChatBot.vue';
import { useRoute } from 'vue2-helpers/vue-router';
import useUser from '@/mixins/useUser';

export default Vue.extend({
  name: 'BCXMessengerChat',
  components: {
    BCXMessengerChatBot,
    BCXMessengerInputResizer,
    BCXMessengerUserSelect,
    BCXMessengerChatHeader,
    BCXMessengerChatInput,
    BCXMessengerChatMessages,
    BCXMessengerUserBanner,
  },
  setup() {
    const route = useRoute();
    const {
      isExpanded, isNewChat: isNew,
      selectedChat: chat, createNewChat,
      isInputFocussed, selectedChatId, isOpened
    } = useMessengerState();

    const isDirectChat = computed(() => chat.value.type === CHAT_TYPE_DIRECT);

    const { tUserFallback: t } = useUser('self');

    const userFullname = computed(() => `${chat.value?.privatePartner?.firstname} ${chat.value?.privatePartner?.lastname}`);

    const isBot = computed(() => BOT_NAMES.includes(userFullname.value ?? ''));

    onMounted(() => {
      const query = route?.query;
      if (query?.openChat) {
        selectedChatId.value = query.openChat as string;
        nextTick(() => {
          isOpened.value = true;
        });
      }
    });

    return {
      isBot,
      isDirectChat,
      isExpanded,
      isNew,
      chat,
      isInputFocussed,
      createNewChat,
      userFullname,
      t
    };
  }
});
