import { RouteConfig } from 'vue-router';
import ProjectGuests from '@/views/project-guests/ProjectGuests.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/project-guests',
    name: 'project-guests',
    component: ProjectGuests,
    meta: {
      auth: true,
      layout: 'full',
    },
  },
];

export default routes;
