import { render, staticRenderFns } from "./MyProjectsContractBaseDataOffer.vue?vue&type=template&id=7f7baf29&scoped=true"
import script from "./MyProjectsContractBaseDataOffer.vue?vue&type=script&setup=true&lang=ts"
export * from "./MyProjectsContractBaseDataOffer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MyProjectsContractBaseDataOffer.vue?vue&type=style&index=0&id=7f7baf29&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f7baf29",
  null
  
)

export default component.exports