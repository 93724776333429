import axios from '@/plugins/axios';
import {
  Settings,
  Settings2FAMethodRequest,
  Settings2FAUsageRequest,
  SettingsCompanyDataRequest,
  SettingsEmailRequest,
  SettingsMobilePhoneRequest,
  SettingsNewPasswordRequest,
  SettingsNotificationRequest,
  SettingsUserDataRequest,
  SettingsVerificationType
} from '@/models/Settings';
import { ExternalFile } from '@/models/Download';
import { AxiosResponse } from 'axios';

export async function getSettings():Promise<Settings | null> {
  return (await axios.get('/v1/users/user/settings').catch(() => null))?.data ?? null;
}

export async function setMobilePhone(data: SettingsMobilePhoneRequest):Promise<AxiosResponse> {
  return axios.put('/v1/users/user/settings/mobilephone', data).catch(({ response }) => response);
}

export async function set2FAMethod(data: Settings2FAMethodRequest):Promise<AxiosResponse> {
  return axios.put('/v1/users/user/settings/2famethod', data).catch(({ response }) => response);
}

export async function set2FAUsage(data: Settings2FAUsageRequest):Promise<AxiosResponse> {
  return axios.put('/v1/users/user/settings/2fausage', data).catch(({ response }) => response);
}

export async function setUserData(data:SettingsUserDataRequest):Promise<AxiosResponse> {
  return axios.put('/v1/users/user/settings/userdata', data).catch(({ response }) => response);
}

export async function setCompanyName(data:SettingsCompanyDataRequest):Promise<AxiosResponse> {
  return axios.put('/v1/users/user/settings/companydata', data).catch(({ response }) => response);
}

export async function setEmail(data:SettingsEmailRequest):Promise<AxiosResponse> {
  return axios.put('/v1/users/user/settings/email', data).catch(({ response }) => response);
}

export async function setNewPassword(data:SettingsNewPasswordRequest):Promise<AxiosResponse> {
  return axios.put('/v1/users/user/settings/password', data).catch(({ response }) => response);
}

export async function sendVerification(type:SettingsVerificationType, security2FaCode:string):Promise<boolean> {
  const urlParts:Record<SettingsVerificationType, string> = {
    [SettingsVerificationType.DeleteAccount]: 'deleteaccount',
    [SettingsVerificationType.FirstAndLastName]: 'userdata',
    [SettingsVerificationType.Email]: 'email',
    [SettingsVerificationType.MobilePhone]: 'mobilephone',
    [SettingsVerificationType.Password]: 'password',
    [SettingsVerificationType.Security2FAMethod]: '2famethod',
    [SettingsVerificationType.Security2FaUsage]: '2fausage',
    [SettingsVerificationType.CompanyName]: 'companydata',
  };
  const urlPart = urlParts[type];
  const result = await axios.post(`/v1/users/user/settings/${urlPart}/verify`, { security2FaCode, type }).catch(() => null);
  return !!result;
}

export async function setGeneralSettings(data:any):Promise<any> {
  const result = await axios.put('/v1/users/user/settings', data).catch(() => null);
  return !!result;
}

export async function setNotificationSettings(data:SettingsNotificationRequest):Promise<any> {
  const result = await axios.put('/v1/users/user/settings/emailnotifications', data).catch(() => null);
  return !!result;
}

export async function getDownload(which:'privacy' | 'legalterms'):Promise<ExternalFile | null> {
  return (await axios.get(`/v1/users/user/settings/download/${which}`))?.data ?? null;
}

export async function getAccountData():Promise<ExternalFile | null> {
  return (await axios.post('/v1/users/user/settings/accountdata'))?.data ?? null;
}

export async function deleteAccount():Promise<AxiosResponse> {
  return axios.delete('/v1/users/user/settings/deleteaccount').catch(({ response }) => response);
}
