import {
  computed, onBeforeUnmount, onMounted, ref, Ref, watch
} from 'vue';
import { useResizeObserver } from '@vueuse/core';
import { useRoute } from 'vue2-helpers/vue-router';

const useDontShrink = (elm: Ref<HTMLElement>) => {
  const maxHeight = ref(0);

  const route = useRoute();

  const routeName = computed(() => route.name);

  useResizeObserver(elm, (entries) => {
    const { height } = entries[0].contentRect;
    if (height > maxHeight.value) {
      maxHeight.value = height;
    }
  });

  watch(maxHeight, (maxHeight) => {
    elm.value.style.minHeight = `${maxHeight}px`;
  });

  const reset = () => {
    maxHeight.value = 0;
  };

  watch(routeName, () => {
    if (route.meta?.dontResetShrink) return;
    reset();
  });

  onMounted(() => {
    window.addEventListener('resize', reset);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', reset);
  });

  return {
    reset
  };
};

export default useDontShrink;
